import React from "react";

import Styled from "./Welcome.style";
import { Loader, LogIn, RegistrationLink } from "./components";

import { ReactComponent as Logo } from "../../assets/logo.svg";

const Welcome: React.FC = () => {
	return (
		<React.Fragment>
			<Styled.Wrapper>
				<Styled.IconWrapper>
					<Logo />
				</Styled.IconWrapper>
				<Styled.FormWrapper>
					<Styled.Title>Вход в личный кабинет</Styled.Title>
					<LogIn />
					<RegistrationLink />
				</Styled.FormWrapper>
			</Styled.Wrapper>
			<Loader />
		</React.Fragment>
	);
};

export default Welcome;
