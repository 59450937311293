import { Box, Typography, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	width: "50.3em",
	backgroundColor: theme.palette.lightGrey,
	borderRadius: "30px",
	[theme.breakpoints.down("tablet")]: {
		backgroundColor: theme.palette.green,
		flexDirection: "column",
		rowGap: "0.3em",
	},
}));

const InfoWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	columnGap: "0.3em",
	paddingLeft: "1.875em",
	[theme.breakpoints.down("tablet")]: {
		backgroundColor: theme.palette.lightGrey,
		width: "18.75em",
		height: "3.44em",
		borderRadius: "30px",
		alignItems: "center",
	},
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "1.125rem",
	color: theme.palette.darkGreen,
	[theme.breakpoints.down("tablet")]: {
		fontSize: "0.875rem",
	},
}));

const StyledAdmin = {
	Wrapper,
	InfoWrapper,
	Text,
};

export default StyledAdmin;
