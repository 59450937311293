import { Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";

import Styled from "./RestoreForm.style";

import { RestoreSchema } from "../../utils/RestoreSchema";

import { FormikItem, Loader } from "../../../common";

import { ReactComponent as PersonInTieIcon } from "../../../../assets/manInTieSmall.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/phone.svg";
import { SystemMessageContext } from "../../../../hocs";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { FormStyles } from "../../../../mui/commonStyles";
import { Paths } from "../../../../paths";
import { restorePasswordThunk } from "../../../../store/thunks/client";

const RestoreForm: React.FC = () => {
	const { isLoading, isRestoreReqSent } = useAppSelector(
		(state) => state.clientReducer,
	);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { showSystemMessage } = React.useContext(SystemMessageContext);

	return (
		<React.Fragment>
			{!isRestoreReqSent && (
				<Styled.Wrapper>
					<Formik
						initialValues={{
							login: "",
							phone: "",
						}}
						validationSchema={RestoreSchema}
						onSubmit={(values) => {
							dispatch(restorePasswordThunk({ ...values, showSystemMessage }));
						}}
					>
						{({ values, handleChange, handleSubmit, errors, touched }) => (
							<form onSubmit={handleSubmit}>
								<FormStyles.Wrapper>
									<FormStyles.Title>Восстановление пароля</FormStyles.Title>
									<FormikItem
										id={"login"}
										label="Логин"
										type={"text"}
										values={values}
										errors={errors}
										onChange={handleChange}
										touched={touched}
										Icon={PersonInTieIcon}
									/>
									<FormikItem
										id={"phone"}
										label="Номер телефона"
										type={"tel"}
										values={values}
										errors={errors}
										onChange={handleChange}
										touched={touched}
										Icon={PhoneIcon}
									/>
									<FormStyles.ButtonsWrapper>
										<FormStyles.SubmitButton
											variant="contained"
											type={"submit"}
										>
											Восстановить
										</FormStyles.SubmitButton>
										<FormStyles.CancelButton
											variant="contained"
											onClick={() => {
												navigate(Paths.welcome);
											}}
										>
											Отмена
										</FormStyles.CancelButton>
									</FormStyles.ButtonsWrapper>
								</FormStyles.Wrapper>
							</form>
						)}
					</Formik>
					<Loader isLoading={isLoading} />
				</Styled.Wrapper>
			)}
		</React.Fragment>
	);
};

export default RestoreForm;
