import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { TShowSystemMessage } from "../../../hocs/SystemMessageContextWrapper/utils/types";
import { $api } from "../../../http";
import { IInvitees, IReject, IResponse } from "../../../models/api";

export const getInviteesThunk = createAsyncThunk<
	IResponse<IInvitees>,
	{
		clientId: number;
		showSystemMessage: TShowSystemMessage;
	},
	{
		rejectValue: IReject;
	}
>("reference/getInviteesThunk", async (args, { rejectWithValue }) => {
	const { clientId, showSystemMessage } = args;
	try {
		const response = await $api.get<IResponse<IInvitees>>("reference", {
			params: { clientId },
		});
		return response.data;
	} catch (err) {
		const error: AxiosError<IReject> = err as AxiosError<IReject>; // cast the error for access
		showSystemMessage(
			typeof error.response?.data.message === "string"
				? error.response?.data.message
				: "Ошибка выполнения операции",
			"error",
		);
		return rejectWithValue(
			error.response?.data || {
				error: true,
				message: "Ошибка выполнения операции",
				statusCode: 500,
			},
		);
	}
});
