import React from "react";

import { ReactComponent as CalendarIcon } from "../../../../../../../../../../../../assets/calendar.svg";
import { ReactComponent as PersonTieIcon } from "../../../../../../../../../../../../assets/manInTieSmall.svg";
import { ReactComponent as PersonIcon } from "../../../../../../../../../../../../assets/person.svg";
import { ReactComponent as PiggyBankIcon } from "../../../../../../../../../../../../assets/piggyBank.svg";
import { ReactComponent as ListIcon } from "../../../../../../../../../../../../assets/toDoList.svg";
import { ReactComponent as WalletIcon } from "../../../../../../../../../../../../assets/wallet1.svg";
import { TModalWindowItem } from "../../../../../../../../../../../../models";
import { IAdminAccountHistoryRow } from "../../../../../../../../../../../../models/api";
import { getDate } from "../../../../../../../../../../../../utils/functions";

export const getModalWindowList = (
	transaction: IAdminAccountHistoryRow,
): TModalWindowItem[] => {
	const list: TModalWindowItem[] = [
		{
			label: "Отправитель",
			icon: <PersonTieIcon />,
			text: transaction.sender,
		},
		{
			label: "Получатель",
			icon: <PersonIcon />,
			text: transaction.recipient,
		},
	];

	!!transaction.wallet &&
		list.push({
			label: "В кошелёк",
			icon: <WalletIcon />,
			text: `${transaction.wallet}`,
		});

	!!transaction.moneyBox &&
		list.push({
			label: "В копилку",
			icon: <PiggyBankIcon />,
			text: `${transaction.moneyBox}`,
		});

	list.push(
		{
			label: "Дата",
			icon: <CalendarIcon />,
			text: getDate(new Date(transaction.createdAt)),
		},
		{
			label: "Описание",
			icon: <ListIcon />,
			text: transaction.desc || "-",
		},
	);

	return list;
};
