import React from "react";

import { CreditCard, Loader } from "../../../../../common";

import { ReactComponent as BankIcon } from "../../../../../../assets/bankSmall.svg";
import { SystemMessageContext } from "../../../../../../hocs";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks";
import { getBankBalanceThunk } from "../../../../../../store/thunks/bank";

const BankBalance: React.FC = () => {
	const { bank, isLoading } = useAppSelector((state) => state.bankReducer);
	const dispatch = useAppDispatch();
	const { showSystemMessage } = React.useContext(SystemMessageContext);

	React.useEffect(() => {
		dispatch(getBankBalanceThunk({ showSystemMessage }));
		// eslint-disable-next-line
	}, [dispatch]);

	return (
		<React.Fragment>
			<CreditCard title={"Банк"} amount={bank} icon={<BankIcon />} />
			<Loader isLoading={isLoading} />
		</React.Fragment>
	);
};

export default BankBalance;
