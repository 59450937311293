import { Formik } from "formik";
import React from "react";

import { ChangePasswordSchema } from "./utils/ChangePasswordSchema";

import { FormikItem, Loader } from "../../../../../../../common";

import { ReactComponent as Lock } from "../../../../../../../../assets/lock.svg";
import { SystemMessageContext } from "../../../../../../../../hocs";
import { useAppDispatch, useAppSelector } from "../../../../../../../../hooks";
import { FormStyles } from "../../../../../../../../mui/commonStyles";
import { changePasswordThunk } from "../../../../../../../../store/thunks/credential";

const ChangePassword: React.FC<TProps> = ({ setIsEditPasswordMode }) => {
	const { isLoading } = useAppSelector((state) => state.credentialReducer);
	const { showSystemMessage } = React.useContext(SystemMessageContext);
	const dispatch = useAppDispatch();

	return (
		<div>
			<Formik
				initialValues={{
					oldPassword: "",
					newPassword: "",
					confirmation: "",
				}}
				validationSchema={ChangePasswordSchema}
				onSubmit={(values) => {
					dispatch(changePasswordThunk({ ...values, showSystemMessage }));
				}}
				enableReinitialize
			>
				{({ values, handleChange, handleSubmit, errors, touched }) => (
					<form onSubmit={handleSubmit}>
						<FormStyles.Wrapper>
							<FormStyles.Title>Изменить пароль</FormStyles.Title>
							<FormikItem
								id={"oldPassword"}
								label="Старый пароль&#42;"
								type={"password"}
								values={values}
								errors={errors}
								onChange={handleChange}
								touched={touched}
								Icon={Lock}
							/>
							<FormikItem
								id={"newPassword"}
								label="Новый пароль&#42;"
								type={"password"}
								values={values}
								errors={errors}
								onChange={handleChange}
								touched={touched}
								Icon={Lock}
							/>
							<FormikItem
								id={"confirmation"}
								label="Подтвердите пароль&#42;"
								type={"password"}
								values={values}
								errors={errors}
								onChange={handleChange}
								touched={touched}
								Icon={Lock}
							/>
							<FormStyles.ButtonsWrapper>
								<FormStyles.SubmitButton variant="contained" type={"submit"}>
									Сохранить
								</FormStyles.SubmitButton>
								<FormStyles.CancelButton
									variant="contained"
									onClick={() => {
										setIsEditPasswordMode(false);
									}}
								>
									Отмена
								</FormStyles.CancelButton>
							</FormStyles.ButtonsWrapper>
						</FormStyles.Wrapper>
					</form>
				)}
			</Formik>
			<Loader isLoading={isLoading} />
		</div>
	);
};

type TProps = {
	setIsEditPasswordMode: (arg: boolean) => void;
};

export default ChangePassword;
