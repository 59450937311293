import { Formik } from "formik";
import React from "react";

import { ProfileSchema } from "./utils";

import { FormikItem, Loader } from "../../../../../../../common";

import { ReactComponent as At } from "../../../../../../../../assets/at.svg";
import { SystemMessageContext } from "../../../../../../../../hocs";
import { useAppDispatch, useAppSelector } from "../../../../../../../../hooks";
import { FormStyles } from "../../../../../../../../mui/commonStyles";
import { updateProfileThunk } from "../../../../../../../../store/thunks/profile";

const ProfileInfoForm: React.FC<TProps> = ({ setEditDataMode }) => {
	const { isLoading, email } = useAppSelector((state) => state.profileReducer);
	const { showSystemMessage } = React.useContext(SystemMessageContext);
	const dispatch = useAppDispatch();

	return (
		<div>
			<Formik
				initialValues={{
					email,
				}}
				enableReinitialize
				validationSchema={ProfileSchema}
				onSubmit={async (values) => {
					const request = await dispatch(
						updateProfileThunk({ ...values, showSystemMessage }),
					);
					if (request.meta.requestStatus === "fulfilled") {
						setEditDataMode(false);
					}
				}}
			>
				{({
					values,
					handleChange,
					handleSubmit,
					errors,
					touched,
					initialValues,
				}) => (
					<form onSubmit={handleSubmit}>
						<FormStyles.Wrapper>
							<FormStyles.Title>Изменить данные</FormStyles.Title>
							<FormikItem
								id={"email"}
								label={"Адрес электронной почты"}
								type={"text"}
								values={values}
								errors={errors}
								onChange={handleChange}
								touched={touched}
								Icon={At}
							/>
							<FormStyles.ButtonsWrapper>
								<FormStyles.SubmitButton
									variant="contained"
									type={"submit"}
									disabled={values.email === initialValues.email}
								>
									Сохранить
								</FormStyles.SubmitButton>
								<FormStyles.CancelButton
									variant="contained"
									onClick={() => {
										setEditDataMode(false);
									}}
								>
									Отмена
								</FormStyles.CancelButton>
							</FormStyles.ButtonsWrapper>
						</FormStyles.Wrapper>
					</form>
				)}
			</Formik>
			<Loader isLoading={isLoading} />
		</div>
	);
};

type TProps = {
	setEditDataMode: (isEditMode: boolean) => void;
};

export default ProfileInfoForm;
