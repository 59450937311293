import { Formik } from "formik";
import React from "react";

import { PercentSchema } from "./utils/PercentSchema";

import { FormikItem, Loader } from "../../../common";

import { ReactComponent as Chart } from "../../../../assets/chart.svg";
import { ReactComponent as Logo } from "../../../../assets/coinsAndPercent.svg";
import { ReactComponent as CoinsStackAndPercent } from "../../../../assets/coinsStackWithPercent.svg";
import { ReactComponent as MagnifyingGlass } from "../../../../assets/magnifyingGlass.svg";
import { SystemMessageContext } from "../../../../hocs";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { FormStyles, MainStyles } from "../../../../mui/commonStyles";
import { percentSlice } from "../../../../store/slices";
import { getPercentsThunk, setPercentsThunk } from "../../../../store/thunks";

const Percent: React.FC = () => {
	const { isLoading, cashback, income, marketing, weekly, isChanged } =
		useAppSelector((state) => state.percentReducer);
	const { setChanged } = percentSlice.actions;
	const { showSystemMessage } = React.useContext(SystemMessageContext);
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		dispatch(getPercentsThunk({ showSystemMessage }));
		// eslint-disable-next-line
	}, []);

	return (
		<MainStyles.Wrapper>
			<MainStyles.LogoContainer>
				<Logo />
			</MainStyles.LogoContainer>
			<Formik
				initialValues={{
					cashback,
					income,
					marketing,
					weekly,
				}}
				validationSchema={PercentSchema}
				onSubmit={(values) => {
					dispatch(setPercentsThunk({ ...values, showSystemMessage }));
				}}
				enableReinitialize
			>
				{({ values, handleChange, handleSubmit, errors, touched }) => (
					<form onSubmit={handleSubmit}>
						<FormStyles.Wrapper>
							<FormStyles.Title>Проценты</FormStyles.Title>
							<FormikItem
								id={"cashback"}
								label="Кэшбек, %"
								type={"number"}
								values={values}
								errors={errors}
								onChange={(event) => {
									handleChange(event);
									dispatch(setChanged());
								}}
								touched={touched}
								Icon={CoinsStackAndPercent}
							/>
							<FormikItem
								id={"marketing"}
								label="Маркетинг, %"
								type={"number"}
								values={values}
								errors={errors}
								onChange={(event) => {
									handleChange(event);
									dispatch(setChanged());
								}}
								touched={touched}
								Icon={Chart}
							/>
							<FormikItem
								id={"weekly"}
								label="Еженедельный, %"
								type={"number"}
								values={values}
								errors={errors}
								onChange={(event) => {
									handleChange(event);
									dispatch(setChanged());
								}}
								touched={touched}
								Icon={MagnifyingGlass}
							/>
							<FormikItem
								id={"income"}
								label="Доход, %"
								type={"number"}
								values={values}
								errors={errors}
								onChange={(event) => {
									handleChange(event);
									dispatch(setChanged());
								}}
								touched={touched}
								Icon={MagnifyingGlass}
							/>
							<FormStyles.SubmitButton
								variant="contained"
								type={"submit"}
								disabled={!isChanged}
							>
								Сохранить
							</FormStyles.SubmitButton>
						</FormStyles.Wrapper>
					</form>
				)}
			</Formik>
			<Loader isLoading={isLoading} />
		</MainStyles.Wrapper>
	);
};

export default Percent;
