import { Box, styled } from "@mui/material";

const Wrapper = styled(Box)({
	maxHeight: "80vh",
	overflowY: "auto",
});

const Title = styled("h3")(({ theme }) => ({
	display: "flex",
	justifyContent: "flex-start",
	position: "sticky",
	top: 0,
	height: "3.5em",
	margin: 0,
	padding: "0.5em 0",
	color: theme.palette.white,
	fontSize: "1.25rem",
	[theme.breakpoints.down("tablet")]: {
		fontSize: "1.125rem",
	},
}));

const List = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "flex-start",
	rowGap: "1em",
	[theme.breakpoints.down("tablet")]: {
		fontSize: "0.875rem",
	},
}));

const StyledRefillHistory = {
	Wrapper,
	Title,
	List,
};

export default StyledRefillHistory;
