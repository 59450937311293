import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios/index";

import { TShowSystemMessage } from "../../../hocs/SystemMessageContextWrapper/utils/types";
import { $api } from "../../../http";
import { IAccount, IReject } from "../../../models/api";

export const remittanceThunk = createAsyncThunk<
	IAccount,
	{
		recipientId: number;
		amount: number;
		desc: string;
		showSystemMessage: TShowSystemMessage;
	},
	{
		rejectValue: IReject;
	}
>("remittance/remittanceThunk", async (args, { rejectWithValue }) => {
	const { recipientId, amount, desc, showSystemMessage } = args;
	try {
		const response = await $api.post<IAccount>("remittance", {
			recipientId: Number(recipientId),
			amount: Number(amount),
			desc,
		});
		showSystemMessage("Операция прошла успешно", "success");
		return response.data;
	} catch (err) {
		// это исключение, которое выбрасывает Axios, ответ от сервера он положит в поле
		// "data" ({ error, message, statusCode })
		const error: AxiosError<IReject> = err as AxiosError<IReject>; // cast the error for access
		showSystemMessage(
			typeof error.response?.data.message === "string"
				? error.response?.data.message
				: "Ошибка выполнения операции",
			"error",
		);
		// Thunk выбросит своё исключение с полем "payload", равным указанному ниже объекту
		return rejectWithValue({
			error: false,
			message: "Ошибка выполнения операции",
			statusCode: 500,
		});
	}
});
