import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AxiosError } from "axios/index";

import { TShowSystemMessage } from "../../../hocs/SystemMessageContextWrapper/utils/types";
import { API_URL } from "../../../http";
import { IReject, IResponse } from "../../../models/api";

export const restorePasswordThunk = createAsyncThunk<
	IResponse<undefined>,
	{
		login: string;
		phone: string;
		showSystemMessage: TShowSystemMessage;
	},
	{
		rejectValue: IReject;
	}
>("client/restorePasswordThunk", async (args, { rejectWithValue }) => {
	const { showSystemMessage } = args;
	try {
		const response = await axios.put<IResponse<undefined>>(
			`${API_URL}/client/restore.password`,
			{
				login: args.login || undefined,
				phone: args.phone || undefined,
			},
		);
		return response.data;
	} catch (err) {
		const error: AxiosError<IReject> = err as AxiosError<IReject>; // cast the error for access
		showSystemMessage(
			typeof error.response?.data.message === "string"
				? error.response?.data.message
				: "Ошибка выполнения операции",
			"error",
		);
		return rejectWithValue(
			error.response?.data || {
				error: true,
				message: "Ошибка выполнения операции",
				statusCode: 500,
			},
		);
	}
});
