import { Tooltip } from "@mui/material";
import React from "react";

import Styled from "./ListItemLink.style";

import StyledListItem from "../ListItem/ListItem.style";

import { ReactComponent as ThreeMans } from "../../../../../../../../../../assets/threeMans.svg";
import { SystemMessageContext } from "../../../../../../../../../../hocs";
import { useAppSelector } from "../../../../../../../../../../hooks";

const ListItemLink: React.FC = () => {
	const { refLink } = useAppSelector((state) => state.profileReducer);
	const { showSystemMessage } = React.useContext(SystemMessageContext);

	const handleCopyInClipboard = () => {
		showSystemMessage("Скопированно в буфер", "success");
		window.navigator.clipboard.writeText(refLink);
	};

	return (
		<StyledListItem.ListItem>
			<StyledListItem.Icon>
				<ThreeMans />
			</StyledListItem.Icon>
			<StyledListItem.Container>
				<StyledListItem.LabelWrapper>
					<StyledListItem.Label>Реферальная ссылка</StyledListItem.Label>
				</StyledListItem.LabelWrapper>
				<StyledListItem.InfoWrapper>
					<Tooltip title="Копировать">
						<Styled.Link onClick={handleCopyInClipboard}>{refLink}</Styled.Link>
					</Tooltip>
				</StyledListItem.InfoWrapper>
			</StyledListItem.Container>
		</StyledListItem.ListItem>
	);
};

export default ListItemLink;
