import React from "react";

import Styled from "./RegistrationLink.style";

import { Paths } from "../../../../paths";

const RegistrationLink: React.FC = () => {
	return (
		<Styled.Wrapper>
			<Styled.Reference href={Paths.restorePassRequest}>
				Забыли пароль?
			</Styled.Reference>
			<Styled.Reference href={Paths.registration}>Регистрация</Styled.Reference>
		</Styled.Wrapper>
	);
};

export default RegistrationLink;
