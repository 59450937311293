import { Box, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	rowGap: "2.5em",
	alignItems: "center",
	[theme.breakpoints.down("tablet")]: {
		rowGap: "1.25em",
	},
}));

const LogoContainer = styled(Box)(({ theme }) => ({
	[theme.breakpoints.down("tablet")]: {
		display: "flex",
		justifyContent: "center",
		overflow: "hidden",
		"& svg": {
			minWidth: "45em",
			transform: "scale(0.6)",
		},
	},
}));

const CardWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	alignItems: "flex-start",
	columnGap: "11em",
	[theme.breakpoints.down("tablet")]: {
		flexDirection: "column",
		rowGap: "1em",
	},
}));

export const MainStyles = {
	Wrapper,
	LogoContainer,
	CardWrapper,
};
