import React from "react";

import Styled from "./Logout.style";

import { useAppDispatch } from "../../../../hooks";
import { actionTypes } from "../../../../store/consts";
import { logout } from "../../../../store/thunks/auth";

const Logout: React.FC = () => {
	const dispatch = useAppDispatch();
	const resetStore = () => {
		dispatch(logout());
		dispatch({ type: actionTypes.resetStore });
	};

	return (
		<Styled.Wrapper>
			<Styled.LogoutButton variant={"contained"} onClick={resetStore}>
				Выйти
			</Styled.LogoutButton>
		</Styled.Wrapper>
	);
};

export default Logout;
