import { Box, styled } from "@mui/material";

import Back from "../../assets/noise.png";

const Wrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	height: "100vh",
	textAlign: "center",
	background: `url(${Back}),
	  radial-gradient(100% 215.42% at 0% 0%, ${theme.palette.green} 0%,${theme.palette.hoverGreen} 100%)`,
	backgroundSize: "cover",
}));

const IconWrapper = styled(Box)(({ theme }) => ({
	width: "auto",
	[theme.breakpoints.down("tablet")]: {
		"& svg": {
			scale: "0.75",
		},
	},
}));

const FormWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	color: "white",
	height: "28em",
	width: "27.5em",
	backgroundColor: theme.palette.white,
	padding: "2.5em 2em 2em",
	borderRadius: "30px",
	margin: "2em 0em 10em 0em",
	[theme.breakpoints.down("tablet")]: {
		height: "22.88em",
		width: "18.75em",
		margin: "0.5em 0em 0em 0em",
	},
}));

const Title = styled(Box)(({ theme }) => ({
	color: theme.palette.darkGreen,
	fontSize: "1.39em",
	marginBottom: "2em",
	[theme.breakpoints.down("tablet")]: {
		fontSize: "1.125em",
		marginBottom: "0.5em",
	},
}));

const StyledApp = {
	Wrapper,
	IconWrapper,
	FormWrapper,
	Title,
};

export default StyledApp;
