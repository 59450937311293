import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ReactComponent as Logo } from "../../../../assets/logo.svg";
import { SystemMessageContext } from "../../../../hocs";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { ConfirmStyles, FormStyles } from "../../../../mui/commonStyles";
import { Paths } from "../../../../paths";
import { setRandomPasswordThunk } from "../../../../store/thunks/client";

const NewPassConfirmForm: React.FC = () => {
	const { wasPasswordRestored } = useAppSelector(
		(state) => state.clientReducer,
	);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { showSystemMessage } = React.useContext(SystemMessageContext);

	const handleSubmit = () => {
		const link = searchParams.get("link");
		if (link) {
			dispatch(setRandomPasswordThunk({ link, showSystemMessage }));
		}
	};
	const handleCancel = () => {
		navigate(Paths.welcome);
	};

	return (
		<React.Fragment>
			{!wasPasswordRestored && (
				<ConfirmStyles.Wrapper>
					<Logo />
					<ConfirmStyles.Window>
						<ConfirmStyles.Title>
							Вы действительно хотите восстановить пароль?
						</ConfirmStyles.Title>
						<FormStyles.ButtonsWrapper>
							<FormStyles.SubmitButton
								variant="contained"
								onClick={handleSubmit}
							>
								Да
							</FormStyles.SubmitButton>
							<FormStyles.CancelButton
								variant="contained"
								onClick={handleCancel}
							>
								Нет
							</FormStyles.CancelButton>
						</FormStyles.ButtonsWrapper>
					</ConfirmStyles.Window>
				</ConfirmStyles.Wrapper>
			)}
		</React.Fragment>
	);
};

export default NewPassConfirmForm;
