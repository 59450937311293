import axios, { InternalAxiosRequestConfig } from "axios";

import { injectedStore } from "./injectStore";
import { API_URL } from "./url";

import { localStore } from "../localStorage";
import { IAuthResponse } from "../models/api";
import { actionTypes } from "../store/consts";

const $api = axios.create({
	withCredentials: true, // auto set cookies
	baseURL: API_URL,
});

$api.interceptors.request.use((config: InternalAxiosRequestConfig) => {
	const accessToken = localStore.getAccessToken();

	config.headers.Authorization = accessToken;

	return config;
});

$api.interceptors.response.use(
	(config) => config,
	async (error) => {
		const originalRequest = error.config;
		if (
			(error.response.status === 401 || error.response.status === 403) &&
			originalRequest &&
			!originalRequest._isRetry
		) {
			originalRequest._isRetry = true;
			try {
				const response = await axios.get<IAuthResponse>(
					`${API_URL}/token/refresh`,
					{
						withCredentials: true,
					},
				);
				localStore.setAccessToken(
					`Bearer ${response.data.accessToken.replace(/"/g, "")}`,
				);
				return $api.request(originalRequest);
			} catch (e) {
				injectedStore.dispatch({ type: actionTypes.resetStore });
			}
		}
		throw error;
	},
);

export default $api;
