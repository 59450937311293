import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios/index";

import { TShowSystemMessage } from "../../../hocs/SystemMessageContextWrapper/utils/types";
import { $api, API_URL } from "../../../http";
import { IReject, IResponse } from "../../../models/api";

export const changePasswordThunk = createAsyncThunk<
	IResponse<undefined>,
	{
		oldPassword: string;
		newPassword: string;
		showSystemMessage: TShowSystemMessage;
	},
	{
		rejectValue: IReject;
	}
>("credential/changePasswordThunk", async (args, { rejectWithValue }) => {
	const { oldPassword, newPassword, showSystemMessage } = args;
	try {
		const response = await $api.put<IResponse<undefined>>(
			`${API_URL}/credential`,
			{
				oldPassword,
				newPassword,
			},
		);
		showSystemMessage("Пароль изменён успешно", "success");
		return response.data;
	} catch (err) {
		const error: AxiosError<IReject> = err as AxiosError<IReject>; // cast the error for access
		showSystemMessage(
			typeof error.response?.data.message === "string"
				? error.response?.data.message
				: "Ошибка выполнения операции",
			"error",
		);
		return rejectWithValue(
			error.response?.data || {
				error: true,
				message: "Ошибка выполнения операции",
				statusCode: 500,
			},
		);
	}
});
