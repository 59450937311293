import React from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ConfirmStyles, FormStyles } from "../../mui/commonStyles";
import { Paths } from "../../paths";

const Confirmation: React.FC = () => {
	const navigate = useNavigate();
	const handleClick = () => {
		navigate(Paths.welcome);
	};

	return (
		<ConfirmStyles.Wrapper>
			<Logo />
			<ConfirmStyles.Window>
				<ConfirmStyles.Title>
					Адрес электронной почты успешно подтверждён, для входа в приложение
					используйте Логин и Пароль, указанный вами при регистрации
				</ConfirmStyles.Title>
				<FormStyles.SubmitButton variant="contained" onClick={handleClick}>
					Понятно
				</FormStyles.SubmitButton>
			</ConfirmStyles.Window>
		</ConfirmStyles.Wrapper>
	);
};

export default Confirmation;
