import React from "react";

import { useAppDispatch } from "./useAppDispatch";

import { refresh } from "../store/thunks";

export const useRefresh = () => {
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		dispatch(refresh());
	}, [dispatch]);
};
