import { Formik } from "formik";
import React from "react";

import { AddAdminSchema } from "./utils/AddAdminSchema";

import { FormikItem, Loader } from "../../../../../common";

import { ReactComponent as NumberIcon } from "../../../../../../assets/number.svg";
import { SystemMessageContext, WithClientName } from "../../../../../../hocs";
import {
	useAppDispatch,
	useAppSelector,
	useDebounce,
} from "../../../../../../hooks";
import { FormStyles } from "../../../../../../mui/commonStyles";
import { personalDataSlice } from "../../../../../../store/slices";
import { getPersonalDataThunk } from "../../../../../../store/thunks/personalData";
import { addAdminThunk } from "../../../../../../store/thunks/role/addAdmin.thunk";

const AddAdmin: React.FC = () => {
	const [id, setId] = React.useState(0);
	const { clientId, isLoading } = useAppSelector(
		(state) => state.personalDataReducer,
	);
	const { showSystemMessage } = React.useContext(SystemMessageContext);
	const { resetPersonInfo } = personalDataSlice.actions;
	const dispatch = useAppDispatch();
	const debounced = useDebounce(id);

	React.useEffect(() => {
		if (debounced && Number.isInteger(debounced)) {
			dispatch(
				getPersonalDataThunk({ clientId: debounced, showSystemMessage }),
			);
		} else {
			dispatch(resetPersonInfo());
		}
		// eslint-disable-next-line
	}, [debounced]);

	return (
		<React.Fragment>
			<Formik
				initialValues={{
					clientId,
				}}
				validationSchema={AddAdminSchema}
				onSubmit={(values, actions) => {
					dispatch(addAdminThunk({ ...values, showSystemMessage }));
					actions.resetForm();
					setId(0);
				}}
			>
				{({ values, handleChange, handleSubmit, errors, touched }) => (
					<form onSubmit={handleSubmit}>
						<FormStyles.Wrapper>
							<FormStyles.Title>Добавить администратора</FormStyles.Title>
							<WithClientName>
								<FormikItem
									id={"clientId"}
									label="Номер клиента&#42;"
									type={"number"}
									values={values}
									errors={errors}
									onChange={(event) => {
										setId(Number(event.target.value));
										handleChange(event);
									}}
									touched={touched}
									Icon={NumberIcon}
								/>
							</WithClientName>
							<FormStyles.SubmitButton variant="contained" type={"submit"}>
								Добавить
							</FormStyles.SubmitButton>
						</FormStyles.Wrapper>
					</form>
				)}
			</Formik>
			<Loader isLoading={isLoading} />
		</React.Fragment>
	);
};

export default AddAdmin;
