import { Formik } from "formik";
import React from "react";

import { RemittanceSchema } from "./utils/RemittanceSchema";

import { FormikItem, Loader } from "../../../../../common";

import { ReactComponent as MoneyStack } from "../../../../../../assets/moneyStack.svg";
import { ReactComponent as NumberIcon } from "../../../../../../assets/number.svg";
import { ReactComponent as ToDoList } from "../../../../../../assets/toDoList.svg";
import { SystemMessageContext } from "../../../../../../hocs";
import WithClientName from "../../../../../../hocs/WithClientName/WithClientName";
import {
	useAppDispatch,
	useAppSelector,
	useDebounce,
} from "../../../../../../hooks";
import { FormStyles } from "../../../../../../mui/commonStyles";
import {
	accountSlice,
	personalDataSlice,
} from "../../../../../../store/slices";
import { getPersonalDataThunk } from "../../../../../../store/thunks/personalData";
import { remittanceThunk } from "../../../../../../store/thunks/remittance/transferMoney.thunk";

const Remittance: React.FC = () => {
	const [id, setId] = React.useState(0);
	const { isLoading: isPersonalDataLoading } = useAppSelector(
		(state) => state.personalDataReducer,
	);
	const { isLoading } = useAppSelector((state) => state.remittanceReducer);
	const { showSystemMessage } = React.useContext(SystemMessageContext);
	const { resetPersonInfo } = personalDataSlice.actions;
	const { updateAccount } = accountSlice.actions;
	const dispatch = useAppDispatch();
	const debounced = useDebounce(id);

	React.useEffect(() => {
		if (debounced && Number.isInteger(debounced)) {
			dispatch(
				getPersonalDataThunk({ clientId: debounced, showSystemMessage }),
			);
		} else {
			dispatch(resetPersonInfo());
		}
		// eslint-disable-next-line
	}, [debounced]);

	return (
		<React.Fragment>
			<Formik
				initialValues={{
					recipientId: 0,
					amount: 0,
					desc: "",
				}}
				validationSchema={RemittanceSchema}
				onSubmit={async (values, actions) => {
					const res = await dispatch(
						remittanceThunk({ ...values, showSystemMessage }),
					);
					if (res.payload && res.meta.requestStatus === "fulfilled") {
						//@ts-ignore если статус "fulfilled", то это не ошибка и данные будут получены
						const { wallet, moneyBox } = res.payload;
						dispatch(
							updateAccount({
								wallet,
								moneyBox,
							}),
						);
						actions.resetForm();
						setId(0);
					}
				}}
				enableReinitialize
			>
				{({ values, handleChange, handleSubmit, errors, touched }) => (
					<form
						onSubmit={(event) => {
							handleSubmit(event);
						}}
					>
						<FormStyles.Wrapper>
							<FormStyles.Title>Перевод</FormStyles.Title>
							<WithClientName>
								<FormikItem
									id={"recipientId"}
									label="Номер получателя&#42;"
									type={"number"}
									values={values}
									errors={errors}
									onChange={(event) => {
										setId(Number(event.target.value));
										handleChange(event);
									}}
									touched={touched}
									Icon={NumberIcon}
								/>
							</WithClientName>
							<FormikItem
								id={"amount"}
								label="Сумма"
								type={"number"}
								values={values}
								errors={errors}
								onChange={handleChange}
								touched={touched}
								Icon={MoneyStack}
							/>
							<FormikItem
								id={"desc"}
								label="Описание"
								type={"text"}
								values={values}
								errors={errors}
								onChange={handleChange}
								touched={touched}
								Icon={ToDoList}
							/>
							<FormStyles.SubmitButton variant="contained" type={"submit"}>
								Перевести
							</FormStyles.SubmitButton>
						</FormStyles.Wrapper>
					</form>
				)}
			</Formik>
			<Loader isLoading={isLoading || isPersonalDataLoading} />
		</React.Fragment>
	);
};

export default Remittance;
