import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	styled,
} from "@mui/material";

const ModalWindow = styled(Dialog)(({ theme }) => ({
	"& [role=dialog]": {
		width: "26.3em",
		borderRadius: "30px",
	},
	[theme.breakpoints.down("tablet")]: {
		"& [role=dialog]": {
			width: "18.75em",
		},
	},
}));

const Title = styled(DialogTitle)(({ theme }) => ({
	color: theme.palette.darkGreen,
	fontSize: "1.375rem",
	padding: "1.7em 0 0.45em",
	margin: "0 auto",
	[theme.breakpoints.down("tablet")]: {
		fontSize: "1.125rem",
		padding: "1.7em 0 0",
	},
}));

const List = styled(DialogContent)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	rowGap: "0.8em",
	padding: "0 1.9em 2em",
	[theme.breakpoints.down("tablet")]: {
		rowGap: "0.5em",
	},
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: 15,
	top: 15,
	color: theme.palette.darkGreen,
	[theme.breakpoints.down("tablet")]: {
		right: 10,
		top: 10,
	},
}));

const StyledItem = {
	ModalWindow,
	Title,
	List,
	CloseButton,
};

export default StyledItem;
