import React from "react";

import { History } from "./components";

import { Pagination } from "../../../../../common";

import { useAppSelector } from "../../../../../../hooks";
import { HistoryWrapper } from "../../../../../../mui/commonStyles";
import { getMoneyBoxHistoryThunk } from "../../../../../../store/thunks/history";

const MoneyBoxHistory: React.FC = () => {
	const { moneyBoxHistory } = useAppSelector((state) => state.historyReducer);

	return (
		<HistoryWrapper.Wrapper>
			<History
				title={"История начислений"}
				transactions={moneyBoxHistory.rows}
				isLoading={false}
			/>
			<Pagination
				count={moneyBoxHistory.count}
				thunk={getMoneyBoxHistoryThunk}
			/>
		</HistoryWrapper.Wrapper>
	);
};

export default MoneyBoxHistory;
