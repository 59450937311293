import { AsyncThunk } from "@reduxjs/toolkit";
import React from "react";

import Styled from "./Pagination.style";

import { SystemMessageContext } from "../../../hocs";
import { TShowSystemMessage } from "../../../hocs/SystemMessageContextWrapper/utils/types";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IReject, IResponse } from "../../../models/api";
import { pagination } from "../../../utils/consts";

const Pagination: React.FC<TProps> = ({ account, count, thunk }) => {
	const { clientId } = useAppSelector((state) => state.authReducer);
	const [pageNumber, setPageNumber] = React.useState(1);
	const [blockNumber, setBlockNumber] = React.useState(1);
	const amountOfPagesInBlock = pagination.pagesInBlock;
	const { showSystemMessage } = React.useContext(SystemMessageContext);
	const dispatch = useAppDispatch();

	const pages = [];
	const totalPagesCount = Math.ceil(count / pagination.limit);
	const countOfBlocks = Math.ceil(totalPagesCount / amountOfPagesInBlock);
	const firstPageNumber = (blockNumber - 1) * amountOfPagesInBlock + 1;
	let lastPageNumber = firstPageNumber + (amountOfPagesInBlock - 1);

	if (totalPagesCount === 0) {
		lastPageNumber = 1;
	} else if (lastPageNumber > totalPagesCount) {
		lastPageNumber = totalPagesCount;
	}

	for (let i = firstPageNumber; i <= lastPageNumber; i++) {
		pages.push(i);
	}

	React.useEffect(() => {
		if (clientId) {
			dispatch(
				thunk({
					page: pageNumber,
					limit: pagination.limit,
					showSystemMessage,
				}),
			);
		}
		// eslint-disable-next-line
	}, [pageNumber, clientId]);

	React.useEffect(() => {
		// количество денег на счету изменилось, а значит нужно обновить историю
		if (pageNumber === 1) {
			dispatch(
				thunk({
					page: pageNumber,
					limit: pagination.limit,
					showSystemMessage,
				}),
			);
		}
		// eslint-disable-next-line
	}, [account]);

	return (
		<Styled.Wrapper>
			<Styled.ButtonsWrapper>
				<Styled.ArrowButton
					onClick={() => {
						setBlockNumber(1);
					}}
					disabled={blockNumber === 1}
				>
					≪
				</Styled.ArrowButton>
				<Styled.ArrowButton
					onClick={() => {
						setBlockNumber((number) => number - 1);
					}}
					disabled={blockNumber === 1}
				>
					&#60;
				</Styled.ArrowButton>
			</Styled.ButtonsWrapper>
			<Styled.PagesWrapper>
				{pages.map((page) => {
					return (
						<Styled.PageNumber
							key={page}
							onClick={() => {
								setPageNumber(page);
							}}
							isActive={page === pageNumber}
						>
							{page}
						</Styled.PageNumber>
					);
				})}
			</Styled.PagesWrapper>
			<Styled.ButtonsWrapper>
				<Styled.ArrowButton
					onClick={() => {
						setBlockNumber((number) => number + 1);
					}}
					disabled={!totalPagesCount || blockNumber === countOfBlocks}
				>
					&#62;
				</Styled.ArrowButton>
				<Styled.ArrowButton
					onClick={() => {
						setBlockNumber(countOfBlocks);
					}}
					disabled={!totalPagesCount || blockNumber === countOfBlocks}
				>
					≫
				</Styled.ArrowButton>
			</Styled.ButtonsWrapper>
		</Styled.Wrapper>
	);
};

type TProps = {
	account?: number;
	count: number;
	thunk: AsyncThunk<
		IResponse<{ count: number; rows: any[] }>,
		{
			page: number;
			limit: number;
			showSystemMessage: TShowSystemMessage;
		},
		{
			rejectValue: IReject;
		}
	>;
};

export default Pagination;
