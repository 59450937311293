import React from "react";

import Styled from "./ExtraInfo.style";

const ExtraInfo: React.FC = () => {
	return (
		<Styled.Wrapper>
			<Styled.Text>{new Date().getFullYear()}</Styled.Text>
			<Styled.Text>Политика конфиденциальности</Styled.Text>
			<Styled.InfoWrapper>
				<Styled.SimpleLink
					href={"https://t.me/AlexandrChazov"}
					target={"_blank"}
					rel={"noopener noreferrer"}
				>
					Разработка сайта
				</Styled.SimpleLink>
				<Styled.SimpleLink
					href={"https://t.me/Mariya_Prokopchik"}
					target={"_blank"}
					rel={"noopener noreferrer"}
				>
					Веб-дизайн
				</Styled.SimpleLink>
			</Styled.InfoWrapper>
		</Styled.Wrapper>
	);
};

export default ExtraInfo;
