import { createSlice } from "@reduxjs/toolkit";

import { loginThunk, logout, refresh, register } from "../thunks/auth";

import { IRoles } from "../../models/api";

interface TokenState {
	isAuthorized: boolean;
	clientId: number;
	clientRoles: IRoles[];
	isLoading: boolean;
}

const initialState: TokenState = {
	isAuthorized: false,
	clientId: 0,
	clientRoles: [],
	isLoading: false,
};

export const authSlice = createSlice({
	name: "authSlice",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(loginThunk.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isAuthorized = true;
			state.clientId = action.payload.clientInfo.clientId;
			state.clientRoles = action.payload.clientInfo.roles;
		});
		builder.addCase(loginThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(loginThunk.rejected, (state) => {
			state.isLoading = false;
			state.isAuthorized = false;
			state.clientId = 0;
			state.clientRoles = [];
		});

		builder.addCase(refresh.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isAuthorized = true;
			state.clientId = action.payload.clientInfo.clientId;
			state.clientRoles = action.payload.clientInfo.roles;
		});
		builder.addCase(refresh.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(refresh.rejected, () => initialState);

		builder.addCase(register.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isAuthorized = true;
			state.clientId = action.payload.clientInfo.clientId;
			state.clientRoles = action.payload.clientInfo.roles;
		});
		builder.addCase(register.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(register.rejected, (state) => {
			state.isLoading = false;
			state.isAuthorized = false;
			state.clientId = 0;
			state.clientRoles = [];
		});

		builder.addCase(logout.fulfilled, (state) => {
			state.isLoading = false;
		});
		builder.addCase(logout.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(logout.rejected, (state) => {
			state.isLoading = false;
		});
	},
});
