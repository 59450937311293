import { Box, Typography, styled } from "@mui/material";

const Wrapper = styled(Box)({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	rowGap: "1.6em",
});

const Title = styled(Typography)(({ theme }) => ({
	fontSize: "1.375rem",
	marginTop: "0.4em",
	[theme.breakpoints.down("tablet")]: {
		fontSize: "1.125rem",
	},
}));

const AdminsListWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	rowGap: "0.625em",
	[theme.breakpoints.down("tablet")]: {
		rowGap: "1em",
	},
}));

const StyledAdminsList = {
	Wrapper,
	Title,
	AdminsListWrapper,
};

export default StyledAdminsList;
