import React from "react";

import { CreditCard } from "../../../../../common";

import { ReactComponent as BankIcon } from "../../../../../../assets/bankSmall.svg";
import { SystemMessageContext } from "../../../../../../hocs";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks";
import { getAdminAccountThunk } from "../../../../../../store/thunks/admin";

const AdminBalance: React.FC = () => {
	const { clientId } = useAppSelector((state) => state.authReducer);
	const { balance } = useAppSelector((state) => state.adminReducer);
	const { showSystemMessage } = React.useContext(SystemMessageContext);
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		if (clientId) {
			dispatch(getAdminAccountThunk({ clientId, showSystemMessage }));
		}
		// eslint-disable-next-line
	}, [clientId]);

	return (
		<React.Fragment>
			<CreditCard
				title={"Счёт администратора"}
				amount={balance}
				icon={<BankIcon />}
			/>
		</React.Fragment>
	);
};

export default AdminBalance;
