import React from "react";

import Styled from "./Address.style";

const Address: React.FC = () => {
	return (
		<Styled.Wrapper>
			<div>
				<Styled.Title variant={"h6"}>Адрес:</Styled.Title>
				<Styled.SimpleLink
					href={`https://www.google.ru/maps/place/%D0%93%D0%BE%D1%81%D1%82%D0%B8
					%D0%BD%D0%B8%D1%86%D0%B0+%22%D0%9E%D0%BA%D1%82%D1%8F%D0%B1%D1%80%D1%8C
					%D1%81%D0%BA%D0%B0%D1%8F%22,+%D0%9E%D1%84%D0%B8%D1%81%D0%BD%D1%8B%D0%B
					9+%D0%A6%D0%B5%D0%BD%D1%82%D1%80/@59.9317984,30.3618615,18.25z/data=!4
					m6!3m5!1s0x469631a3537f4a55:0x2aa5bcb90b211b9e!8m2!3d59.9320895!4d30.3
					621572!16s%2Fg%2F1tfphgpy?entry=ttu`}
					target={"_blank"}
					rel={"noopener noreferrer"}
				>
					<div>РФ, г. Санкт-Петербург,</div>
					<div>Лиговский проспект 10/118,</div>
					<div>офисный центр гостиницы Октябрьской,</div>
					<div>офис 220, 1-й этаж, вход с 1-й Советской ул.</div>
				</Styled.SimpleLink>
			</div>
		</Styled.Wrapper>
	);
};

export default Address;
