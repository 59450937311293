import * as Yup from "yup";

export const SavePersonDataSchema = Yup.object().shape({
	clientId: Yup.number()
		.positive("укажите положительное число")
		.integer("должно быть целым числом")
		.lessThan(4294967295, "слишком большое число") // INT
		.required("обязательное поле"),
	name: Yup.string()
		.min(2, "не менее 2 символов")
		.max(30, "не более 30 символов")
		.matches(/^[ёа-яА-Я-]+$/, "только буквы кириллицы")
		.required("обязательное поле"),
	surname: Yup.string()
		.min(2, "не менее 2 символов")
		.max(30, "не более 30 символов")
		.matches(/^[ёа-яА-Я-]+$/, "только буквы кириллицы")
		.required("обязательное поле"),
	patronymic: Yup.string()
		.min(2, "не менее 2 символов")
		.max(30, "не более 30 символов")
		.matches(/^[ёа-яА-Я-]+$/, "только буквы кириллицы")
		.required("обязательное поле"),
	phone: Yup.string()
		.min(6, "не менее 6 символов")
		.max(16, "не более 16 символов")
		.matches(/^\+\d+$/, "номер в формате +7123456789"),
});
