import React from "react";

import Styled from "./Inviter.style";
import { initDetails } from "./consts";

import { ModalWindow } from "../../../../../../../common";

import { ReactComponent as AtIcon } from "../../../../../../../../assets/at.svg";
import { ReactComponent as CalendarIcon } from "../../../../../../../../assets/calendar.svg";
import { ReactComponent as NumberIcon } from "../../../../../../../../assets/number.svg";
import { ReactComponent as PersonIcon } from "../../../../../../../../assets/person.svg";
import { ReactComponent as PhoneIcon } from "../../../../../../../../assets/phone.svg";
import { $api } from "../../../../../../../../http";
import { TModalWindowItem } from "../../../../../../../../models";
import {
	IInvitee,
	IInvitees,
	IResponse,
} from "../../../../../../../../models/api";
import { IClientInfo } from "../../../../../../../../models/api/client";
import { getDate } from "../../../../../../../../utils/functions";

const Inviter: React.FC<TProps> = ({
	inviterId,
	surname,
	name,
	patronymic,
	level,
}) => {
	const [invitees, setInvitees] = React.useState<IInvitee[]>([]);
	const [open, setOpen] = React.useState(false);
	const [wasRequest, setWasRequest] = React.useState(false);
	const [showInvitees, setShowInvitees] = React.useState(false);
	const [details, setDetails] = React.useState<IClientInfo>(initDetails);
	const getDetails = async () => {
		try {
			if (!details.id) {
				const response = await $api.get<IResponse<IClientInfo>>("client/info", {
					params: { clientId: inviterId },
				});
				setDetails(response.data.data);
			}
			setOpen(true);
		} catch {
			setOpen(false);
		}
	};
	const handleClose = () => setOpen(false);

	const getInvitees = async () => {
		if (showInvitees) {
			setShowInvitees(false);
		} else {
			if (!wasRequest) {
				try {
					const response = await $api.get<IResponse<IInvitees>>("reference", {
						params: { clientId: inviterId },
					});
					setInvitees(response.data.data.invitees);
					setShowInvitees(true);
					setWasRequest(true);
				} catch {
					setInvitees([]);
				}
			} else {
				setShowInvitees(true);
			}
		}
	};

	const list: TModalWindowItem[] = [
		{
			label: "ФИО",
			icon: <PersonIcon />,
			text: `${details.surname} ${details.name} ${details.patronymic}`,
		},
		{
			label: "Личный номер",
			icon: <NumberIcon />,
			text: `${details.id}`,
		},
		{
			label: "Номер телефона",
			icon: <PhoneIcon />,
			text: `${details.contacts.phone}`,
		},
		{
			label: "Почта",
			icon: <AtIcon />,
			text: `${details.contacts.email}`,
		},
		{
			label: "Дата регистрации",
			icon: <CalendarIcon />,
			text: `${getDate(new Date(details.createdAt))}`,
		},
	];

	return (
		<Styled.Wrapper>
			<Styled.Arrow onClick={getInvitees} />
			<div>
				{`${surname} ${name} ${patronymic} ур.${level}`}
				<Styled.Details onClick={getDetails} />
				{showInvitees &&
					invitees.map((el) => {
						return (
							<React.Fragment key={el.id}>
								<Inviter
									inviterId={el.id}
									surname={el.client.surname}
									name={el.client.name}
									patronymic={el.client.patronymic}
									level={level + 1}
								/>
							</React.Fragment>
						);
					})}
			</div>
			<ModalWindow open={open} handleClose={handleClose} details={list} />
		</Styled.Wrapper>
	);
};

type TProps = {
	inviterId: number;
	surname: string;
	name: string;
	patronymic: string;
	level: number;
};

export default Inviter;
