import React from "react";

import { BankBalance, BankHistory, RefillForm } from "./components";

import { ReactComponent as Logo } from "../../../../assets/bank.svg";
import { MainStyles } from "../../../../mui/commonStyles";

const Bank: React.FC = () => {
	return (
		<MainStyles.Wrapper>
			<MainStyles.LogoContainer>
				<Logo />
			</MainStyles.LogoContainer>
			<MainStyles.CardWrapper>
				<BankBalance />
				<RefillForm />
			</MainStyles.CardWrapper>
			<BankHistory />
		</MainStyles.Wrapper>
	);
};

export default Bank;
