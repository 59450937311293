import { createSlice } from "@reduxjs/toolkit";

import { restorePasswordThunk, setRandomPasswordThunk } from "../thunks/client";

type TClient = {
	isLoading: boolean;
	isRestoreReqSent: boolean;
	wasPasswordRestored: boolean;
};

const initialState: TClient = {
	isLoading: false,
	isRestoreReqSent: false,
	wasPasswordRestored: false,
};

export const clientSlice = createSlice({
	name: "clientSlice",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(restorePasswordThunk.fulfilled, (state, action) => {
			state.isLoading = false;
			state.isRestoreReqSent = action.payload.error ? false : true;
		});
		builder.addCase(restorePasswordThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(restorePasswordThunk.rejected, (state) => {
			state.isLoading = false;
			state.isRestoreReqSent = false;
		});

		builder.addCase(setRandomPasswordThunk.fulfilled, (state, action) => {
			state.isLoading = false;
			state.wasPasswordRestored = action.payload.error ? false : true;
		});
		builder.addCase(setRandomPasswordThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(setRandomPasswordThunk.rejected, (state) => {
			state.isLoading = false;
			state.wasPasswordRestored = false;
		});
	},
});
