import React from "react";

import Styled from "./ListItem.style";

import { TModalWindowItem } from "../../../../../models";

const ListItem: React.FC<TModalWindowItem> = ({ label, icon, text }) => {
	return (
		<Styled.Wrapper>
			<Styled.Label>{label}</Styled.Label>
			<Styled.IconWrapper>{icon}</Styled.IconWrapper>
			<Styled.Text>{text}</Styled.Text>
		</Styled.Wrapper>
	);
};

export default ListItem;
