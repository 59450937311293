import { Box, Typography, styled } from "@mui/material";

const fontSize = "1.125rem";

const Account = styled(Box)(({ theme }) => ({
	position: "relative",
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "28.75em",
	height: "17.81em",
	backgroundImage: `linear-gradient(
			180deg,
		 	${theme.palette.lightGrey} 74%,
		  ${theme.palette.yellow} 74%,
	 		${theme.palette.yellow} 100%
	 	)`,
	borderRadius: "30px",
	padding: "2.4em 1.87em 1.6em",
	[theme.breakpoints.down("tablet")]: {
		width: "18.75em",
		height: "12.5em",
		padding: "1.2em 1em 1em 1em",
		borderRadius: "20px",
	},
}));

const Title = styled(Typography)(({ theme }) => ({
	textTransform: "uppercase",
	color: theme.palette.green,
	position: "relative",
	paddingLeft: "1.5em",
	fontSize,
	"&::before": {
		position: "absolute",
		top: "7px",
		left: "1px",
		content: "' '",
		width: "19.78px",
		height: "1px",
		backgroundColor: theme.palette.green,
	},
	"&::after": {
		position: "absolute",
		top: "13px",
		left: "11px",
		content: "' '",
		width: "9.89px",
		height: "1px",
		backgroundColor: theme.palette.darkGreen,
	},
	[theme.breakpoints.down("tablet")]: {
		fontSize: "0.875rem",
		paddingLeft: "1.7em",
		"&::before": {
			left: "-1px",
		},
		"&::after": {
			left: "9px",
		},
	},
}));

const IconWrapper = styled(Box)(({ theme }) => ({
	"& svg": {
		position: "absolute",
		top: 26,
		right: 27,
	},
	[theme.breakpoints.down("tablet")]: {
		"& svg": {
			transform: "scale(0.8)",
			top: 8,
			right: 18,
		},
	},
}));

const SimCardWrapper = styled(Box)(({ theme }) => ({
	[theme.breakpoints.down("tablet")]: {
		height: "3em",
		"& svg": {
			transform: "translate(-10px, 6px) scale(0.7)",
		},
	},
}));

const Amount = styled(Typography)(({ theme }) => ({
	margin: "0.5em 0 1.4em 0",
	fontSize: "1.5rem",
	fontWeight: 600,
	color: theme.palette.green,
	[theme.breakpoints.down("tablet")]: {
		fontSize: "1.25rem",
	},
}));

const IdWrapper = styled(Box)({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "flex-end",
	width: "100%",
});

const IdText = styled(Typography)(({ theme }) => ({
	fontSize,
	color: theme.palette.darkGreen,
	[theme.breakpoints.down("tablet")]: {
		fontSize: "0.875rem",
	},
}));

const IdNumber = styled(Typography)(({ theme }) => ({
	fontSize: "1.25em",
	color: theme.palette.darkGreen,
	[theme.breakpoints.down("tablet")]: {
		fontSize: "1rem",
	},
}));

const StyledCreditCard = {
	Account,
	Amount,
	IdWrapper,
	IdText,
	IdNumber,
	IconWrapper,
	SimCardWrapper,
	Title,
};

export default StyledCreditCard;
