import { createSlice } from "@reduxjs/toolkit";

import {
	getPersonalDataThunk,
	savePersonalDataThunk,
} from "../thunks/personalData";

type TClient = {
	clientId: number;
	name: string;
	surname: string;
	patronymic: string;
	phone: string;
	login: string;
	isLoading: boolean;
};

const initialState: TClient = {
	clientId: 0,
	name: "",
	surname: "",
	patronymic: "",
	phone: "",
	login: "",
	isLoading: false,
};

export const personalDataSlice = createSlice({
	name: "personSlice",
	initialState,
	reducers: {
		resetPersonInfo: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(getPersonalDataThunk.fulfilled, (state, action) => {
			state.clientId = action.payload.data.id;
			state.name = action.payload.data.name;
			state.surname = action.payload.data.surname;
			state.patronymic = action.payload.data.patronymic;
			state.phone = action.payload.data.contacts.phone;
			state.login = action.payload.data.credential.login;
			state.isLoading = false;
		});
		builder.addCase(getPersonalDataThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getPersonalDataThunk.rejected, () => initialState);

		builder.addCase(savePersonalDataThunk.fulfilled, () => initialState);
		builder.addCase(savePersonalDataThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(savePersonalDataThunk.rejected, (state) => {
			state.isLoading = false;
		});
	},
});
