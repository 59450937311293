import CloseIcon from "@mui/icons-material/Close";
import React from "react";

import Styled from "./ModalWindow.style";
import { ListItem } from "./components";

import { TModalWindowItem } from "../../../models";

const ModalWindow: React.FC<TProps> = ({ open, handleClose, details }) => {
	return (
		<Styled.ModalWindow
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			open={open}
		>
			<Styled.Title>Подробная информация</Styled.Title>
			<Styled.List id="modal-modal-description" sx={{ mt: 2 }}>
				{details.map((item, index) => {
					return (
						<React.Fragment key={index}>
							<ListItem label={item.label} icon={item.icon} text={item.text} />
						</React.Fragment>
					);
				})}
			</Styled.List>
			<Styled.CloseButton aria-label="close" onClick={handleClose}>
				<CloseIcon />
			</Styled.CloseButton>
		</Styled.ModalWindow>
	);
};

type TProps = {
	open: boolean;
	handleClose: () => void;
	details: TModalWindowItem[];
};

export default ModalWindow;
