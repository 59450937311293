import React from "react";

import Styled from "./AdminsList.style";
import { Admin } from "./components";

import { SystemMessageContext } from "../../../../../../hocs";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks";
import { getAdminsThunk } from "../../../../../../store/thunks";

const AdminsList: React.FC = () => {
	const { clientId } = useAppSelector((state) => state.authReducer);
	const { adminsList } = useAppSelector((state) => state.roleReducer);

	const dispatch = useAppDispatch();
	const { showSystemMessage } = React.useContext(SystemMessageContext);

	React.useEffect(() => {
		dispatch(getAdminsThunk({ showSystemMessage }));
		// eslint-disable-next-line
	}, []);

	return (
		<Styled.Wrapper>
			<Styled.Title variant={"h6"}>Список администраторов:</Styled.Title>
			<Styled.AdminsListWrapper>
				{adminsList.map((admin) => {
					if (admin.clientId === clientId) return null;
					return (
						<React.Fragment key={admin.clientId}>
							<Admin admin={admin} />
						</React.Fragment>
					);
				})}
			</Styled.AdminsListWrapper>
		</Styled.Wrapper>
	);
};

export default AdminsList;
