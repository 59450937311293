import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { TShowSystemMessage } from "../../../hocs/SystemMessageContextWrapper/utils/types";
import { $api } from "../../../http";
import { localStore } from "../../../localStorage";
import { IAuthResponse, IReject } from "../../../models/api";

export const loginThunk = createAsyncThunk<
	IAuthResponse,
	{
		login: string;
		password: string;
		showSystemMessage: TShowSystemMessage;
	},
	{
		rejectValue: IReject;
	}
>("auth/loginThunk", async (args, { rejectWithValue }) => {
	const { login, password, showSystemMessage } = args;
	try {
		const response = await $api.post<IAuthResponse>("auth/login", {
			login,
			password,
		});
		localStore.setAccessToken(
			`Bearer ${response.data.accessToken.replace(/"/g, "")}`,
		);
		return response.data;
	} catch (err) {
		localStore.removeAccessToken();
		const error: AxiosError<IReject> = err as AxiosError<IReject>; // cast the error for access
		showSystemMessage(
			typeof error.response?.data.message === "string"
				? error.response?.data.message
				: "Не верное имя пользователя либо пароль",
			"error",
		);
		return rejectWithValue(
			error.response?.data || {
				error: true,
				message: "Ошибка сервера",
				statusCode: 500,
			},
		);
	}
});
