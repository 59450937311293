import { AlertColor } from "@mui/material";
import React from "react";

const SystemMessageContext = React.createContext({
	showSystemMessage: (message: string, type: AlertColor | undefined) => {
		// do nothing
	},
});

export default SystemMessageContext;
