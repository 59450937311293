import { actionTypes } from "../store/consts";

type TStore = {
	dispatch: (arg0: { type: typeof actionTypes.resetStore }) => void;
};

export let injectedStore: TStore;
export const injectStore = (_store: TStore) => {
	injectedStore = _store;
};
