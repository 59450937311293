import { createAsyncThunk } from "@reduxjs/toolkit";

import { $api } from "../../../http";
import { localStore } from "../../../localStorage";
import { IReject, IResponse } from "../../../models/api";

export const logout = createAsyncThunk<
	IResponse<undefined>,
	undefined,
	{
		rejectValue: IReject;
	}
>("auth/logout", async (args, { rejectWithValue }) => {
	try {
		const response = await $api.post<IResponse<undefined>>("auth/logout");
		localStore.removeAccessToken();
		return response.data;
	} catch (err) {
		return rejectWithValue({
			error: true,
			message: "Произошла ошибка",
			statusCode: 500,
		});
	}
});
