import { Box, Button, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
	paddingTop: "2em",
	display: "flex",
	flexDirection: "column",
	rowGap: "2.5em",
	width: "100%",
	textAlign: "center",
	backgroundColor: theme.palette.green,
	minHeight: "100%",
	overflowY: "auto",
	"& svg": {
		margin: "0 auto",
	},
	[theme.breakpoints.down("tablet")]: {
		paddingBottom: "3em",
	},
}));

const FormWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	gap: "1.5em",
	margin: "0 auto",
	width: "90em",
	padding: "2em",
}));

const SubmitButton = styled(Button)({
	margin: "0 auto",
});

const CancelButton = styled(Button)(({ theme }) => ({
	margin: "0 auto",
	backgroundColor: theme.palette.middleGrey,
	"&:hover": {
		backgroundColor: theme.palette.darkGrey,
	},
}));

const StyledRegistration = {
	Wrapper,
	FormWrapper,
	SubmitButton,
	CancelButton,
};

export default StyledRegistration;
