import React from "react";

import Styled from "./Footer.style";
import { Address, Contacts, Logout } from "./components";
import { ExtraInfo } from "./components/ExtraInfo";

const Footer: React.FC = () => {
	return (
		<Styled.Wrapper>
			<Styled.Divider />
			<Styled.ContactsWrapper>
				<Address />
				<Contacts />
				<Logout />
			</Styled.ContactsWrapper>
			<ExtraInfo />
		</Styled.Wrapper>
	);
};

export default Footer;
