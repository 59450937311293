import { Box, Link, styled } from "@mui/material";

const Wrapper = styled(Box)({
	display: "flex",
	flexDirection: "column",
	marginTop: "1.5em",
	rowGap: "0.5em",
});

const Reference = styled(Link)(({ theme }) => ({
	color: "black",
	fontSize: "1.13rem",
	textDecoration: "underline",
	[theme.breakpoints.down("tablet")]: {
		fontSize: "1em",
	},
}));

const StyledLogIn = {
	Wrapper,
	Reference,
};

export default StyledLogIn;
