import { CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";

import { SystemMessageContextWrapper } from "./hocs";
import { useRedirect, useRefresh } from "./hooks";
import { theme } from "./mui";
import { AppRouter } from "./router";

const App: React.FC = () => {
	useRedirect();
	useRefresh();

	return (
		<React.Fragment>
			<CssBaseline />
			<SystemMessageContextWrapper>
				<ThemeProvider theme={theme}>
					<AppRouter />
				</ThemeProvider>
			</SystemMessageContextWrapper>
		</React.Fragment>
	);
};

export default App;
