import React from "react";

import Styled from "./NewPasswordConfirm.style";
import { NewPassConfirmForm, NewPassSuccess } from "./components";

const NewPasswordConfirm: React.FC = () => {
	return (
		<Styled.Wrapper>
			<NewPassConfirmForm />
			<NewPassSuccess />
		</Styled.Wrapper>
	);
};

export default NewPasswordConfirm;
