import React from "react";

import { HistoryItem } from "./components";

import { Loader } from "../index";

import { TTransaction } from "../../../models/history";
import Styled from "../../../mui/commonStyles/History.style";

const History: React.FC<TProps> = ({ title, transactions, isLoading }) => {
	return (
		<Styled.Wrapper>
			<Styled.Title>{title}</Styled.Title>
			<Styled.List>
				{[...transactions]
					.sort(
						(a, b) =>
							Date.parse(b.createdAt.toString()) -
							Date.parse(a.createdAt.toString()),
					)
					.map((transaction) => {
						return (
							<React.Fragment key={transaction.id}>
								<HistoryItem transaction={transaction} />
							</React.Fragment>
						);
					})}
			</Styled.List>
			<Loader isLoading={isLoading} />
		</Styled.Wrapper>
	);
};

type TProps = {
	title: string;
	transactions: TTransaction[];
	isLoading: boolean;
};

export default History;
