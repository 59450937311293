import React from "react";

import { History } from "./components";

import { Pagination } from "../../../../../common";

import { useAppSelector } from "../../../../../../hooks";
import { HistoryWrapper } from "../../../../../../mui/commonStyles";
import { getWalletHistoryThunk } from "../../../../../../store/thunks/history";

const WalletHistory: React.FC = () => {
	const { wallet } = useAppSelector((state) => state.accountReducer);
	const { walletHistory } = useAppSelector((state) => state.historyReducer);

	return (
		<HistoryWrapper.Wrapper>
			<History
				title={"История операций"}
				transactions={walletHistory.rows}
				isLoading={false}
			/>
			<Pagination
				account={wallet}
				count={walletHistory.count}
				thunk={getWalletHistoryThunk}
			/>
		</HistoryWrapper.Wrapper>
	);
};

export default WalletHistory;
