import React from "react";

import { MoneyBoxHistory } from "./components";

import { CreditCard, Loader } from "../../../common";

import { ReactComponent as Logo } from "../../../../assets/coinsStack.svg";
import { ReactComponent as PiggyBankIcon } from "../../../../assets/piggyBankBig.svg";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { MainStyles } from "../../../../mui/commonStyles";
import { getBalance } from "../../../../store/thunks";

const MoneyBox: React.FC = () => {
	const { clientId } = useAppSelector((state) => state.authReducer);
	const { moneyBox, isLoading } = useAppSelector(
		(state) => state.accountReducer,
	);
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		if (clientId) {
			dispatch(getBalance());
		}
	}, [dispatch, clientId]);

	return (
		<MainStyles.Wrapper>
			<MainStyles.LogoContainer>
				<Logo />
			</MainStyles.LogoContainer>
			<CreditCard
				title={"Копилка"}
				amount={moneyBox}
				icon={<PiggyBankIcon />}
			/>
			<MoneyBoxHistory />
			<Loader isLoading={isLoading} />
		</MainStyles.Wrapper>
	);
};

export default MoneyBox;
