import { Box, Typography, styled } from "@mui/material";

const Wrapper = styled(Box)({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	rowGap: "1em",
});

const Text = styled(Typography)(({ theme }) => ({
	color: theme.palette.lightGrey,
	margin: "3em 0 2em",
}));

const StyledRestoreSuccess = {
	Wrapper,
	Text,
};

export default StyledRestoreSuccess;
