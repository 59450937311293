import React from "react";

import Styled from "./Invitees.style";
import { Inviter } from "./components";

import { ReactComponent as Logo } from "../../../../../../assets/threeMansLogo.svg";
import { SystemMessageContext } from "../../../../../../hocs";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks";
import { MainStyles } from "../../../../../../mui/commonStyles";
import { getInviteesThunk } from "../../../../../../store/thunks/reference";
import { getPeopleAmountText } from "../../../../../../utils/functions";

const Invitees: React.FC = () => {
	const { invitees } = useAppSelector((state) => state.referenceReducer);
	const { clientId } = useAppSelector((state) => state.authReducer);
	const { showSystemMessage } = React.useContext(SystemMessageContext);
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		!!clientId && dispatch(getInviteesThunk({ clientId, showSystemMessage }));
		// eslint-disable-next-line
	}, [clientId]);

	return (
		<MainStyles.Wrapper>
			<MainStyles.LogoContainer>
				<Logo />
			</MainStyles.LogoContainer>
			<Styled.InfoWrapper>
				<Styled.Title variant={"h6"}>Личная структура</Styled.Title>
				<Styled.Text>
					В этом разделе можно следить за развитием своей личной структуры
				</Styled.Text>
			</Styled.InfoWrapper>
			<Styled.Container>
				<Styled.Total>
					{`Количество приглашённых в первой линии - ${getPeopleAmountText(
						invitees.length,
					)}`}
				</Styled.Total>
				<Styled.List>
					{invitees.map((el) => {
						if (el.id === clientId) return null;
						return (
							<React.Fragment key={el.id}>
								<Inviter
									inviterId={el.id}
									surname={el.client.surname}
									name={el.client.name}
									patronymic={el.client.patronymic}
									level={1}
								/>
							</React.Fragment>
						);
					})}
				</Styled.List>
			</Styled.Container>
		</MainStyles.Wrapper>
	);
};

export default Invitees;
