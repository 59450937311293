import React from "react";

import Styled from "./Reference.style";

const Reference: React.FC<TProps> = ({ name, link }) => {
	return (
		<Styled.Nav>
			<Styled.Reference
				to={link}
				className={({ isActive, isPending }) =>
					isPending ? "pending" : isActive ? "active" : ""
				}
				onClick={() => {
					const navbar = document.getElementById("nav");
					if (navbar) {
						navbar.classList.add("hide");
					}
				}}
			>
				<span>{name}</span>
			</Styled.Reference>
		</Styled.Nav>
	);
};

type TProps = {
	name: string;
	link: string;
};

export default Reference;
