import { Formik } from "formik";
import React from "react";

import { RefillSchema } from "./utils/RefillSchema";

import { FormikItem, Loader } from "../../../../../common";

import { ReactComponent as NumberIcon } from "../../../../../../assets/number.svg";
import { ReactComponent as PiggyBank } from "../../../../../../assets/piggyBank.svg";
import { ReactComponent as ToDoList } from "../../../../../../assets/toDoList.svg";
import { ReactComponent as WalletIcon } from "../../../../../../assets/wallet.svg";
import { SystemMessageContext } from "../../../../../../hocs";
import WithClientName from "../../../../../../hocs/WithClientName/WithClientName";
import {
	useAppDispatch,
	useAppSelector,
	useDebounce,
} from "../../../../../../hooks";
import { FormStyles, MainStyles } from "../../../../../../mui/commonStyles";
import { personalDataSlice } from "../../../../../../store/slices";
import { refillThunk } from "../../../../../../store/thunks/admin/refill.thunk";
import { getPersonalDataThunk } from "../../../../../../store/thunks/personalData";

const RefillForm: React.FC = () => {
	const [id, setId] = React.useState(0);
	const { isLoading } = useAppSelector((state) => state.adminReducer);
	const { showSystemMessage } = React.useContext(SystemMessageContext);
	const { resetPersonInfo } = personalDataSlice.actions;
	const dispatch = useAppDispatch();
	const debounced = useDebounce(id);

	React.useEffect(() => {
		if (debounced && Number.isInteger(debounced)) {
			dispatch(
				getPersonalDataThunk({ clientId: debounced, showSystemMessage }),
			);
		} else {
			dispatch(resetPersonInfo());
		}
		// eslint-disable-next-line
	}, [debounced]);

	return (
		<MainStyles.Wrapper>
			<Formik
				initialValues={{
					clientId: 0,
					wallet: 0,
					moneyBox: 0,
					desc: "",
				}}
				validationSchema={RefillSchema}
				onSubmit={async (values, actions) => {
					const res = await dispatch(
						refillThunk({ ...values, showSystemMessage }),
					);
					if (res.meta.requestStatus === "fulfilled") {
						actions.resetForm();
						setId(0);
					}
				}}
			>
				{({ values, handleChange, handleSubmit, errors, touched }) => (
					<form onSubmit={handleSubmit}>
						<FormStyles.Wrapper>
							<FormStyles.Title>Пополнение счёта</FormStyles.Title>
							<WithClientName>
								<FormikItem
									id={"clientId"}
									label="Номер клиента&#42;"
									type={"number"}
									values={values}
									errors={errors}
									onChange={(event) => {
										setId(Number(event.target.value));
										handleChange(event);
									}}
									touched={touched}
									Icon={NumberIcon}
								/>
							</WithClientName>
							<FormikItem
								id={"wallet"}
								label="Кошелёк"
								type={"number"}
								values={values}
								errors={errors}
								onChange={handleChange}
								touched={touched}
								Icon={WalletIcon}
							/>
							<FormikItem
								id={"moneyBox"}
								label="Копилка"
								type={"number"}
								values={values}
								errors={errors}
								onChange={handleChange}
								touched={touched}
								Icon={PiggyBank}
							/>
							<FormikItem
								id={"desc"}
								label="Описание"
								type={"text"}
								values={values}
								errors={errors}
								onChange={handleChange}
								touched={touched}
								Icon={ToDoList}
							/>
							<FormStyles.SubmitButton variant="contained" type={"submit"}>
								Пополнить
							</FormStyles.SubmitButton>
						</FormStyles.Wrapper>
					</form>
				)}
			</Formik>
			<Loader isLoading={isLoading} />
		</MainStyles.Wrapper>
	);
};

export default RefillForm;
