import { Box, Link, Typography, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	[theme.breakpoints.down("tablet")]: {
		marginBottom: "1em",
	},
}));

const Title = styled(Typography)(({ theme }) => ({
	marginBottom: "1.3em",
	[theme.breakpoints.down("tablet")]: {
		marginBottom: "0.3em",
	},
}));

const SimpleLink = styled(Link)(({ theme }) => ({
	fontSize: "1.15rem",
	textDecoration: "none",
	color: theme.palette.lightGrey,
	[theme.breakpoints.down("tablet")]: {
		fontSize: "1rem",
	},
}));

const StyledAddress = {
	Wrapper,
	Title,
	SimpleLink,
};

export default StyledAddress;
