import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { TShowSystemMessage } from "../../../hocs/SystemMessageContextWrapper/utils/types";
import { $api } from "../../../http";
import { IBank, IReject, IResponse } from "../../../models/api";

export const changeIncomeThunk = createAsyncThunk<
	IResponse<IBank<"income">>,
	{
		income: number;
		showSystemMessage: TShowSystemMessage;
	},
	{
		rejectValue: IReject;
	}
>("bank/changeIncomeThunk", async (args, { rejectWithValue }) => {
	const { income, showSystemMessage } = args;
	try {
		const response = await $api.post<IResponse<IBank<"income">>>(
			"bank/income",
			{
				income,
			},
		);
		return response.data;
	} catch (err) {
		const error: AxiosError<IReject> = err as AxiosError<IReject>; // cast the error for access
		showSystemMessage(
			typeof error.response?.data.message === "string"
				? error.response?.data.message
				: "Ошибка выполнения операции",
			"error",
		);
		return rejectWithValue(
			error.response?.data || {
				error: true,
				message: "Ошибка выполнения операции",
				statusCode: 500,
			},
		);
	}
});
