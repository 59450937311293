import { Box, TextField, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	width: "100%",
	borderBottom: `1px solid ${theme.palette.darkGreen}`,
}));

const IconWrapper = styled(Box)({
	width: "3.9em",
});

const FormInput = styled(TextField)(({ theme }) => ({
	borderLeft: `1px solid ${theme.palette.darkGreen}`,
	"& label": {
		fontSize: "1.2em",
		"&.Mui-focused": {
			color: theme.palette.darkGreen,
		},
	},
	" input": {
		width: "16em",
	},
	"& fieldset": {
		border: "none",
	},
	[theme.breakpoints.down("tablet")]: {
		" input": {
			width: "13em",
		},
	},
}));

const StyledFormItem = {
	Wrapper,
	IconWrapper,
	FormInput,
};

export default StyledFormItem;
