import { Box, Typography, styled } from "@mui/material";

const Content = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	width: "50em",
	backgroundColor: theme.palette.lightGrey,
	padding: "2.7em 1.875em 1.875em",
	borderRadius: "30px",
	margin: "0 auto",
	"& div:nth-last-of-type(1)": {
		border: "none",
	},
	[theme.breakpoints.down("tablet")]: {
		width: "18.75em",
		padding: "0.625em",
		borderRadius: "20px",
	},
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: "1.375rem",
	color: theme.palette.darkGreen,
	marginBottom: "1em",
	[theme.breakpoints.down("tablet")]: {
		marginBottom: "0.5em",
	},
}));

const List = styled(Box)({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	// rowGap: "0.65em",
});

const StyledStatistics = {
	Content,
	Title,
	List,
};

export default StyledStatistics;
