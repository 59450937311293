import * as Yup from "yup";

export const RefillAdminBalanceSchema = Yup.object().shape({
	adminId: Yup.number()
		.positive("укажите положительное число")
		.integer("должно быть целым числом")
		.lessThan(4294967295, "слишком большое число") // INT
		.required("обязательное поле"),
	amount: Yup.number()
		.integer("должна быть целым числом")
		.lessThan(2147483647, "слишком большое число") // INT
		.moreThan(-2147483648, "слишком маленькое число"), // INT
	desc: Yup.string()
		.min(2, "не менее 2 символов")
		.max(50, "не более 50 символов"),
});
