import { createSlice } from "@reduxjs/toolkit";

import {
	getAdminAccountHistoryThunk,
	getWalletHistoryThunk,
} from "../thunks/history";
import { getMoneyBoxHistoryThunk } from "../thunks/history/getMoneyBoxHistory.thunk";

import {
	IAdminAccountHistory,
	IMoneyBoxHistory,
	IWalletHistory,
} from "../../models/api/history";

type TState = {
	adminAccountHistory: IAdminAccountHistory;
	moneyBoxHistory: IMoneyBoxHistory;
	walletHistory: IWalletHistory;
	isLoading: boolean;
};

const initialState: TState = {
	adminAccountHistory: { count: 0, rows: [] },
	moneyBoxHistory: { count: 0, rows: [] },
	walletHistory: { count: 0, rows: [] },
	isLoading: false,
};

export const historySlice = createSlice({
	name: "historySlice",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getWalletHistoryThunk.fulfilled, (state, action) => {
			state.walletHistory = action.payload.data;
			state.isLoading = false;
		});
		builder.addCase(getWalletHistoryThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getWalletHistoryThunk.rejected, (state) => {
			state.walletHistory = initialState.walletHistory;
			state.isLoading = false;
		});

		builder.addCase(getMoneyBoxHistoryThunk.fulfilled, (state, action) => {
			state.moneyBoxHistory = action.payload.data;
			state.isLoading = false;
		});
		builder.addCase(getMoneyBoxHistoryThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getMoneyBoxHistoryThunk.rejected, (state) => {
			state.moneyBoxHistory = initialState.moneyBoxHistory;
			state.isLoading = false;
		});

		builder.addCase(getAdminAccountHistoryThunk.fulfilled, (state, action) => {
			state.adminAccountHistory = action.payload.data;
			state.isLoading = false;
		});
		builder.addCase(getAdminAccountHistoryThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getAdminAccountHistoryThunk.rejected, (state) => {
			state.adminAccountHistory = initialState.adminAccountHistory;
			state.isLoading = false;
		});
	},
});
