import { Box, Typography, styled } from "@mui/material";

const Container = styled(Box)(({ theme }) => ({
	width: "77.5em",
	backgroundColor: theme.palette.lightGrey,
	borderRadius: "30px",
	padding: "2.5em",
	"& p": {
		margin: 0,
	},
	[theme.breakpoints.down("tablet")]: {
		width: "18.75em",
		padding: "0.9em",
	},
}));

const TitleContainer = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
}));

const Title = styled(Typography)(({ theme }) => ({
	[theme.breakpoints.down("tablet")]: {
		fontSize: "1.125rem",
	},
}));

const Paragraph = styled(Box)(({ theme }) => ({
	margin: "1em 0",
}));

const StyledDescription = {
	Container,
	TitleContainer,
	Title,
	Paragraph,
};

export default StyledDescription;
