import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";

import { TShowSystemMessage } from "../../../hocs/SystemMessageContextWrapper/utils/types";
import { API_URL } from "../../../http";
import { localStore } from "../../../localStorage";
import { IAuthResponse, IReject } from "../../../models/api";

export const register = createAsyncThunk<
	IAuthResponse,
	{
		name: string;
		surname: string;
		patronymic: string;
		reference: number;
		email: string;
		phone: string;
		login: string;
		password: string;
		showSystemMessage: TShowSystemMessage;
	},
	{
		rejectValue: IReject;
	}
>("auth/register", async (args, { rejectWithValue }) => {
	const {
		name,
		surname,
		patronymic,
		reference,
		email,
		phone,
		login,
		password,
		showSystemMessage,
	} = args;
	try {
		const response = await axios.post<IAuthResponse>(
			`${API_URL}/auth/registration`,
			{
				name,
				surname,
				patronymic,
				reference,
				email: email || undefined,
				phone: phone || undefined,
				login,
				password,
			},
		);
		localStore.setAccessToken(
			`Bearer ${response.data.accessToken.replace(/"/g, "")}`,
		);
		showSystemMessage("Пользователь успешно зарегистрирован", "success");
		return response.data;
	} catch (err) {
		const error: AxiosError<IReject> = err as AxiosError<IReject>; // cast the error for access
		showSystemMessage(
			typeof error.response?.data.message === "string"
				? error.response?.data.message
				: "Произошла ошибка",
			"error",
		);
		return rejectWithValue(
			error.response?.data || {
				error: true,
				message: "Произошла ошибка",
				statusCode: 500,
			},
		);
	}
});
