import {
	Confirmation,
	Main,
	NewPasswordConfirm,
	Registration,
	Restore,
	Welcome,
} from "../components";
import {
	AdminAccount,
	Admins,
	Bank,
	Description,
	Income,
	Invitees,
	MoneyBox,
	Percent,
	PersonalData,
	Profile,
	RefillAdminAccount,
	Statistics,
	Wallet,
} from "../components/Main";
import { Paths } from "../paths";

export const routes = [
	{
		path: Paths.welcome,
		element: <Welcome />,
	},
	{
		path: Paths.main,
		element: <Main />,
		children: [
			{ path: Paths.profile, element: <Profile /> },
			{ path: Paths.invitees, element: <Invitees /> },
			{ path: Paths.bank, element: <Bank /> },
			{ path: Paths.income, element: <Income /> },
			{ path: Paths.wallet, element: <Wallet /> },
			{ path: Paths.description, element: <Description /> },
			{ path: Paths.moneyBox, element: <MoneyBox /> },
			{
				path: Paths.adminAccount,
				element: <AdminAccount />,
			},
			{
				path: Paths.personalData,
				element: <PersonalData />,
			},
			{
				path: Paths.rights,
				element: <Admins />,
			},
			{
				path: Paths.refillAdminBalance,
				element: <RefillAdminAccount />,
			},
			{
				path: Paths.percent,
				element: <Percent />,
			},
			{
				path: Paths.statistics,
				element: <Statistics />,
			},
		],
	},
	{
		path: Paths.registration,
		element: <Registration />,
	},
	{
		path: Paths.emailConfirm,
		element: <Confirmation />,
	},
	{
		path: Paths.restorePassRequest,
		element: <Restore />,
	},
	{
		path: Paths.newPasswordConfirm,
		element: <NewPasswordConfirm />,
	},
];
