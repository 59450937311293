import { Button } from "@mui/material";
import React from "react";

import Styled from "./Profile.style";
import { ChangePassword, ProfileInfo, ProfileInfoForm } from "./components";

import { Loader } from "../../../../../common";

import { ReactComponent as Icon } from "../../../../../../assets/man_in_tie.svg";
import { SystemMessageContext } from "../../../../../../hocs";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks";
import { MainStyles } from "../../../../../../mui/commonStyles";
import { credentialSlice } from "../../../../../../store/slices";
import { getProfileThunk } from "../../../../../../store/thunks/profile";

const Profile: React.FC = () => {
	const [isEditDataMode, setEditDataMode] = React.useState(false);
	const [isEditPasswordMode, setIsEditPasswordMode] = React.useState(false);
	const { isLoading } = useAppSelector((state) => state.profileReducer);
	const { wasPasswordChanged } = useAppSelector(
		(state) => state.credentialReducer,
	);
	const { setPasswordWasNotChanged } = credentialSlice.actions;
	const { showSystemMessage } = React.useContext(SystemMessageContext);
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		wasPasswordChanged && setIsEditPasswordMode(false);
	}, [wasPasswordChanged]);

	React.useEffect(() => {
		dispatch(getProfileThunk({ showSystemMessage }));
		// eslint-disable-next-line
	}, []);

	const showProfileData = !isEditDataMode && !isEditPasswordMode;

	return (
		<MainStyles.Wrapper>
			<MainStyles.LogoContainer>
				<Icon />
			</MainStyles.LogoContainer>
			{!!isEditDataMode && (
				<ProfileInfoForm setEditDataMode={setEditDataMode} />
			)}
			{!!isEditPasswordMode && (
				<ChangePassword setIsEditPasswordMode={setIsEditPasswordMode} />
			)}
			{showProfileData && (
				<React.Fragment>
					<ProfileInfo />
					<Styled.ButtonsWrapper>
						<Button
							variant={"contained"}
							onClick={() => {
								setIsEditPasswordMode(true);
								dispatch(setPasswordWasNotChanged());
							}}
						>
							Изменить пароль
						</Button>
						<Styled.ChangeDataButton
							variant={"contained"}
							onClick={() => {
								setEditDataMode(true);
							}}
						>
							Изменить данные
						</Styled.ChangeDataButton>
					</Styled.ButtonsWrapper>
				</React.Fragment>
			)}
			<Loader isLoading={isLoading} />
		</MainStyles.Wrapper>
	);
};

export default Profile;
