import React from "react";

import Styled from "./Description.style";

import { ReactComponent as Logo } from "../../../../assets/listPen.svg";
import { MainStyles } from "../../../../mui/commonStyles";

const Description: React.FC = () => {
	return (
		<MainStyles.Wrapper>
			<MainStyles.LogoContainer>
				<Logo />
			</MainStyles.LogoContainer>
			<Styled.Container>
				<Styled.TitleContainer>
					<Styled.Title variant={"h5"}>Описание программы:</Styled.Title>
				</Styled.TitleContainer>
				<Styled.Paragraph>
					<p>Покупая – зарабатывай 💰</p>
				</Styled.Paragraph>
				<Styled.Paragraph>
					<p>«Зеленое золото» - программа лояльности для покупателей.</p>
				</Styled.Paragraph>
				<Styled.Paragraph>
					<p>
						У каждого участника программы имеются два счета: «Копилка» и
						«Кошелек». Движение средств начинается с момента покупки. На счет
						«Копилка» сразу попадает кэшбэк в размере от 5% до 20% от стоимости
						приобретенного. Пополнение производится начислением бонов –
						финансово-маркетинговой учетной единицы. Один бон равен двум рублям.
						Все боны суммируются. На накопленную сумму бонов в «Копилке»
						начисляются еженедельные проценты.
					</p>
				</Styled.Paragraph>
				<Styled.Paragraph>
					<p>Условия получения процентов:</p>
					<p>
						Если сумма бонов в "Копилке" менее 1000, начисляется 0.12 % в
						неделю, это 0.5 % в месяц.
					</p>
					<p>
						Если сумма бонов в "Копилке" 1000 и более, начисляется 0.24 % в
						неделю, это 1 % в месяц.
					</p>
					<p>
						Этот доход попадает на другой счет - «Кошелек», с которого можно
						делать новые покупки.
					</p>
				</Styled.Paragraph>
				<Styled.Paragraph>
					<p>
						Программа предусматривает систему реферальных выплат. Реферальное
						вознаграждение начисляется с 10 уровней. С каждого уровня партнеры
						получают 0.6% от кэшбэка.
					</p>
					<p>Условия получения реферального вознаграждения:</p>
				</Styled.Paragraph>
				<Styled.Paragraph>
					<p>Реферальное вознаграждение начисляется с 1 по 5 уровень, если:</p>
					<p>1. У самого партнера в "Копилке" 1000 бон.</p>
					<p>
						2. В первой линии зарегистрированы 5 партнеров, у которых в
						"Копилке" есть по 1000 бон.
					</p>
					<p>
						Пример: если в первой линии подписан один человек, у которого в
						"Копилке" 1000 бон, реферальное вознаграждение начисляется с первого
						уровня.{" "}
					</p>
					<p>
						Если в первой линии подписаны два человека и у них в «Копилке» по
						1000 бон, то реферальное вознаграждение начисляется с двух уровней.
					</p>
					<p>И так далее по пятый уровень.</p>
				</Styled.Paragraph>
				<Styled.Paragraph>
					<p>Реферальное вознаграждение начисляется с 6 по 10 уровень, если:</p>
					<p>1. У самого партнера в "Копилке" есть 10000 бон.</p>
					<p>
						2. В первой линии зарегистрированы 10 человек, у которых в "Копилке"
						по 1000 бон.
					</p>
					<p>
						Пример: если в первой линии зарегистрированы 6 человек и у них в
						«Копилке» по 1000 бон, то реферальное вознаграждение начисляется с
						шести уровней. Если в первой линии зарегистрированы 7 человек и у
						них в «Копилке» по 1000 бон, то реферальное вознаграждение
						начисляется с семи уровней. И так далее по 10 уровень.
					</p>
					<p>Реферальное вознаграждение зачисляется в «Кошелек».</p>
				</Styled.Paragraph>
				<Styled.Paragraph>
					<p>
						Увидеть оба счета и начать работать в личном кабинете можно после
						прохождения регистрации на сайте купи-копи.рф.
					</p>
				</Styled.Paragraph>
				<Styled.Paragraph>
					<p>
						Партнеры GMT найдут много знакомых опций, поэтому освоятся быстро.
						Новички тоже легко сориентируются, поскольку все наглядно и понятно.
					</p>
					<p>
						Самый главный раздел: «Основные операции». Здесь находятся два ваших
						основных счёта «Кошелек» и «Копилка», оба с вашим идентификационным
						номером.
					</p>
				</Styled.Paragraph>
				<Styled.Paragraph>
					<p>
						«Копилка» – это ваши несгораемые накопления, которые увеличиваются с
						каждой покупкой. В «Копилку» вы будете получать от 5 до 20 % от
						стоимости каждого приобретенного товара.
					</p>
				</Styled.Paragraph>
				<Styled.Paragraph>
					<p>В «Кошелек» поступают:</p>
					<p>
						- проценты , которые начисляются на сумму бонов, отраженную в
						«Копилке».
					</p>
					<p>- переводы с «Кошелька» на «Кошелек».</p>
					<p>- реферальное вознаграждение.</p>
				</Styled.Paragraph>
				<Styled.Paragraph>
					<p>
						Здесь же в личном кабинете видна история переводов от
						администратора, переводов средств партнерами из «Кошелька» в
						«Кошелек», начисление реферального вознаграждения, еженедельного
						бонуса. Все операции можно увидеть в режиме онлайн.
					</p>
				</Styled.Paragraph>
			</Styled.Container>
		</MainStyles.Wrapper>
	);
};

export default Description;
