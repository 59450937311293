import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineTwoToneIcon from "@mui/icons-material/HelpOutlineTwoTone";
import { Box, styled } from "@mui/material";

const Wrapper = styled(Box)({
	display: "flex",
	justifyContent: "start",
});

const Arrow = styled(ExpandMoreIcon)({
	cursor: "pointer",
	transform: "rotate(-90deg)",
	transition: "0.5s",
	"&:hover": {
		transform: "none",
	},
});

const Details = styled(HelpOutlineTwoToneIcon)({
	cursor: "pointer",
	position: "absolute",
	marginLeft: "0.2em",
});

const StyledPercent = {
	Arrow,
	Details,
	Wrapper,
};

export default StyledPercent;
