import React from "react";

import Styled from "./Statistics.style";
import { ListItem } from "./components";

import { Loader } from "../../../common";

import { ReactComponent as CalculatorIcon } from "../../../../assets/calculatorCoins.svg";
import { ReactComponent as Logo } from "../../../../assets/chartLogo.svg";
import { ReactComponent as PiggyBankIcon } from "../../../../assets/piggyBank.svg";
import { ReactComponent as SacIcon } from "../../../../assets/sac.svg";
import { ReactComponent as WalletIcon } from "../../../../assets/wallet1.svg";
import { ReactComponent as WalletArrowIcon } from "../../../../assets/walletArrow.svg";
import { SystemMessageContext } from "../../../../hocs";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { MainStyles } from "../../../../mui/commonStyles";
import { getTotalThunk } from "../../../../store/thunks/account/getTotal.thunk";

const Statistics: React.FC = () => {
	const {
		totalWallet,
		totalMoneyBox,
		totalAdminAccount,
		totalInvestments,
		isLoading,
	} = useAppSelector((state) => state.accountReducer);
	const dispatch = useAppDispatch();
	const { showSystemMessage } = React.useContext(SystemMessageContext);

	React.useEffect(() => {
		dispatch(getTotalThunk({ showSystemMessage }));
		// eslint-disable-next-line
	}, [dispatch]);

	return (
		<MainStyles.Wrapper>
			<MainStyles.LogoContainer>
				<Logo />
			</MainStyles.LogoContainer>
			<Styled.Content>
				<Styled.Title>Статистика</Styled.Title>
				<Styled.List>
					<ListItem
						icon={<WalletArrowIcon />}
						text={"Общее количество бон, вложенных в приложение"}
						amount={totalInvestments.toFixed(2)}
					/>
					<ListItem
						icon={<WalletIcon />}
						text={"Общее количество бон в кошельках клиентов"}
						amount={totalWallet.toFixed(2)}
					/>
					<ListItem
						icon={<PiggyBankIcon />}
						text={"Общее количество бон в копилках клиентов"}
						amount={totalMoneyBox.toFixed(2)}
					/>
					<ListItem
						icon={<SacIcon />}
						text={"Общее количество бон на счетах администраторов"}
						amount={totalAdminAccount.toFixed(2)}
					/>
					<ListItem
						icon={<CalculatorIcon />}
						text={"Всего на счетах клиентов"}
						amount={(totalWallet + totalMoneyBox + totalAdminAccount).toFixed(
							2,
						)}
					/>
				</Styled.List>
			</Styled.Content>
			<Loader isLoading={isLoading} />
		</MainStyles.Wrapper>
	);
};

export default Statistics;
