import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { TShowSystemMessage } from "../../../hocs/SystemMessageContextWrapper/utils/types";
import { $api } from "../../../http";
import { IReject, IResponse } from "../../../models/api";
import { IMoneyBoxHistory } from "../../../models/api/history";

export const getMoneyBoxHistoryThunk = createAsyncThunk<
	TResponse,
	{
		page: number;
		limit: number;
		showSystemMessage: TShowSystemMessage;
	},
	{
		rejectValue: IReject;
	}
>("history/getMoneyBoxHistoryThunk", async (args, { rejectWithValue }) => {
	const { page, limit, showSystemMessage } = args;
	try {
		const response = await $api.get<TResponse>("history/money.box", {
			params: {
				page,
				limit,
			},
		});
		return response.data;
	} catch (err) {
		const error: AxiosError<IReject> = err as AxiosError<IReject>; // cast the error for access
		showSystemMessage(
			typeof error.response?.data.message === "string"
				? error.response?.data.message
				: "Ошибка выполнения операции",
			"error",
		);
		return rejectWithValue(
			error.response?.data || {
				error: true,
				message: "Ошибка выполнения операции",
				statusCode: 500,
			},
		);
	}
});

type TResponse = IResponse<IMoneyBoxHistory>;
