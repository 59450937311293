import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { TShowSystemMessage } from "../../../hocs/SystemMessageContextWrapper/utils/types";
import { $api } from "../../../http";
import { IPercent, IReject, IResponse } from "../../../models/api";

export const setPercentsThunk = createAsyncThunk<
	IResponse<IPercent>,
	{
		cashback: number;
		income: number;
		marketing: number;
		weekly: number;
		showSystemMessage: TShowSystemMessage;
	},
	{
		rejectValue: IReject;
	}
>("percent/setPercentsThunk", async (args, { rejectWithValue }) => {
	const { cashback, income, marketing, weekly, showSystemMessage } = args;
	try {
		const response = await $api.put<IResponse<IPercent>>("percent", {
			cashback,
			income,
			marketing,
			weekly,
		});
		showSystemMessage("Данные успешно обновлены", "success");
		return response.data;
	} catch (err) {
		const error: AxiosError<IReject> = err as AxiosError<IReject>; // cast the error for access
		showSystemMessage(
			typeof error.response?.data.message === "string"
				? error.response?.data.message
				: "Ошибка выполнения операции",
			"error",
		);
		return rejectWithValue(
			error.response?.data || {
				error: true,
				message: "Ошибка выполнения операции",
				statusCode: 500,
			},
		);
	}
});
