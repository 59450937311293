import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { TShowSystemMessage } from "../../../hocs/SystemMessageContextWrapper/utils/types";
import { $api } from "../../../http";
import { IAdminAccount, IReject } from "../../../models/api";

export const refillAdminAccountThunk = createAsyncThunk<
	IAdminAccount,
	{
		adminId: number;
		amount: number;
		desc: string | null;
		showSystemMessage: TShowSystemMessage;
	},
	{
		rejectValue: IReject;
	}
>("bankHistory/refillAdminAccountThunk", async (args, { rejectWithValue }) => {
	const { adminId, amount, desc, showSystemMessage } = args;
	try {
		const response = await $api.post<IAdminAccount>("bank-history", {
			adminId,
			amount,
			desc,
		});
		showSystemMessage("Операция прошла успешно", "success");
		return response.data;
	} catch (err) {
		const error: AxiosError<IReject> = err as AxiosError<IReject>; // cast the error for access
		showSystemMessage(
			typeof error.response?.data.message === "string"
				? error.response?.data.message
				: "Ошибка выполнения операции",
			"error",
		);
		return rejectWithValue(
			error.response?.data || {
				error: true,
				message: "Ошибка выполнения операции",
				statusCode: 500,
			},
		);
	}
});
