import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { API_URL } from "../../../http";
import { localStore } from "../../../localStorage";
import { IAuthResponse, IReject } from "../../../models/api";

export const refresh = createAsyncThunk<
	IAuthResponse,
	undefined,
	{
		rejectValue: IReject;
	}
>("auth/refresh", async (args, thunkAPI) => {
	try {
		const response = await axios.get<IAuthResponse>(
			`${API_URL}/token/refresh`,
			{
				withCredentials: true,
			},
		);
		localStore.setAccessToken(
			`Bearer ${response.data.accessToken.replace(/"/g, "")}`,
		);
		return response.data;
	} catch (err) {
		localStore.removeAccessToken();
		return thunkAPI.rejectWithValue({
			error: true,
			message: "Пользователь не авторизован",
			statusCode: 500,
		});
	}
});
