import { Box, Typography, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	rowGap: "2.5em",
	height: "100vh",
	backgroundColor: theme.palette.green,
}));

const Window = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	gap: "1em",
	margin: "0 auto 1em",
	width: "22.5em",
	backgroundColor: theme.palette.lightGrey,
	padding: "2.4em 2em 2em",
	borderRadius: "30px",
	[theme.breakpoints.down("tablet")]: {
		gap: "1em",
		width: "18.75em",
		padding: "1.2em 0.625em 1.25em",
		borderRadius: "20px",
	},
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: "1.43rem",
	textAlign: "center",
	[theme.breakpoints.down("tablet")]: {
		fontSize: "1.125rem",
	},
}));

export const ConfirmStyles = {
	Wrapper,
	Window,
	Title,
};
