import React from "react";

import { History } from "./components";

import { Pagination } from "../../../../../common";

import { useAppSelector } from "../../../../../../hooks";
import { HistoryWrapper } from "../../../../../../mui/commonStyles";
import { getAdminAccountHistoryThunk } from "../../../../../../store/thunks/history";

const AdminHistory: React.FC = () => {
	const { balance } = useAppSelector((state) => state.adminReducer);
	const { adminAccountHistory } = useAppSelector(
		(state) => state.historyReducer,
	);

	return (
		<HistoryWrapper.Wrapper>
			<History
				title={"История операций"}
				transactions={adminAccountHistory.rows}
				isLoading={false}
			/>
			<Pagination
				account={balance}
				count={adminAccountHistory.count}
				thunk={getAdminAccountHistoryThunk}
			/>
		</HistoryWrapper.Wrapper>
	);
};

export default AdminHistory;
