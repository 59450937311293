import React from "react";

import Styled from "./ClientName.style";

import { useAppSelector } from "../../../../hooks";

const ClientName: React.FC = () => {
	const { name, surname, patronymic } = useAppSelector(
		(state) => state.personalDataReducer,
	);

	return <Styled.Name>{`${surname} ${name} ${patronymic}`}</Styled.Name>;
};

export default ClientName;
