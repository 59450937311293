import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios/index";

import { TShowSystemMessage } from "../../../hocs/SystemMessageContextWrapper/utils/types";
import { $api } from "../../../http";
import { IReject, IResponse } from "../../../models/api";

export const savePersonalDataThunk = createAsyncThunk<
	IResponse<undefined>,
	{
		clientId: number;
		name: string;
		surname: string;
		patronymic: string;
		phone: string;
		showSystemMessage: TShowSystemMessage;
	},
	{
		rejectValue: IReject;
	}
>("personalData/savePersonalDataThunk", async (args, { rejectWithValue }) => {
	const { clientId, name, surname, patronymic, phone, showSystemMessage } =
		args;
	try {
		const response = await $api.put<IResponse<undefined>>(
			"client/personal.data",
			{
				clientId,
				name,
				surname,
				patronymic,
				phone,
			},
		);
		showSystemMessage("Личные данные сохранены успешно", "success");
		return response.data;
	} catch (err) {
		const error: AxiosError<IReject> = err as AxiosError<IReject>; // cast the error for access
		showSystemMessage(
			typeof error.response?.data.message === "string"
				? error.response?.data.message
				: "Ошибка выполнения операции",
			"error",
		);
		return rejectWithValue(
			error.response?.data || {
				error: true,
				message: "Ошибка выполнения операции",
				statusCode: 500,
			},
		);
	}
});
