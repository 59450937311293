import React from "react";
import { useLocation } from "react-router-dom";

import { HeaderMenuItem } from "../HeaderMenuItem";
import { Reference } from "../Reference";

import { useAppSelector } from "../../../../hooks";
import { Paths } from "../../../../paths";
import { Roles, urlItem } from "../../../../utils/consts";

const OwnerLinks: React.FC = () => {
	const { clientRoles } = useAppSelector((state) => state.authReducer);
	const isOwner = clientRoles.some((role) => role === Roles.owner);
	const { pathname } = useLocation();

	if (!isOwner) return null;

	return (
		<HeaderMenuItem
			name={"Владелец"}
			isActive={pathname.includes(`/${urlItem.main}/${urlItem.owner}/`)}
		>
			<Reference name={"Банк"} link={Paths.bank} />
			<Reference name={"Доход"} link={Paths.income} />
			<Reference name={"Администраторы"} link={Paths.rights} />
			<Reference
				name={"Начисление администратору"}
				link={Paths.refillAdminBalance}
			/>
			<Reference name={"Проценты"} link={Paths.percent} />
			<Reference name={"Статистика"} link={Paths.statistics} />
			<Reference name={"Личные данные"} link={Paths.personalData} />
		</HeaderMenuItem>
	);
};

export default OwnerLinks;
