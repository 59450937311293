import { Box, Link, styled } from "@mui/material";

const Container = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	rowGap: "1em",
}));

const SimpleLink = styled(Link)(({ theme }) => ({
	fontSize: "1.15rem",
	textDecoration: "underline",
	color: theme.palette.lightGrey,
	[theme.breakpoints.down("tablet")]: {
		fontSize: "1rem",
	},
}));

const StyledWallet = {
	Container,
	SimpleLink,
};

export default StyledWallet;
