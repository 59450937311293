import React from "react";

import { ReactComponent as CalendarIcon } from "../../../../../../../../../../../../assets/calendar.svg";
import { ReactComponent as PersonTieIcon } from "../../../../../../../../../../../../assets/manInTieSmall.svg";
import { ReactComponent as PersonIcon } from "../../../../../../../../../../../../assets/person.svg";
import { ReactComponent as ListIcon } from "../../../../../../../../../../../../assets/toDoList.svg";
import { ReactComponent as WalletIcon } from "../../../../../../../../../../../../assets/wallet1.svg";
import { TModalWindowItem } from "../../../../../../../../../../../../models";
import { IWalletHistoryRow } from "../../../../../../../../../../../../models/api/history";
import { getFullDate } from "../../../../../../../../../../../../utils/functions";

export const getModalWindowList = (
	transaction: IWalletHistoryRow,
	amount: number,
): TModalWindowItem[] => [
	{
		label: "Отправитель",
		icon: <PersonTieIcon />,
		text: transaction.sender || "-",
	},
	{
		label: "Получатель",
		icon: <PersonIcon />,
		text: transaction.recipient,
	},
	{
		label: "Сумма",
		icon: <WalletIcon />,
		text: `${amount}`,
	},
	{
		label: "Дата",
		icon: <CalendarIcon />,
		text: getFullDate(new Date(transaction.createdAt)),
	},
	{
		label: "Описание",
		icon: <ListIcon />,
		text: transaction.desc || "-",
	},
];
