import { IAdminAccountHistoryRow } from "../../../../../../../../../../../../models/api";

export const getName = (
	transaction: IAdminAccountHistoryRow,
	clientId: number,
): string => {
	const amISender = transaction.senderId === clientId;
	const client = amISender ? transaction.recipient : transaction.sender;
	const splitedName = client.split(" ");
	return `${splitedName[0]} ${splitedName[1][0]}.${splitedName[2][0]}`;
};
