import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppSelector } from "./useAppSelector";

import { Paths } from "../paths";

export const useRedirect = () => {
	const { isAuthorized } = useAppSelector((state) => state.authReducer);
	const navigate = useNavigate();
	const { pathname } = useLocation();

	React.useEffect(() => {
		if (isAuthorized) {
			if (pathname === Paths.welcome || pathname === Paths.registration) {
				navigate(`${Paths.main}/${Paths.wallet}`);
			}
		} else if (
			pathname !== Paths.registration &&
			pathname !== Paths.emailConfirm &&
			pathname !== Paths.restorePassRequest &&
			pathname !== Paths.newPasswordConfirm
		) {
			pathname !== Paths.welcome && navigate(Paths.welcome);
		}
	}, [isAuthorized, navigate, pathname]);
};
