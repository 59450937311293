import { createSlice } from "@reduxjs/toolkit";

import {
	changeIncomeThunk,
	getBankBalanceThunk,
	getIncomeThunk,
	refillBankBalanceThunk,
} from "../thunks";

type TBank = {
	isLoading: boolean;
	bank: number;
	income: number;
};

const initialState: TBank = {
	isLoading: false,
	bank: 0,
	income: 0,
};

export const bankSlice = createSlice({
	name: "bankSlice",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getBankBalanceThunk.fulfilled, (state, action): void => {
			state.isLoading = false;
			state.bank = action.payload.data.value;
		});
		builder.addCase(getBankBalanceThunk.pending, (state): void => {
			state.isLoading = true;
		});
		builder.addCase(getBankBalanceThunk.rejected, (state): void => {
			state.isLoading = false;
			state.bank = initialState.bank;
		});

		builder.addCase(refillBankBalanceThunk.fulfilled, (state, action): void => {
			state.isLoading = false;
			state.bank = action.payload.data.value;
		});
		builder.addCase(refillBankBalanceThunk.pending, (state): void => {
			state.isLoading = true;
		});
		builder.addCase(refillBankBalanceThunk.rejected, (state): void => {
			state.isLoading = false;
			state.bank = initialState.bank;
		});

		builder.addCase(getIncomeThunk.fulfilled, (state, action): void => {
			state.isLoading = false;
			state.income = action.payload.data.value;
		});
		builder.addCase(getIncomeThunk.pending, (state): void => {
			state.isLoading = true;
		});
		builder.addCase(getIncomeThunk.rejected, (state): void => {
			state.isLoading = false;
			state.income = initialState.income;
		});

		builder.addCase(changeIncomeThunk.fulfilled, (state, action): void => {
			state.isLoading = false;
			state.income = action.payload.data.value;
		});
		builder.addCase(changeIncomeThunk.pending, (state): void => {
			state.isLoading = true;
		});
		builder.addCase(changeIncomeThunk.rejected, (state): void => {
			state.isLoading = false;
			state.income = initialState.income;
		});
	},
});
