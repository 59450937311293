import React from "react";

import { AddAdmin, AdminsList } from "./components";

import { Loader } from "../../../common";

import { ReactComponent as Logo } from "../../../../assets/threePersons.svg";
import { useAppSelector } from "../../../../hooks";
import { MainStyles } from "../../../../mui/commonStyles";

const Admins: React.FC = () => {
	const { isLoading } = useAppSelector((state) => state.roleReducer);

	return (
		<MainStyles.Wrapper>
			<MainStyles.LogoContainer>
				<Logo />
			</MainStyles.LogoContainer>
			<AddAdmin />
			<AdminsList />
			<Loader isLoading={isLoading} />
		</MainStyles.Wrapper>
	);
};

export default Admins;
