import React from "react";

import Styled from "./ListItem.style";

const ListItem: React.FC<TProps> = ({ icon, label, info }) => {
	return (
		<Styled.ListItem>
			<Styled.Icon>{icon}</Styled.Icon>
			<Styled.Container>
				<Styled.LabelWrapper>
					<Styled.Label>{label}</Styled.Label>
				</Styled.LabelWrapper>
				<Styled.InfoWrapper>
					<Styled.Info>{info}</Styled.Info>
				</Styled.InfoWrapper>
			</Styled.Container>
		</Styled.ListItem>
	);
};

type TProps = {
	icon: React.ReactNode;
	label: string;
	info: string | number;
};

export default ListItem;
