import { createAsyncThunk } from "@reduxjs/toolkit";

import { $api } from "../../../http";
import { IReject } from "../../../models/api";
import { IBalance } from "../../../models/api/account";

export const getBalance = createAsyncThunk<
	IBalance,
	undefined,
	{
		rejectValue: IReject;
	}
>("account/getBalance", async (data, { rejectWithValue }) => {
	try {
		const response = await $api.get<IBalance>("account/balance");
		return response.data;
	} catch (err) {
		return rejectWithValue({
			error: true,
			message: "Произошла ошибка",
			statusCode: 500,
		});
	}
});
