import React from "react";

export const useDebounce = (value: number, delay = 300): number => {
	const [debounced, setDebounced] = React.useState(value);

	React.useEffect(() => {
		const handler = setTimeout(() => setDebounced(value), delay);
		return () => clearTimeout(handler);
	}, [value, delay]);

	return debounced;
};
