import { Formik } from "formik";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Styled from "./RegistrationForm.style";
import { RegistrationSchema, getNumber } from "./utils";

import { FormikItem } from "../../../common";

import { ReactComponent as AtIcon } from "../../../../assets/at.svg";
import { ReactComponent as Badge2Icon } from "../../../../assets/badge2.svg";
import { ReactComponent as Badge3Icon } from "../../../../assets/badge3.svg";
import { ReactComponent as BadgeIcon } from "../../../../assets/badge.svg";
import { ReactComponent as LockIcon } from "../../../../assets/lock.svg";
import { ReactComponent as Logo } from "../../../../assets/logo.svg";
import { ReactComponent as PersonInTieIcon } from "../../../../assets/manInTieSmall.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/phone.svg";
import { ReactComponent as ThreeMansIcon } from "../../../../assets/threeMans.svg";
import { SystemMessageContext, WithClientName } from "../../../../hocs";
import { useAppDispatch, useDebounce } from "../../../../hooks";
import { FormStyles } from "../../../../mui/commonStyles";
import { Paths } from "../../../../paths";
import { personalDataSlice } from "../../../../store/slices";
import { register } from "../../../../store/thunks/auth";
import { getPersonalDataThunk } from "../../../../store/thunks/personalData";

const RegistrationForm: React.FC = () => {
	const [id, setId] = React.useState(0);
	const { showSystemMessage } = React.useContext(SystemMessageContext);
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const reference = getNumber(searchParams.get("ref"));
	const debounced = useDebounce(id);
	const { resetPersonInfo } = personalDataSlice.actions;

	React.useEffect(() => {
		if (debounced && Number.isInteger(debounced)) {
			dispatch(
				getPersonalDataThunk({ clientId: debounced, showSystemMessage }),
			);
		} else {
			dispatch(resetPersonInfo());
		}
		// eslint-disable-next-line
	}, [debounced]);

	React.useEffect(() => {
		if (reference) {
			setId(reference);
		}
		// eslint-disable-next-line
	}, [reference]);

	return (
		<Styled.Wrapper>
			<Logo />
			<Formik
				initialValues={{
					name: "",
					surname: "",
					patronymic: "",
					reference,
					email: "",
					phone: "",
					login: "",
					password: "",
					confirm: "",
				}}
				enableReinitialize
				validationSchema={RegistrationSchema}
				onSubmit={(values) => {
					dispatch(register({ ...values, showSystemMessage }));
				}}
			>
				{({ values, handleChange, handleSubmit, errors, touched }) => (
					<form onSubmit={handleSubmit}>
						<FormStyles.Wrapper>
							<FormStyles.Title>Регистрация</FormStyles.Title>
							<FormikItem
								id={"surname"}
								label="Фамилия&#42;"
								type={"text"}
								values={values}
								errors={errors}
								onChange={handleChange}
								touched={touched}
								Icon={BadgeIcon}
							/>
							<FormikItem
								id={"name"}
								label="Имя&#42;"
								type={"text"}
								values={values}
								errors={errors}
								onChange={handleChange}
								touched={touched}
								Icon={Badge2Icon}
							/>
							<FormikItem
								id={"patronymic"}
								label="Отчество&#42;"
								type={"text"}
								values={values}
								errors={errors}
								onChange={handleChange}
								touched={touched}
								Icon={Badge3Icon}
							/>
							<WithClientName>
								<FormikItem
									id={"reference"}
									label="Реферальная ссылка"
									type={"number"}
									values={values}
									errors={errors}
									onChange={(event) => {
										setId(Number(event.target.value));
										handleChange(event);
									}}
									touched={touched}
									Icon={ThreeMansIcon}
								/>
							</WithClientName>
							<FormikItem
								id={"email"}
								label="Адрес электронной почты"
								type={"text"}
								values={values}
								errors={errors}
								onChange={handleChange}
								touched={touched}
								Icon={AtIcon}
							/>
							<FormikItem
								id={"phone"}
								label="Номер телефона"
								type={"tel"}
								values={values}
								errors={errors}
								onChange={handleChange}
								touched={touched}
								Icon={PhoneIcon}
							/>
							<FormikItem
								id={"login"}
								label="Логин&#42;"
								type={"text"}
								values={values}
								errors={errors}
								onChange={handleChange}
								touched={touched}
								Icon={PersonInTieIcon}
							/>
							<FormikItem
								id={"password"}
								label="Пароль&#42;"
								type={"password"}
								values={values}
								errors={errors}
								onChange={handleChange}
								touched={touched}
								Icon={LockIcon}
							/>
							<FormikItem
								id={"confirm"}
								label="Подтвердите пароль&#42;"
								type={"password"}
								values={values}
								errors={errors}
								onChange={handleChange}
								touched={touched}
								Icon={LockIcon}
							/>
							<FormStyles.ButtonsWrapper>
								<FormStyles.SubmitButton variant="contained" type={"submit"}>
									Зарегистрироваться
								</FormStyles.SubmitButton>
								<FormStyles.CancelButton
									variant="contained"
									onClick={() => {
										navigate(Paths.welcome);
									}}
								>
									Отмена
								</FormStyles.CancelButton>
							</FormStyles.ButtonsWrapper>
						</FormStyles.Wrapper>
					</form>
				)}
			</Formik>
		</Styled.Wrapper>
	);
};

export default RegistrationForm;
