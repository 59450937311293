import * as Yup from "yup";

export const RestoreSchema = Yup.object().shape({
	phone: Yup.string()
		.min(6, "не менее 6 символов")
		.max(16, "не более 16 символов")
		.matches(/^\+\d+$/, "номер в формате +7123456789")
		.test(function (value, ctx) {
			const { login } = this.parent;
			if (!login && !value)
				return ctx.createError({ message: "обязательное поле" });
			return true;
		}),
	login: Yup.string()
		.min(5, "не менее 5 символов")
		.max(20, "не более 20 символов")
		.test(function (value, ctx) {
			const { phone } = this.parent;
			if (!phone && !value)
				return ctx.createError({ message: "обязательное поле" });
			return true;
		}),
});
