import { Box, TextField, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
	position: "relative",
	display: "flex",
	alignItems: "center",
	width: "100%",
	borderBottom: `1px solid ${theme.palette.darkGreen}`,
}));

const IconWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "3.9em",
	[theme.breakpoints.down("tablet")]: {
		width: "2.9em",
		"& svg": {
			transform: "scale(0.9)",
		},
	},
}));

const FormInput = styled(TextField)(({ theme }) => ({
	borderLeft: `1px solid ${theme.palette.darkGreen}`,
	"& label": {
		fontSize: "1.2em",
		"&.Mui-focused": {
			color: theme.palette.darkGreen,
		},
	},
	" input": {
		width: "16em",
		"&.Mui-disabled": {
			WebkitTextFillColor: theme.palette.text.primary,
		},
	},
	"& fieldset": {
		border: "none",
	},
	[theme.breakpoints.down("tablet")]: {
		"& label": {
			fontSize: "1em",
		},
	},
}));

const Error = styled(Box)(({ theme }) => ({
	position: "absolute",
	color: "red",
	fontSize: "0.8em",
	top: "4.5em",
	left: "5.5em",
	[theme.breakpoints.down("tablet")]: {
		top: "4.5em",
		left: "4.3em",
	},
}));

const StyledFormItem = {
	Wrapper,
	IconWrapper,
	FormInput,
	Error,
};

export default StyledFormItem;
