import React from "react";
import { useLocation } from "react-router-dom";

import { HeaderMenuItem } from "../HeaderMenuItem";
import { Reference } from "../Reference";

import { Paths } from "../../../../paths";
import { urlItem } from "../../../../utils/consts";

const MainOperationsLinks: React.FC = () => {
	const { pathname } = useLocation();

	return (
		<HeaderMenuItem
			name={"Основные операции"}
			isActive={pathname.includes(`/${urlItem.main}/${urlItem.operation}/`)}
		>
			<Reference name={"Кошелёк"} link={Paths.wallet} />
			<Reference name={"Копилка"} link={Paths.moneyBox} />
		</HeaderMenuItem>
	);
};

export default MainOperationsLinks;
