import React from "react";

import { ReactComponent as CalendarIcon } from "../../../../../../../assets/calendar.svg";
import { ReactComponent as PersonTieIcon } from "../../../../../../../assets/manInTieSmall.svg";
import { ReactComponent as CoinsIcon } from "../../../../../../../assets/moneyStack.svg";
import { ReactComponent as PersonIcon } from "../../../../../../../assets/person.svg";
import { ReactComponent as PiggyBankIcon } from "../../../../../../../assets/piggyBank.svg";
import { ReactComponent as ListIcon } from "../../../../../../../assets/toDoList.svg";
import { ReactComponent as WalletIcon } from "../../../../../../../assets/wallet1.svg";
import { TModalWindowItem } from "../../../../../../../models";
import { TTransaction } from "../../../../../../../models/history";
import { getFullDate, getFullName } from "../../../../../../../utils/functions";

export const getModalWindowList = (
	transaction: TTransaction,
): TModalWindowItem[] => {
	const list: TModalWindowItem[] = [
		{
			label: "Отправитель",
			icon: <PersonTieIcon />,
			text: getFullName(transaction.sender),
		},
		{
			label: "Получатель",
			icon: <PersonIcon />,
			text: getFullName(transaction.recipient),
		},
	];

	!!transaction.amount &&
		list.push({
			label: "Сумма",
			icon: <CoinsIcon />,
			text: `${transaction.amount}`,
		});

	!!transaction.wallet &&
		list.push({
			label: "В кошелёк",
			icon: <WalletIcon />,
			text: `${transaction.wallet}`,
		});

	!!transaction.moneyBox &&
		list.push({
			label: "В копилку",
			icon: <PiggyBankIcon />,
			text: `${transaction.moneyBox}`,
		});

	list.push(
		{
			label: "Дата",
			icon: <CalendarIcon />,
			text: getFullDate(new Date(transaction.createdAt)),
		},
		{
			label: "Описание",
			icon: <ListIcon />,
			text: transaction.desc || "-",
		},
	);

	return list;
};
