import { createSlice } from "@reduxjs/toolkit";

import { getPercentsThunk, setPercentsThunk } from "../thunks";

type TAccount = {
	isLoading: boolean;
	cashback: number;
	income: number;
	marketing: number;
	weekly: number;
	isChanged: boolean;
};

const initialState: TAccount = {
	isLoading: false,
	cashback: 0,
	income: 0,
	marketing: 0,
	weekly: 0,
	isChanged: false,
};

export const percentSlice = createSlice({
	name: "percentSlice",
	initialState,
	reducers: {
		setChanged: (state) => {
			state.isChanged = true;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getPercentsThunk.fulfilled, (state, action) => {
			state.isLoading = false;
			state.cashback = action.payload.data.cashback || 0;
			state.income = action.payload.data.income || 0;
			state.marketing = action.payload.data.marketing || 0;
			state.weekly = action.payload.data.weekly || 0;
			state.isChanged = false;
		});
		builder.addCase(getPercentsThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getPercentsThunk.rejected, (state) => {
			state.isLoading = false;
			state.cashback = initialState.cashback;
			state.income = initialState.income;
			state.marketing = initialState.marketing;
			state.weekly = initialState.weekly;
		});

		builder.addCase(setPercentsThunk.fulfilled, (state, action) => {
			state.isLoading = false;
			state.cashback = action.payload.data.cashback;
			state.income = action.payload.data.income;
			state.marketing = action.payload.data.marketing;
			state.weekly = action.payload.data.weekly;
			state.isChanged = false;
		});
		builder.addCase(setPercentsThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(setPercentsThunk.rejected, (state) => {
			state.isLoading = false;
			state.cashback = initialState.cashback;
			state.income = initialState.income;
			state.marketing = initialState.marketing;
			state.weekly = initialState.weekly;
		});
	},
});
