import { Box, styled } from "@mui/material";

const Wrapper = styled(Box)({
	display: "flex",
	flexDirection: "column",
});

const StyledRestoreForm = {
	Wrapper,
};

export default StyledRestoreForm;
