import { Box, Typography, styled } from "@mui/material";

const ListItem = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "flex-end",
	borderBottom: `1px solid ${theme.palette.darkGreen}`,
}));

const Icon = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "3.9em",
	height: "3.681em",
	[theme.breakpoints.down("tablet")]: {
		borderRight: `1px solid ${theme.palette.darkGreen}`,
		height: "4.375em",
		width: "2.6em",
		"& svg": {
			transform: "scale(0.9)",
		},
	},
}));

const Container = styled(Box)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	[theme.breakpoints.down("tablet")]: {
		flexDirection: "column",
		alignItems: "flex-start",
		width: "14.87em",
	},
}));

const LabelWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	width: "20.6em",
	borderLeft: `1px solid ${theme.palette.darkGreen}`,
	borderRight: `1px solid ${theme.palette.darkGreen}`,
	height: "3.681em",
	paddingLeft: "1.25em",
	[theme.breakpoints.down("tablet")]: {
		height: "2em",
		width: "100%",
		border: "none",
		paddingLeft: "0.65em",
	},
}));

const Label = styled(Typography)(({ theme }) => ({
	fontSize: "1.125rem",
	[theme.breakpoints.down("tablet")]: {
		fontSize: "0.875rem",
		lineHeight: "1",
	},
}));

const InfoWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	width: "24.4em",
	height: "3.681em",
	paddingLeft: "1.25em",
	[theme.breakpoints.down("tablet")]: {
		height: "2em",
		width: "100%",
		paddingLeft: "0.65em",
		overflowX: "hidden",
	},
}));

const Info = styled(Box)(({ theme }) => ({
	fontSize: "1.125em",
	[theme.breakpoints.down("tablet")]: {
		fontSize: "1rem",
		textWrap: "nowrap",
	},
}));

const StyledListItem = {
	ListItem,
	Icon,
	Container,
	LabelWrapper,
	Label,
	InfoWrapper,
	Info,
};

export default StyledListItem;
