import { TCreditCard } from "../../models";
import { TTransactionType } from "../../models/api";

export const getTransactionName = (
	name: TTransactionType,
	type: TCreditCard,
): string => {
	switch (name) {
		case "remittance": {
			return type === "moneyBox" ? "Кэшбек" : "Перевод";
		}
		case "refill": {
			return "Пополнение счёта";
		}
		case "accrual": {
			return "Еженедельный процент";
		}
		case "marketing": {
			return "Реферальное вознаграждение";
		}
		default: {
			return "Транзакция";
		}
	}
};
