import { createSlice } from "@reduxjs/toolkit";

import { changePasswordThunk } from "../thunks/credential";

type TCredential = {
	isLoading: boolean;
	wasPasswordChanged: boolean;
};

const initialState: TCredential = {
	isLoading: false,
	wasPasswordChanged: false,
};

export const credentialSlice = createSlice({
	name: "credentialSlice",
	initialState,
	reducers: {
		setPasswordWasNotChanged: (state) => {
			state.wasPasswordChanged = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(changePasswordThunk.fulfilled, (state) => {
			state.isLoading = false;
			state.wasPasswordChanged = true;
		});
		builder.addCase(changePasswordThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(changePasswordThunk.rejected, (state) => {
			state.isLoading = false;
		});
	},
});
