import React from "react";

import Styled from "./Header.style";
import {
	AccountLinks,
	AdminLinks,
	MainOperationsLinks,
	OwnerLinks,
} from "./components";
import { Logout } from "./components";

import { ReactComponent as Logo } from "../../assets/logo.svg";

const Header: React.FC = () => {
	const menuRef = React.useRef<HTMLDivElement>(null);
	const menuButtonRef = React.useRef<HTMLDivElement>(null);

	const handleMenuButtonClick = () => {
		if (menuRef.current) {
			menuRef.current.classList.toggle("hide");
		}
		if (menuButtonRef.current) {
			menuButtonRef.current.classList.add("animate");
			menuButtonRef.current.classList.toggle("active");
		}
	};

	return (
		<Styled.Wrapper>
			<Styled.MenuButtonContainer>
				<Styled.MenuButton
					onClick={handleMenuButtonClick}
					ref={menuButtonRef}
					id={"menu_button"}
				>
					<Styled.MenuLine className={"first_line"} />
					<Styled.MenuLine className={"second_line"} />
					<Styled.MenuLine className={"third_line"} />
				</Styled.MenuButton>
			</Styled.MenuButtonContainer>
			<Styled.MenuContainer
				ref={menuRef}
				className={"hide"}
				id={"menu_container"}
			>
				<Styled.Container1>
					<AccountLinks />
					<MainOperationsLinks />
				</Styled.Container1>
				<Styled.LogoContainer>
					<Logo />
				</Styled.LogoContainer>
				<Styled.Container2>
					<OwnerLinks />
					<AdminLinks />
					<Logout />
				</Styled.Container2>
			</Styled.MenuContainer>
		</Styled.Wrapper>
	);
};

export default Header;
