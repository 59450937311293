import * as Yup from "yup";

export const RegistrationSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "не менее 2 символов")
		.max(30, "не более 30 символов")
		.matches(/^[ёа-яА-Я-]+$/, "только буквы кириллицы")
		.required("обязательное поле"),
	surname: Yup.string()
		.min(2, "не менее 2 символов")
		.max(30, "не более 30 символов")
		.matches(/^[ёа-яА-Я-]+$/, "только буквы кириллицы")
		.required("обязательное поле"),
	patronymic: Yup.string()
		.min(2, "не менее 2 символов")
		.max(30, "не более 30 символов")
		.matches(/^[ёа-яА-Я-]+$/, "только буквы кириллицы")
		.required("обязательное поле"),
	reference: Yup.number()
		.positive("укажите положительное число")
		.integer("должно быть целым числом")
		.lessThan(4294967295, "слишком большое число"), // INT
	email: Yup.string().email("неверный адрес").max(30, "не более 30 символов"),
	phone: Yup.string()
		.min(6, "не менее 6 символов")
		.max(16, "не более 16 символов")
		.matches(/^\+\d+$/, "номер в формате +7123456789"),
	login: Yup.string()
		.min(5, "не менее 5 символов")
		.max(20, "не более 20 символов")
		.matches(
			/^[a-zA-Z-\d!<@#_$>%`~^&?*()|+.,/\\]+$/,
			"может состоять только из букв латинского алфавита, цифр и символов",
		)
		.required("обязательное поле"),
	password: Yup.string()
		.min(5, "не менее 5 символов")
		.max(20, "не более 20 символов")
		.matches(
			/^[a-zA-Z-\d!<@#_$>%`~^&?*()|+.,/\\]+$/,
			"может состоять только из букв латинского алфавита, цифр и символов",
		)
		.required("обязательное поле"),
	confirm: Yup.string()
		.min(5, "не менее 5 символов")
		.max(20, "не более 20 символов")
		.matches(
			/^[a-zA-Z-\d!<@#_$>%`~^&?*()|+.,/\\]+$/,
			"может состоять только из букв латинского алфавита, цифр и символов",
		)
		.required("обязательное поле")
		.oneOf([Yup.ref("password"), "string"], "пароли не совпадают"),
});
