import React from "react";

import Styled from "./Contacts.style";

const Contacts: React.FC = () => {
	return (
		<Styled.Wrapper>
			<div>
				<Styled.Title variant={"h6"}>Контакты:</Styled.Title>
				<Styled.InfoWrapper>
					<Styled.Text>Тел.:</Styled.Text>
					<Styled.PhoneNumbers>
						<Styled.Text>+7 (965) 022-44-14</Styled.Text>
						<Styled.Text>+7 (965) 771-02-19</Styled.Text>
					</Styled.PhoneNumbers>
				</Styled.InfoWrapper>
				<Styled.InfoWrapper>
					<Styled.Text>E-mail:</Styled.Text>
					<Styled.SimpleLink href={"mailto:pavelignatev19@gmail.com"}>
						pavelignatev19@gmail.com
					</Styled.SimpleLink>
				</Styled.InfoWrapper>
				<Styled.InfoWrapper>
					<Styled.Text>Telegram:</Styled.Text>
					<Styled.SimpleLink
						href={"https://t.me/zelenoezolotoeko"}
						target={"_blank"}
						rel={"noopener noreferrer"}
					>
						zelenoezolotoeko
					</Styled.SimpleLink>
				</Styled.InfoWrapper>
				<Styled.InfoWrapper>
					<Styled.Text>Наши товары:</Styled.Text>
					<Styled.SimpleLink
						href={"https://зеленоезолото.рф"}
						target={"_blank"}
						rel={"noopener noreferrer"}
					>
						зеленоезолото.рф
					</Styled.SimpleLink>
				</Styled.InfoWrapper>
			</div>
		</Styled.Wrapper>
	);
};

export default Contacts;
