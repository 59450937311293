import { createSlice } from "@reduxjs/toolkit";

import { deleteAdminThunk, getAdminsThunk } from "../thunks/role";
import { addAdminThunk } from "../thunks/role/addAdmin.thunk";

import { IAdminInfo } from "../../models/api";

type TRole = {
	isLoading: boolean;
	adminsList: IAdminInfo[];
};

const initialState: TRole = {
	isLoading: false,
	adminsList: [],
};

export const roleSlice = createSlice({
	name: "roleSlice",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getAdminsThunk.fulfilled, (state, action) => {
			state.isLoading = false;
			state.adminsList = action.payload.data;
		});
		builder.addCase(getAdminsThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getAdminsThunk.rejected, (state) => {
			state.isLoading = false;
			state.adminsList = [];
		});

		builder.addCase(addAdminThunk.fulfilled, (state, action) => {
			state.isLoading = false;
			state.adminsList = action.payload.data;
		});
		builder.addCase(addAdminThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(addAdminThunk.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(deleteAdminThunk.fulfilled, (state, action) => {
			state.isLoading = false;
			state.adminsList = action.payload.data;
		});
		builder.addCase(deleteAdminThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(deleteAdminThunk.rejected, (state) => {
			state.isLoading = false;
		});
	},
});
