import { Box, keyframes, styled } from "@mui/material";

const columnGap = "1.9em";
const width = "30em";
const lineHeight = 2;
const linesGap = 10;
const distance = lineHeight + linesGap;

const Wrapper = styled(Box)(({ theme }) => ({
	[theme.breakpoints.down("tablet")]: {
		position: "relative",
		width: "100vw",
	},
}));

const MenuButtonContainer = styled(Box)(({ theme }) => ({
	[theme.breakpoints.down("tablet")]: {
		position: "absolute",
		right: "30px",
		top: "20px",
		width: "30px",
		height: 2 * linesGap + 3 * lineHeight + 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
}));

const MenuButton = styled(Box)(({ theme }) => ({
	display: "none",
	[theme.breakpoints.down("tablet")]: {
		display: "block",
		zIndex: 3,
		width: "30px",
		cursor: "pointer",
		"&.animate.active .first_line": {
			animationName: animation_one,
		},
		"&.animate.active .second_line": {
			animationName: animation_two,
		},
		"&.animate.active .third_line": {
			animationName: animation_three,
		},
		"&.animate .first_line": {
			animationName: animation_one_reverse,
		},
		"&.animate .second_line": {
			animationName: animation_two_reverse,
		},
		"&.animate .third_line": {
			animationName: animation_three_reverse,
		},
	},
}));

const MenuLine = styled(Box)(({ theme }) => ({
	[theme.breakpoints.down("tablet")]: {
		height: lineHeight,
		backgroundColor: theme.palette.yellow,
		borderRadius: "4px",
		animation: "0.7s ease-in-out forwards",
		"&.second_line": {
			margin: `${linesGap}px 0`,
		},
	},
}));

const MenuContainer = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	width: "90em",
	color: "white",
	margin: "0 auto",
	padding: "0.4em 6.3em 2.3em",
	backgroundColor: theme.palette.green,
	[theme.breakpoints.down("tablet")]: {
		rowGap: "1em",
		position: "fixed",
		left: 0,
		height: "100vh",
		width: "100vw",
		margin: 0,
		padding: "1em 0 0 0",
		flexDirection: "column",
		justifyContent: "flex-start",
		zIndex: 2,
		transition: "0.5s",
		"&.hide": {
			left: "100vw",
		},
	},
}));

const Container1 = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "flex-start",
	alignItems: "flex-end",
	columnGap,
	width,
	[theme.breakpoints.down("tablet")]: {
		flexDirection: "column",
		alignItems: "flex-start",
		rowGap: "1em",
		order: 2,
		paddingLeft: "1.875em",
	},
}));

const LogoContainer = styled(Box)(({ theme }) => ({
	height: "5.38em",
	[theme.breakpoints.down("tablet")]: {
		display: "flex",
		justifyContent: "center",
		order: 1,
		"& svg": {
			scale: "0.75",
		},
	},
}));

const Container2 = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "flex-end",
	alignItems: "flex-end",
	columnGap,
	width,
	[theme.breakpoints.down("tablet")]: {
		flexDirection: "column",
		alignItems: "flex-start",
		rowGap: "1em",
		order: 3,
		paddingLeft: "1.875em",
	},
}));

const animation_one = keyframes`
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  40%, 60% {
    transform: translateY(${distance}px);
  }
  100% {
    transform: translateY(${distance}px) rotate(45deg);
  }
`;

const animation_two = keyframes`
  0% {
    transform: scale(1);
  }
  40%, 100% {
    transform: scale(0);
  }
`;

const animation_three = keyframes`
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  40%, 60% {
    transform: translateY(-${distance}px);
  }
  100% {
    transform: translateY(-${distance}px) rotate(135deg);
  }
`;

const animation_one_reverse = keyframes`
  0% {
    transform: translateY(${distance}px) rotate(45deg);
  }
  40%, 60% {
    transform: translateY(${distance}px);
  }
  100% {
    transform: translateY(0px) rotate(0deg);
  }
`;

const animation_two_reverse = keyframes`
  0% {
    transform: scale(0);
  }
  60%, 100% {
    transform: scale(1);
  }
`;

const animation_three_reverse = keyframes`
  0% {
    transform: translateY(-${distance}px) rotate(135deg);
  }
  40%, 60% {
    transform: translateY(-${distance}px);
  }
  100% {
    transform: translateY(0px) rotate(0deg);
  }
`;

const StyledHeader = {
	Wrapper,
	MenuContainer,
	MenuButtonContainer,
	MenuButton,
	MenuLine,
	Container1,
	LogoContainer,
	Container2,
};

export default StyledHeader;
