import { Box, styled } from "@mui/material";

const Link = styled(Box)(({ theme }) => ({
	fontSize: "1.125em",
	textWrap: "nowrap",
	"&:hover": {
		cursor: "pointer",
	},
	[theme.breakpoints.down("tablet")]: {
		fontSize: "1rem",
	},
}));

const StyledListItemLink = {
	Link,
};

export default StyledListItemLink;
