import { Box, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
	height: "100vh",
	paddingBottom: "3em",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	rowGap: "2.5em",
	width: "100%",
	textAlign: "center",
	backgroundColor: theme.palette.green,
	minHeight: "100%",
	overflowY: "auto",
	"& svg": {
		margin: "0 auto",
	},
}));

const LogoWrapper = styled(Box)({
	height: "5em",
});

const StyledRestore = {
	Wrapper,
	LogoWrapper,
};

export default StyledRestore;
