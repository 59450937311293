import { Box, styled } from "@mui/material";

const MenuWrapper = styled(Box)(({ theme }) => ({
	[theme.breakpoints.down("tablet")]: {
		"& .submenu.visible": {
			display: "flex",
		},
	},
	[theme.breakpoints.up("tablet")]: {
		"&:hover": {
			"& .submenu": {
				display: "flex",
			},
		},
	},
}));

const Submenu = styled(Box)(({ theme }) => ({
	display: "none",
	flexDirection: "column",
	position: "absolute",
	width: "15em",
	[theme.breakpoints.down("tablet")]: {
		position: "inherit",
	},
}));

const Gap = styled(Box)(({ theme }) => ({
	height: "1em",
	[theme.breakpoints.down("tablet")]: {
		height: "0em",
	},
}));

const LinksList = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	rowGap: "1em",
	border: `1px solid ${theme.palette.yellow}`,
	borderRadius: "1.8em",
	padding: "1em 0 0 1.5em",
	backgroundColor: theme.palette.green,
	zIndex: 2,
	paddingBottom: "1.2em",
	[theme.breakpoints.down("tablet")]: {
		border: "none",
		paddingBottom: 0,
	},
}));

const Link = styled(Box, {
	shouldForwardProp: (prop) => prop !== "isActive",
})<ILinkProps>(({ theme, isActive }) => ({
	fontSize: "1.15rem",
	color: isActive ? theme.palette.yellow : theme.palette.lightGrey,
	"&:hover": {
		cursor: "pointer",
		color: theme.palette.yellow,
	},
	[theme.breakpoints.down("tablet")]: {
		"&:hover": {
			color: theme.palette.lightGrey,
		},
	},
}));

const StyledHeaderMenuItem = {
	MenuWrapper,
	Submenu,
	Gap,
	LinksList,
	Link,
};

type ILinkProps = {
	isActive: boolean;
};

export default StyledHeaderMenuItem;
