import { Box, Button, Typography, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	gap: "2em",
	margin: "0 auto 1em",
	width: "22.5em",
	backgroundColor: theme.palette.lightGrey,
	padding: "2.4em 2em 2em",
	borderRadius: "30px",
	[theme.breakpoints.down("tablet")]: {
		gap: "1.7em",
		width: "18.75em",
		padding: "1.2em 0.625em 1.25em",
		borderRadius: "20px",
	},
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: "1.43rem",
	[theme.breakpoints.down("tablet")]: {
		fontSize: "1.125rem",
	},
}));

const ButtonsWrapper = styled(Box)({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	gap: "0.5em",
	marginTop: "1em",
});

const SubmitButton = styled(Button)(({ theme }) => ({
	margin: "0 auto",
	backgroundColor: theme.palette.green,
	color: theme.palette.lightGrey,
	"&:hover": {
		backgroundColor: theme.palette.darkGreen,
	},
	[theme.breakpoints.down("tablet")]: {
		width: "17.5em",
	},
}));

const CancelButton = styled(Button)(({ theme }) => ({
	margin: "0 auto",
	backgroundColor: theme.palette.middleGrey,
	"&:hover": {
		backgroundColor: theme.palette.darkGrey,
	},
	[theme.breakpoints.down("tablet")]: {
		width: "17.5em",
	},
}));

export const FormStyles = {
	Wrapper,
	Title,
	ButtonsWrapper,
	SubmitButton,
	CancelButton,
};
