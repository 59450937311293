import React from "react";

import { ReactComponent as CalendarIcon } from "../../../../../../../../../../../../assets/calendar.svg";
import { ReactComponent as PersonTieIcon } from "../../../../../../../../../../../../assets/manInTieSmall.svg";
import { ReactComponent as PersonIcon } from "../../../../../../../../../../../../assets/person.svg";
import { ReactComponent as ListIcon } from "../../../../../../../../../../../../assets/toDoList.svg";
import { ReactComponent as WalletIcon } from "../../../../../../../../../../../../assets/wallet1.svg";
import { TModalWindowItem } from "../../../../../../../../../../../../models";
import { IMoneyBoxHistoryRow } from "../../../../../../../../../../../../models/api/history";
import { getDate } from "../../../../../../../../../../../../utils/functions";

export const getModalWindowList = (
	transaction: IMoneyBoxHistoryRow,
): TModalWindowItem[] => {
	return [
		{
			label: "Отправитель",
			icon: <PersonTieIcon />,
			text: transaction.sender || "-",
		},
		{
			label: "Получатель",
			icon: <PersonIcon />,
			text: transaction.recipient,
		},
		{
			label: "Сумма",
			icon: <WalletIcon />,
			text: `${transaction.moneyBox}`,
		},
		{
			label: "Дата",
			icon: <CalendarIcon />,
			text: getDate(new Date(transaction.createdAt)),
		},
		{
			label: "Описание",
			icon: <ListIcon />,
			text: transaction.desc || "-",
		},
	];
};
