import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { TShowSystemMessage } from "../../../hocs/SystemMessageContextWrapper/utils/types";
import { $api } from "../../../http";
import { IContacts, IReject, IResponse } from "../../../models/api";

export const updateProfileThunk = createAsyncThunk<
	IResponse<IContacts>,
	{
		email: string;
		showSystemMessage: TShowSystemMessage;
	},
	{
		rejectValue: IReject;
	}
>("profile/saveProfileThunk", async (args, { rejectWithValue }) => {
	const { email, showSystemMessage } = args;
	try {
		const response = await $api.put<IResponse<IContacts>>(
			"client/update.profile",
			{
				email,
			},
		);
		showSystemMessage("Данные сохранены успешно", "success");
		return response.data;
	} catch (err) {
		const error: AxiosError<IReject> = err as AxiosError<IReject>; // cast the error for access
		showSystemMessage(
			typeof error.response?.data.message === "string"
				? error.response?.data.message
				: "Ошибка выполнения операции",
			"error",
		);
		return rejectWithValue(
			error.response?.data || {
				error: true,
				message: "Ошибка выполнения операции",
				statusCode: 500,
			},
		);
	}
});
