import React from "react";

import Styled from "./HistoryItem.style";
import { getModalWindowList, getName } from "./utils/functions";

import { useAppSelector } from "../../../../../../../../../../hooks";
import { IAdminAccountHistoryRow } from "../../../../../../../../../../models/api";
import { getDate } from "../../../../../../../../../../utils/functions";
import { ModalWindow } from "../../../../../../../../../common";

const HistoryItem: React.FC<IProps> = ({ transaction }) => {
	const { clientId } = useAppSelector((state) => state.authReducer);
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const name = getName(transaction, clientId);

	let sign = 1;
	if (transaction.table === "refill") {
		sign = -1;
	}

	const list = React.useMemo(
		() => getModalWindowList(transaction),
		[transaction],
	);

	return (
		<React.Fragment>
			<Styled.Wrapper onClick={handleOpen}>
				<Styled.DateNameWrapper>
					<Styled.DateInfo>
						{getDate(new Date(transaction.createdAt))}
					</Styled.DateInfo>
					<Styled.ClientName>{name}</Styled.ClientName>
				</Styled.DateNameWrapper>
				<Styled.InfoWrapper>
					{!!transaction.wallet && (
						<Styled.Amount>{`${(transaction.wallet * sign).toFixed(
							2,
						)} бон`}</Styled.Amount>
					)}
					{!!transaction.moneyBox && (
						<Styled.Amount>{`${(transaction.moneyBox * sign).toFixed(
							2,
						)} бон`}</Styled.Amount>
					)}
				</Styled.InfoWrapper>
			</Styled.Wrapper>
			<ModalWindow open={open} handleClose={handleClose} details={list} />
		</React.Fragment>
	);
};

interface IProps {
	transaction: IAdminAccountHistoryRow;
}

export default HistoryItem;
