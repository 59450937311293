import React, { ReactNode } from "react";

import Styled from "./CreditCard.style";

import { ReactComponent as SimCardIcon } from "../../../assets/simCard.svg";
import { useAppSelector } from "../../../hooks";
import { convertAsStringWithCommas } from "../../../utils/functions";

const CreditCard: React.FC<TProps> = ({ title, amount, icon }) => {
	const { clientId } = useAppSelector((state) => state.authReducer);

	return (
		<Styled.Account>
			<Styled.Title>{title}</Styled.Title>
			<Styled.IconWrapper>{icon}</Styled.IconWrapper>
			<Styled.SimCardWrapper>
				<SimCardIcon />
			</Styled.SimCardWrapper>
			<Styled.Amount>
				{`${convertAsStringWithCommas(amount.toFixed(2))}`} бон
			</Styled.Amount>
			<Styled.IdWrapper>
				<Styled.IdText>Идентификационный номер</Styled.IdText>
				<Styled.IdNumber>№ {clientId}</Styled.IdNumber>
			</Styled.IdWrapper>
		</Styled.Account>
	);
};

type TProps = {
	title: string;
	amount: number;
	icon: ReactNode;
};

export default CreditCard;
