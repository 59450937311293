import React from "react";

import Styled from "./HeaderMenuItem.style";

const HeaderMenuItem: React.FC<TProps> = ({ name, children, isActive }) => {
	const submenuRef = React.useRef<HTMLDivElement>(null);

	const handleLinkClick = () => {
		if (submenuRef.current) {
			const submenues = document.querySelectorAll(".submenu");
			submenues.forEach((el) => el.classList.remove("visible"));
			submenuRef.current.classList.toggle("visible");
		}
	};

	const handleSublinkClick = () => {
		const menuContainer = document.getElementById("menu_container");
		const menuButton = document.getElementById("menu_button");
		if (menuContainer && menuButton) {
			menuContainer.classList.toggle("hide");
			menuButton.classList.add("animate");
			menuButton.classList.toggle("active");
		}
	};

	return (
		<Styled.MenuWrapper>
			<Styled.Link isActive={isActive} onClick={handleLinkClick}>
				{name}
			</Styled.Link>
			<Styled.Submenu className={"submenu"} ref={submenuRef}>
				<Styled.Gap />
				<Styled.LinksList onClick={handleSublinkClick}>
					{children}
				</Styled.LinksList>
			</Styled.Submenu>
		</Styled.MenuWrapper>
	);
};

type TProps = {
	name: string;
	children: React.ReactNode;
	isActive: boolean;
};

export default HeaderMenuItem;
