import { Formik } from "formik";
import React from "react";

import { RefillAdminBalanceSchema } from "./utils";

import { FormikItem, Loader } from "../../../common";

import { ReactComponent as MoneyStack } from "../../../../assets/moneyStack.svg";
import { ReactComponent as NumberIcon } from "../../../../assets/number.svg";
import { ReactComponent as Logo } from "../../../../assets/refillAdminLogo.svg";
import { ReactComponent as ToDoList } from "../../../../assets/toDoList.svg";
import { SystemMessageContext } from "../../../../hocs";
import WithClientName from "../../../../hocs/WithClientName/WithClientName";
import { useAppDispatch, useAppSelector, useDebounce } from "../../../../hooks";
import { FormStyles, MainStyles } from "../../../../mui/commonStyles";
import { personalDataSlice } from "../../../../store/slices";
import { refillAdminAccountThunk } from "../../../../store/thunks/bankHistory";
import { getPersonalDataThunk } from "../../../../store/thunks/personalData";

const RefillAdminAccount: React.FC = () => {
	const [id, setId] = React.useState(0);
	const { isLoading } = useAppSelector((state) => state.bankHistoryReducer);
	const { showSystemMessage } = React.useContext(SystemMessageContext);
	const { resetPersonInfo } = personalDataSlice.actions;
	const dispatch = useAppDispatch();
	const debounced = useDebounce(id);

	React.useEffect(() => {
		if (debounced && Number.isInteger(debounced)) {
			dispatch(
				getPersonalDataThunk({ clientId: debounced, showSystemMessage }),
			);
		} else {
			dispatch(resetPersonInfo());
		}
		// eslint-disable-next-line
	}, [debounced]);

	return (
		<MainStyles.Wrapper>
			<MainStyles.LogoContainer>
				<Logo />
			</MainStyles.LogoContainer>
			<Formik
				initialValues={{
					adminId: 0,
					amount: 0,
					desc: "",
				}}
				validationSchema={RefillAdminBalanceSchema}
				onSubmit={(values, actions) => {
					if (values.amount) {
						dispatch(refillAdminAccountThunk({ ...values, showSystemMessage }));
						actions.resetForm();
						setId(0);
					}
				}}
			>
				{({ values, handleChange, handleSubmit, errors, touched }) => (
					<form onSubmit={handleSubmit}>
						<FormStyles.Wrapper>
							<FormStyles.Title>Перевод администратору</FormStyles.Title>
							<WithClientName>
								<FormikItem
									id={"adminId"}
									label="Номер администратора&#42;"
									type={"number"}
									values={values}
									errors={errors}
									onChange={(event) => {
										setId(Number(event.target.value));
										handleChange(event);
									}}
									touched={touched}
									Icon={NumberIcon}
								/>
							</WithClientName>
							<FormikItem
								id={"amount"}
								label={"Сумма"}
								type={"number"}
								values={values}
								errors={errors}
								onChange={handleChange}
								touched={touched}
								Icon={MoneyStack}
							/>
							<FormikItem
								id={"desc"}
								label={"Описание"}
								type={"text"}
								values={values}
								errors={errors}
								onChange={handleChange}
								touched={touched}
								Icon={ToDoList}
							/>
							<FormStyles.SubmitButton variant="contained" type={"submit"}>
								Пополнить
							</FormStyles.SubmitButton>
						</FormStyles.Wrapper>
					</form>
				)}
			</Formik>
			<Loader isLoading={isLoading} />
		</MainStyles.Wrapper>
	);
};

export default RefillAdminAccount;
