import { Box, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	rowGap: "1em",
	height: "100vh",
	backgroundColor: theme.palette.green,
}));

const StyledNewPasswordConfirm = {
	Wrapper,
};

export default StyledNewPasswordConfirm;
