import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
	interface Palette {
		white: string;
		green: string;
		hoverGreen: string;
		darkGreen: string;
		yellow: string;
		darkYellow: string;
		dark: string;
		lightGrey: string;
		middleGrey: string;
		darkGrey: string;
		deepGrey: string;
	}
	interface PaletteOptions {
		white: string;
		green: string;
		hoverGreen: string;
		darkGreen: string;
		yellow: string;
		darkYellow: string;
		dark: string;
		lightGrey: string;
		middleGrey: string;
		darkGrey: string;
		deepGrey: string;
	}
	interface BreakpointOverrides {
		xs: false; // removes the `xs` breakpoint
		sm: false;
		md: false;
		lg: false;
		xl: false;
		tablet: true;
		// laptop: true;
		// desktop: true;
	}
}

export const theme = createTheme({
	typography: {
		fontFamily: ["Manrope", "Roboto", "Arial", "sans-serif"].join(","),
	},
	breakpoints: {
		values: {
			tablet: 600, // tablets 360
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				containedPrimary: ({ theme }) => ({
					backgroundColor: theme.palette.yellow,
					borderRadius: "30px",
					width: "18.5em",
					height: "3.75em",
					color: theme.palette.dark,
					textTransform: "initial",
					fontSize: "inherit",
					"&:hover": {
						backgroundColor: theme.palette.darkYellow,
					},
					[theme.breakpoints.down("tablet")]: {
						width: "18.75em",
					},
				}),
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: () => ({
					borderRadius: "initial",
				}),
			},
		},
		MuiTypography: {
			styleOverrides: {
				root: ({ theme }) => ({
					color: theme.palette.darkGreen,
				}),
				h6: ({ theme }) => ({
					color: theme.palette.yellow,
				}),
			},
		},
	},
	palette: {
		white: "#FFFFFF",
		green: "#425C59",
		hoverGreen: "#3c5250",
		darkGreen: "#35403C",
		yellow: "#FFCDA5",
		darkYellow: "#ffb982",
		dark: "#161c24e6",
		lightGrey: "#F9F9F9",
		middleGrey: "#D9D9D9",
		darkGrey: "#a5a5a5",
		deepGrey: "#7a7a7a",
	},
});
