import { storageKeys } from "./consts";

export const localStore = {
	setAccessToken: (accessToken: string): void => {
		localStorage.setItem(storageKeys.accessToken, accessToken);
	},
	getAccessToken: (): string | null => {
		return localStorage.getItem(storageKeys.accessToken);
	},
	removeAccessToken: (): void => {
		localStorage.removeItem(storageKeys.accessToken);
	},
};
