import React from "react";

import Styled from "./HistoryItem.style";
import { getModalWindowList } from "./utils/functions";

import { IMoneyBoxHistoryRow } from "../../../../../../../../../../models/api/history";
import {
	getDate,
	getTransactionName,
} from "../../../../../../../../../../utils/functions";
import { ModalWindow } from "../../../../../../../../../common";

const HistoryItem: React.FC<IProps> = ({ transaction }) => {
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const name = getTransactionName(transaction.table, "moneyBox");

	const list = React.useMemo(
		() => getModalWindowList(transaction),
		[transaction],
	);

	return (
		<React.Fragment>
			<Styled.Wrapper onClick={handleOpen}>
				<Styled.DateNameWrapper>
					<Styled.DateInfo>
						{getDate(new Date(transaction.createdAt))}
					</Styled.DateInfo>
					<Styled.Name>{name}</Styled.Name>
				</Styled.DateNameWrapper>
				<Styled.InfoWrapper>
					<Styled.Amount>{`${transaction.moneyBox.toFixed(
						2,
					)} бон`}</Styled.Amount>
				</Styled.InfoWrapper>
			</Styled.Wrapper>
			<ModalWindow open={open} handleClose={handleClose} details={list} />
		</React.Fragment>
	);
};

interface IProps {
	transaction: IMoneyBoxHistoryRow;
}

export default HistoryItem;
