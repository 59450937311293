import React from "react";

import Styled from "../HeaderMenuItem/HeaderMenuItem.style";

import { useAppDispatch } from "../../../../hooks";
import { actionTypes } from "../../../../store/consts";
import { logout } from "../../../../store/thunks/auth";

const Logout: React.FC = () => {
	const dispatch = useAppDispatch();
	const resetStore = () => {
		dispatch(logout());
		dispatch({ type: actionTypes.resetStore });
	};

	return (
		<Styled.Link onClick={resetStore} isActive={false}>
			Выйти
		</Styled.Link>
	);
};

export default Logout;
