import { Box, Button, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-end",
	color: "white",
	[theme.breakpoints.down("tablet")]: {
		alignItems: "flex-start",
	},
}));

const LogoutButton = styled(Button)(({ theme }) => ({
	[theme.breakpoints.down("tablet")]: {
		width: "12.5em",
		height: "2.5em",
	},
}));

const StyledLogout = {
	Wrapper,
	LogoutButton,
};

export default StyledLogout;
