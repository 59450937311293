import React from "react";

import { AdminBalance, AdminHistory, RefillForm } from "./components";

import { Loader } from "../../../common";

import { ReactComponent as Logo } from "../../../../assets/calculatorNotebook.svg";
import { useAppSelector } from "../../../../hooks";
import { MainStyles } from "../../../../mui/commonStyles";

const AdminAccount: React.FC = () => {
	const { isLoading } = useAppSelector((state) => state.adminReducer);

	return (
		<MainStyles.Wrapper>
			<MainStyles.LogoContainer>
				<Logo />
			</MainStyles.LogoContainer>
			<MainStyles.CardWrapper>
				<AdminBalance />
				<RefillForm />
			</MainStyles.CardWrapper>
			<AdminHistory />
			<Loader isLoading={isLoading} />
		</MainStyles.Wrapper>
	);
};

export default AdminAccount;
