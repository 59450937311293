import * as Yup from "yup";

export const RemittanceSchema = Yup.object().shape({
	recipientId: Yup.number()
		.positive("должен быть положительным числом")
		.integer("должно быть целым числом")
		.lessThan(4294967295, "слишком большое число") // INT
		.required("обязательное поле"),
	amount: Yup.number()
		.positive("должна быть положительным числом")
		.integer("должна быть целым числом")
		.lessThan(4294967295, "слишком большое число") // INT
		.required("обязательное поле"),
	desc: Yup.string().max(50, "не более 50 символов"),
});
