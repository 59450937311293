import { createSlice } from "@reduxjs/toolkit";

import { getInviteesThunk } from "../thunks/reference";

import { IInvitee } from "../../models/api";

type TInitialState = {
	inviterId: number;
	invitees: IInvitee[];
	isLoading: boolean;
};

const initialState: TInitialState = {
	inviterId: 0,
	invitees: [],
	isLoading: false,
};

export const referenceSlice = createSlice({
	name: "referenceSlice",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getInviteesThunk.fulfilled, (state, action) => {
			state.inviterId = action.payload.data.inviterId;
			state.invitees = action.payload.data.invitees;
			state.isLoading = false;
		});
		builder.addCase(getInviteesThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getInviteesThunk.rejected, () => initialState);
	},
});
