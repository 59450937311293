import React from "react";

import Styled from "./HistoryItem.style";
import { getModalWindowList } from "./utils/functions";

import { ModalWindow } from "../../../ModalWindow";

import { useAppSelector } from "../../../../../hooks";
import { TTransaction } from "../../../../../models/history";
import { getDate, getInitial } from "../../../../../utils/functions";

const HistoryItem: React.FC<IProps> = ({ transaction }) => {
	const { clientId } = useAppSelector((state) => state.authReducer);
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const amISender = transaction.senderId === clientId;
	const nameInfo = amISender ? transaction.recipient : transaction.sender;
	const amountToDisplay = amISender
		? -(transaction.amount || 0)
		: transaction.amount || 0;

	const client = `${nameInfo.surname} ${getInitial(nameInfo.name)}.${getInitial(
		nameInfo.patronymic,
	)}.`;

	const list = React.useMemo(
		() => getModalWindowList(transaction),
		[transaction],
	);

	return (
		<React.Fragment>
			<Styled.Wrapper onClick={handleOpen}>
				<Styled.DateNameWrapper>
					<Styled.DateInfo>
						{getDate(new Date(transaction.createdAt))}
					</Styled.DateInfo>
					<Styled.ClientName>{client}</Styled.ClientName>
				</Styled.DateNameWrapper>
				<Styled.InfoWrapper>
					{!!transaction.amount && (
						<Styled.Amount>{`${amountToDisplay.toFixed(2)} бон`}</Styled.Amount>
					)}
					{!!transaction.wallet && (
						<Styled.Amount>{`${transaction.wallet.toFixed(
							2,
						)} бон`}</Styled.Amount>
					)}
					{!!transaction.moneyBox && (
						<Styled.Amount>{`${transaction.moneyBox.toFixed(
							2,
						)} бон`}</Styled.Amount>
					)}
				</Styled.InfoWrapper>
			</Styled.Wrapper>
			<ModalWindow open={open} handleClose={handleClose} details={list} />
		</React.Fragment>
	);
};

interface IProps {
	transaction: TTransaction;
}

export default HistoryItem;
