import * as Yup from "yup";

export const PercentSchema = Yup.object().shape({
	cashback: Yup.number()
		.min(0, "минимум ноль процентов")
		.max(100, "максимум сто процентов")
		.required("обязательное поле"),
	income: Yup.number()
		.min(0, "минимум ноль процентов")
		.max(100, "максимум сто процентов")
		.required("обязательное поле")
		.test(function (value, ctx) {
			const { marketing } = this.parent;
			if (marketing + value >= 100)
				return ctx.createError({
					message: "Доход + Маркетинг < 100 %",
				});
			return true;
		}),
	marketing: Yup.number()
		.min(0, "минимум ноль процентов")
		.max(100, "максимум сто процентов")
		.required("обязательное поле")
		.test(function (value, ctx) {
			const { income } = this.parent;
			if (income + value >= 100)
				return ctx.createError({
					message: "Маркетинг + Доход < 100 %",
				});
			return true;
		}),
	weekly: Yup.number()
		.min(0, "минимум ноль процентов")
		.max(100, "максимум сто процентов")
		.required("обязательное поле"),
});
