import React from "react";

import Styled from "./Restore.style";
import { RestoreForm, RestoreSuccess } from "./components";

import { ReactComponent as Logo } from "../../assets/logo.svg";

const Restore: React.FC = () => {
	return (
		<Styled.Wrapper>
			<Styled.LogoWrapper>
				<Logo />
			</Styled.LogoWrapper>
			<RestoreForm />
			<RestoreSuccess />
		</Styled.Wrapper>
	);
};

export default Restore;
