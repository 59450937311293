import { Formik } from "formik";
import React from "react";

import { RefillBankSchema } from "./utils/IncomeSchema";

import { FormikItem, Loader } from "../../../../../common";

import { ReactComponent as MoneyStack } from "../../../../../../assets/moneyStack.svg";
import { SystemMessageContext } from "../../../../../../hocs";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks";
import { FormStyles } from "../../../../../../mui/commonStyles";
import { changeIncomeThunk } from "../../../../../../store/thunks";

const IncomeForm: React.FC = () => {
	const { income, isLoading } = useAppSelector((state) => state.bankReducer);
	const dispatch = useAppDispatch();
	const { showSystemMessage } = React.useContext(SystemMessageContext);

	return (
		<React.Fragment>
			<Formik
				initialValues={{
					income,
				}}
				validationSchema={RefillBankSchema}
				onSubmit={(values, action) => {
					dispatch(changeIncomeThunk({ ...values, showSystemMessage }));
					action.resetForm();
				}}
				enableReinitialize
			>
				{({ values, handleChange, handleSubmit, errors, touched }) => (
					<form
						onSubmit={(event) => {
							handleSubmit(event);
						}}
					>
						<FormStyles.Wrapper>
							<FormikItem
								id={"income"}
								label="Сумма"
								type={"number"}
								values={values}
								errors={errors}
								onChange={handleChange}
								touched={touched}
								Icon={MoneyStack}
							/>
							<FormStyles.SubmitButton
								variant="contained"
								type={"submit"}
								disabled={income === values.income}
							>
								Изменить
							</FormStyles.SubmitButton>
						</FormStyles.Wrapper>
					</form>
				)}
			</Formik>
			<Loader isLoading={isLoading} />
		</React.Fragment>
	);
};

export default IncomeForm;
