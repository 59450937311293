import React from "react";
import { useLocation } from "react-router-dom";

import { HeaderMenuItem } from "../HeaderMenuItem";
import { Reference } from "../Reference";

import { Paths } from "../../../../paths";
import { urlItem } from "../../../../utils/consts";

const AccountLinks: React.FC = () => {
	const { pathname } = useLocation();

	return (
		<HeaderMenuItem
			name={"Аккаунт"}
			isActive={pathname.includes(`/${urlItem.main}/${urlItem.account}/`)}
		>
			<Reference name={"Мой профиль"} link={Paths.profile} />
			<Reference name={"Личная структура"} link={Paths.invitees} />
			<Reference name={"Описание программы"} link={Paths.description} />
		</HeaderMenuItem>
	);
};

export default AccountLinks;
