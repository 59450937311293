import React from "react";
import { Outlet } from "react-router-dom";

import Styled from "./Main.style";

import { Footer } from "../Footer";
import { Header } from "../Header";

const Main: React.FC = () => {
	return (
		<Styled.Wrapper>
			<Header />
			<Styled.MainContent>
				<Outlet />
			</Styled.MainContent>
			<Footer />
		</Styled.Wrapper>
	);
};

export default Main;
