import { createSlice } from "@reduxjs/toolkit";

type TState = {
	pageName: string;
};

const initialState: TState = {
	pageName: "",
};

export const commonSlice = createSlice({
	name: "commonSlice",
	initialState,
	reducers: {
		setPageName: (state, action) => {
			state.pageName = action.payload;
		},
	},
});
