import React from "react";

import { History, Pagination } from "../../../../../common";

import { useAppSelector } from "../../../../../../hooks";
import { HistoryWrapper } from "../../../../../../mui/commonStyles";
import { getBankHistoryThunk } from "../../../../../../store/thunks/bankHistory";

const BankHistory: React.FC = () => {
	const { bank } = useAppSelector((state) => state.bankReducer);
	const { bankHistory, isLoading } = useAppSelector(
		(state) => state.bankHistoryReducer,
	);

	return (
		<HistoryWrapper.Wrapper>
			<History
				title={"История переводов"}
				transactions={bankHistory.rows}
				isLoading={isLoading}
			/>
			<Pagination
				account={bank}
				count={bankHistory.count}
				thunk={getBankHistoryThunk}
			/>
		</HistoryWrapper.Wrapper>
	);
};

export default BankHistory;
