import React from "react";

import { HistoryItem } from "./components";

import { Loader } from "../../../../../../../common";

import { IWalletHistoryRow } from "../../../../../../../../models/api/history";
import Styled from "../../../../../../../../mui/commonStyles/History.style";

const History: React.FC<TProps> = ({ title, transactions, isLoading }) => {
	return (
		<Styled.Wrapper>
			<Styled.Title>{title}</Styled.Title>
			<Styled.List>
				{transactions.map((transaction, index) => {
					return (
						<React.Fragment key={index}>
							<HistoryItem transaction={transaction} />
						</React.Fragment>
					);
				})}
			</Styled.List>
			<Loader isLoading={isLoading} />
		</Styled.Wrapper>
	);
};

type TProps = {
	title: string;
	transactions: IWalletHistoryRow[];
	isLoading: boolean;
};

export default History;
