import { createSlice } from "@reduxjs/toolkit";

import { getBalance } from "../thunks";
import { getTotalThunk } from "../thunks/account/getTotal.thunk";

type TAccount = {
	clientId: number;
	wallet: number;
	moneyBox: number;
	totalWallet: number;
	totalMoneyBox: number;
	totalAdminAccount: number;
	totalInvestments: number;
	isLoading: boolean;
};

const initialState: TAccount = {
	clientId: 0,
	wallet: 0,
	moneyBox: 0,
	totalWallet: 0,
	totalMoneyBox: 0,
	totalAdminAccount: 0,
	totalInvestments: 0,
	isLoading: false,
};

export const accountSlice = createSlice({
	name: "accountSlice",
	initialState,
	reducers: {
		updateAccount: (state, action) => {
			state.wallet = action.payload.wallet;
			state.moneyBox = action.payload.moneyBox;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getBalance.fulfilled, (state, action) => {
			state.isLoading = false;
			state.clientId = action.payload.id;
			state.wallet = action.payload.wallet;
			state.moneyBox = action.payload.moneyBox;
		});
		builder.addCase(getBalance.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getBalance.rejected, (state) => {
			state.isLoading = false;
			state.clientId = initialState.clientId;
			state.wallet = initialState.wallet;
			state.moneyBox = initialState.moneyBox;
		});

		builder.addCase(getTotalThunk.fulfilled, (state, action) => {
			state.isLoading = false;
			state.totalWallet = action.payload.data.wallet;
			state.totalMoneyBox = action.payload.data.moneyBox;
			state.totalAdminAccount = action.payload.data.adminAccount;
			state.totalInvestments = action.payload.data.totalInvestments;
		});
		builder.addCase(getTotalThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getTotalThunk.rejected, (state) => {
			state.isLoading = false;
			state.totalWallet = initialState.totalWallet;
			state.totalMoneyBox = initialState.totalMoneyBox;
			state.totalAdminAccount = initialState.totalAdminAccount;
			state.totalInvestments = initialState.totalInvestments;
		});
	},
});
