import React from "react";

import Styled from "./HistoryItem.style";
import { getModalWindowList } from "./utils/functions";

import { useAppSelector } from "../../../../../../../../../../hooks";
import { IWalletHistoryRow } from "../../../../../../../../../../models/api/history";
import {
	getDate,
	getTransactionName,
} from "../../../../../../../../../../utils/functions";
import { ModalWindow } from "../../../../../../../../../common";

const HistoryItem: React.FC<IProps> = ({ transaction }) => {
	const { clientId } = useAppSelector((state) => state.authReducer);
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const name = getTransactionName(transaction.table, "wallet");

	let amount = transaction.sent;
	if (transaction.table === "remittance") {
		if (transaction.senderId !== clientId) {
			amount = transaction.received;
		} else {
			amount = transaction.sent * -1;
		}
	}

	const list = React.useMemo(
		() => getModalWindowList(transaction, Math.abs(amount)),
		[transaction, amount],
	);

	return (
		<React.Fragment>
			<Styled.Wrapper onClick={handleOpen}>
				<Styled.DateNameWrapper>
					<Styled.DateInfo>
						{getDate(new Date(transaction.createdAt))}
					</Styled.DateInfo>
					<Styled.Name>{name}</Styled.Name>
				</Styled.DateNameWrapper>
				<Styled.InfoWrapper>
					{!!amount && (
						<Styled.Amount>{`${amount.toFixed(2)} бон`}</Styled.Amount>
					)}
				</Styled.InfoWrapper>
			</Styled.Wrapper>
			<ModalWindow open={open} handleClose={handleClose} details={list} />
		</React.Fragment>
	);
};

interface IProps {
	transaction: IWalletHistoryRow;
}

export default HistoryItem;
