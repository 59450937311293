import { Box, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	width: "90em",
	margin: "0 auto",
	backgroundColor: theme.palette.green,
	[theme.breakpoints.down("tablet")]: {
		width: "100%",
		overflowX: "hidden",
	},
}));

const MainContent = styled(Box)(({ theme }) => ({
	width: "90em",
	minHeight: "59em",
	padding: "0 6.3em 4.4em",
	backgroundColor: theme.palette.green,
	[theme.breakpoints.down("tablet")]: {
		minHeight: "fit-content",
		width: "100%",
		padding: "1em 0 1em",
		display: "flex",
		justifyContent: "center",
	},
}));

const StyledMain = {
	Wrapper,
	MainContent,
};

export default StyledMain;
