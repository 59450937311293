import * as Yup from "yup";

export const RefillSchema = Yup.object().shape({
	clientId: Yup.number()
		.positive("укажите положительное число")
		.integer("должно быть целым числом")
		.lessThan(4294967295, "слишком большое число") // INT
		.required("обязательное поле"),
	wallet: Yup.number()
		.moreThan(-1, "укажите положительное число")
		.integer("должно быть целым числом")
		.lessThan(4294967295, "слишком большое число") // INT
		.test(function (value, ctx) {
			const { moneyBox } = this.parent;
			if (!moneyBox && !value)
				return ctx.createError({ message: "обязательное поле" });
			return true;
		}),
	moneyBox: Yup.number()
		.moreThan(-1, "укажите положительное число")
		.integer("должно быть целым числом")
		.lessThan(4294967295, "слишком большое число") // INT
		.test(function (value, ctx) {
			const { wallet } = this.parent;
			if (!wallet && !value)
				return ctx.createError({ message: "обязательное поле" });
			return true;
		}),
	desc: Yup.string()
		.min(2, "не менее 2 символов")
		.max(50, "не более 50 символов"),
});
