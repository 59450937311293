import React from "react";

import Styled from "./Registration.style";
import { RegistrationForm } from "./components";

const Registration: React.FC = () => {
	return (
		<Styled.Wrapper>
			<RegistrationForm />
		</Styled.Wrapper>
	);
};

export default Registration;
