import { Button } from "@mui/material";
import React from "react";

import Styled from "./Admin.style";

import { SystemMessageContext } from "../../../../../../../../hocs";
import { useAppDispatch } from "../../../../../../../../hooks";
import { IAdminInfo } from "../../../../../../../../models/api";
import { deleteAdminThunk } from "../../../../../../../../store/thunks";

const Admin: React.FC<IProps> = ({ admin }) => {
	const dispatch = useAppDispatch();
	const { showSystemMessage } = React.useContext(SystemMessageContext);
	const deleteAdmin = (clientId: number): void => {
		dispatch(deleteAdminThunk({ clientId, showSystemMessage }));
	};

	return (
		<Styled.Wrapper>
			<Styled.InfoWrapper>
				<Styled.Text>№{admin.clientId}</Styled.Text>
				<Styled.Text>{admin.client.surname}</Styled.Text>
				<Styled.Text>{admin.client.name}</Styled.Text>
				<Styled.Text>{admin.client.patronymic}</Styled.Text>
				<Styled.Text>{admin.client.balance.balance}</Styled.Text>
			</Styled.InfoWrapper>
			<Button variant="contained" onClick={() => deleteAdmin(admin.clientId)}>
				Удалить
			</Button>
		</Styled.Wrapper>
	);
};

interface IProps {
	admin: IAdminInfo;
}

export default Admin;
