import { Box, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "flex-start",
	alignItems: "flex-end",
	borderTop: `1px solid ${theme.palette.darkGreen}`,
	borderBottom: `1px solid ${theme.palette.darkGreen}`,
	height: "3.8em",
	position: "relative",
	marginTop: "0.4em",
	[theme.breakpoints.down("tablet")]: {
		height: "3.5em",
	},
}));

const Label = styled(Box)(({ theme }) => ({
	position: "absolute",
	left: "4.4em",
	top: "-0.7em",
	backgroundColor: theme.palette.lightGrey,
	padding: "0 0.5em",
	[theme.breakpoints.down("tablet")]: {
		fontSize: "0.875rem",
	},
}));

const IconWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "3.1em",
	width: "3.7em",
	[theme.breakpoints.down("tablet")]: {
		width: "2.8em",
		"& svg": {
			transform: "scale(0.9)",
		},
	},
}));

const Text = styled(Box)(({ theme }) => ({
	padding: "0 0 0.5em 1.1em",
	fontSize: "1.125rem",
	width: "15em",
	display: "flex",
	height: "2.7em",
	alignItems: "flex-end",
	borderLeft: `1px solid ${theme.palette.darkGreen}`,
	textWrap: "nowrap",
	overflowX: "hidden",
	[theme.breakpoints.down("tablet")]: {
		fontSize: "1rem",
		padding: "0 0 0.5em 0.5em",
	},
}));

const StyledListItem = {
	Wrapper,
	Label,
	IconWrapper,
	Text,
};

export default StyledListItem;
