import { Formik } from "formik";
import React from "react";

import Styled from "./LogIn.style";

import { FormItem } from "../FormItem";

import { ReactComponent as IconLock } from "../../../../assets/lock.svg";
import { ReactComponent as IconPerson } from "../../../../assets/person.svg";
import { SystemMessageContext } from "../../../../hocs";
import { useAppDispatch } from "../../../../hooks";
import { loginThunk } from "../../../../store/thunks";

const LogIn = () => {
	const dispatch = useAppDispatch();
	const { showSystemMessage } = React.useContext(SystemMessageContext);

	return (
		<Formik
			initialValues={{
				login: "",
				password: "",
			}}
			onSubmit={(values) => {
				dispatch(loginThunk({ ...values, showSystemMessage }));
			}}
		>
			{({ handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<Styled.FormWrapper>
						<FormItem
							id={"login"}
							label={"Логин"}
							onChange={handleChange}
							Icon={IconPerson}
						/>
						<FormItem
							id={"password"}
							label={"Пароль"}
							type={"password"}
							autoComplete={"current-password"}
							onChange={handleChange}
							Icon={IconLock}
						/>
						<Styled.EnterButton variant="contained" type={"submit"}>
							Войти
						</Styled.EnterButton>
					</Styled.FormWrapper>
				</form>
			)}
		</Formik>
	);
};

export default LogIn;
