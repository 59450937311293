import { Box, styled } from "@mui/material";

const Wrapper = styled(Box)({
	height: "100vh",
});

const StyledRegistration = {
	Wrapper,
};

export default StyledRegistration;
