import { urlItem } from "../utils/consts";

export const Paths = {
	welcome: "/",
	main: `/${urlItem.main}`,
	profile: `${urlItem.account}/profile`,
	invitees: `${urlItem.account}/invitees`,
	wallet: `${urlItem.operation}/wallet`,
	moneyBox: `${urlItem.operation}/moneyBox`,
	description: `${urlItem.operation}/description`,
	bank: `${urlItem.owner}/bank`,
	income: `${urlItem.owner}/income`,
	rights: `${urlItem.owner}/rights`,
	refillAdminBalance: `${urlItem.owner}/refill.admin.balance`,
	percent: `${urlItem.owner}/percent`,
	statistics: `${urlItem.owner}/statistics`,
	personalData: `${urlItem.owner}/personal.data`,
	adminAccount: `${urlItem.admin}/admin.account`,
	registration: "/registration",
	emailConfirm: "/email.confirmation",
	restorePassRequest: "/password.restore",
	newPasswordConfirm: "/new.password.confirmation",
};
