import React from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Logo } from "../../../../assets/logo.svg";
import { useAppSelector } from "../../../../hooks";
import { ConfirmStyles, FormStyles } from "../../../../mui/commonStyles";
import { Paths } from "../../../../paths";

const NewPassSuccess: React.FC = () => {
	const { wasPasswordRestored } = useAppSelector(
		(state) => state.clientReducer,
	);
	const navigate = useNavigate();

	const handleClick = () => {
		navigate(Paths.welcome);
	};

	return (
		<React.Fragment>
			{wasPasswordRestored && (
				<ConfirmStyles.Wrapper>
					<Logo />
					<ConfirmStyles.Window>
						<ConfirmStyles.Title>
							Ваши новые учётные данные для входа в приложение отправлены Вам на
							электронную почту
						</ConfirmStyles.Title>
						<FormStyles.SubmitButton variant="contained" onClick={handleClick}>
							Понятно
						</FormStyles.SubmitButton>
					</ConfirmStyles.Window>
				</ConfirmStyles.Wrapper>
			)}
		</React.Fragment>
	);
};

export default NewPassSuccess;
