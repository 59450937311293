import { styled } from "@mui/material";
import { NavLink } from "react-router-dom";

const Nav = styled("nav")(() => ({
	"& a.active": {
		color: "#FF9800",
	},
}));

const Reference = styled(NavLink)(({ theme }) => ({
	fontSize: "1.15rem",
	color: "#FFFFFF",
	textDecoration: "none",
	"&:hover": {
		cursor: "pointer",
		color: theme.palette.yellow,
	},
}));

const StyledReference = {
	Nav,
	Reference,
};

export default StyledReference;
