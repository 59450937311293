import { createSlice } from "@reduxjs/toolkit";

import {
	getBankHistoryThunk,
	refillAdminAccountThunk,
} from "../thunks/bankHistory";

import { IBankHistory } from "../../models/api";

type TBankHistory = {
	bankHistory: { count: number; rows: IBankHistory[] };
	isLoading: boolean;
};

const initialState: TBankHistory = {
	bankHistory: { count: 0, rows: [] },
	isLoading: false,
};

export const bankHistorySlice = createSlice({
	name: "bankHistorySlice",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(refillAdminAccountThunk.fulfilled, (state) => {
			state.isLoading = false;
		});
		builder.addCase(refillAdminAccountThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(refillAdminAccountThunk.rejected, (state) => {
			state.isLoading = false;
		});

		builder.addCase(getBankHistoryThunk.fulfilled, (state, action) => {
			state.bankHistory = action.payload.data;
			state.isLoading = false;
		});
		builder.addCase(getBankHistoryThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getBankHistoryThunk.rejected, (state) => {
			state.bankHistory = initialState.bankHistory;
		});
	},
});
