import { IClientInfo } from "../../../../../../../../../models/api/client";

export const initDetails: IClientInfo = {
	id: 0,
	surname: "",
	name: "",
	patronymic: "",
	createdAt: new Date(),
	contacts: { phone: "", email: "" },
};
