import { Formik } from "formik";
import React from "react";

import { SavePersonDataSchema } from "./utils/SavePersonDataSchema";

import { FormikItem, Loader } from "../../../common";

import { ReactComponent as BadgeIcon2 } from "../../../../assets/badge2.svg";
import { ReactComponent as BadgeIcon3 } from "../../../../assets/badge3.svg";
import { ReactComponent as BadgeIcon } from "../../../../assets/badge.svg";
import { ReactComponent as Logo } from "../../../../assets/folderWithPersonalData.svg";
import { ReactComponent as PersonInTieIcon } from "../../../../assets/manInTieSmall.svg";
import { ReactComponent as NumberIcon } from "../../../../assets/number.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/phone.svg";
import { SystemMessageContext } from "../../../../hocs";
import { useAppDispatch, useAppSelector, useDebounce } from "../../../../hooks";
import { FormStyles, MainStyles } from "../../../../mui/commonStyles";
import {
	getPersonalDataThunk,
	savePersonalDataThunk,
} from "../../../../store/thunks/personalData";

const PersonalData: React.FC = () => {
	const [id, setId] = React.useState(0);
	const [edited, setEdited] = React.useState(false);
	const { clientId, name, surname, patronymic, phone, login, isLoading } =
		useAppSelector((state) => state.personalDataReducer);
	const { showSystemMessage } = React.useContext(SystemMessageContext);
	const dispatch = useAppDispatch();
	const debounced = useDebounce(id);

	React.useEffect(() => {
		if (debounced && Number.isInteger(debounced)) {
			dispatch(
				getPersonalDataThunk({ clientId: debounced, showSystemMessage }),
			);
			setId(0);
			setEdited(false);
		}
		// eslint-disable-next-line
	}, [debounced]);

	return (
		<MainStyles.Wrapper>
			<MainStyles.LogoContainer>
				<Logo />
			</MainStyles.LogoContainer>
			<Formik
				initialValues={{
					clientId,
					name,
					surname,
					patronymic,
					phone,
					login,
				}}
				validationSchema={SavePersonDataSchema}
				onSubmit={(values) => {
					dispatch(savePersonalDataThunk({ ...values, showSystemMessage }));
					setEdited(false);
				}}
				enableReinitialize
			>
				{({ values, handleChange, handleSubmit, errors, touched }) => (
					<form onSubmit={handleSubmit}>
						<FormStyles.Wrapper>
							<FormStyles.Title>Личные данные</FormStyles.Title>
							<FormikItem
								id={"clientId"}
								label="Номер клиента&#42;"
								type={"number"}
								values={values}
								errors={errors}
								onChange={(event) => {
									setId(Number(event.target.value));
									handleChange(event);
								}}
								touched={touched}
								Icon={NumberIcon}
							/>
							<FormikItem
								id={"surname"}
								label="Фамилия&#42;"
								type={"text"}
								values={values}
								errors={errors}
								onChange={(event) => {
									handleChange(event);
									setEdited(true);
								}}
								touched={touched}
								Icon={BadgeIcon}
							/>
							<FormikItem
								id={"name"}
								label="Имя&#42;"
								type={"text"}
								values={values}
								errors={errors}
								onChange={(event) => {
									handleChange(event);
									setEdited(true);
								}}
								touched={touched}
								Icon={BadgeIcon2}
							/>
							<FormikItem
								id={"patronymic"}
								label="Отчество&#42;"
								type={"text"}
								values={values}
								errors={errors}
								onChange={(event) => {
									handleChange(event);
									setEdited(true);
								}}
								touched={touched}
								Icon={BadgeIcon3}
							/>
							<FormikItem
								id={"phone"}
								label="Номер телефона"
								type={"tel"}
								values={values}
								errors={errors}
								onChange={(event) => {
									handleChange(event);
									setEdited(true);
								}}
								touched={touched}
								Icon={PhoneIcon}
							/>
							<FormikItem
								id={"login"}
								label="Логин"
								type={"text"}
								values={values}
								errors={errors}
								disabled
								touched={touched}
								Icon={PersonInTieIcon}
							/>
							<FormStyles.SubmitButton
								variant="contained"
								type={"submit"}
								disabled={!edited}
							>
								Сохранить
							</FormStyles.SubmitButton>
						</FormStyles.Wrapper>
					</form>
				)}
			</Formik>
			<Loader isLoading={isLoading} />
		</MainStyles.Wrapper>
	);
};

export default PersonalData;
