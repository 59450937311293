import { createSlice } from "@reduxjs/toolkit";

import { getProfileThunk, updateProfileThunk } from "../thunks/profile";

import { IProfileInfo } from "../../models/api/Profile";

type TProfile = {
	isLoading: boolean;
};

const initialState: TProfile & IProfileInfo = {
	isLoading: false,
	surname: "",
	name: "",
	patronymic: "",
	email: "",
	phone: "",
	login: "",
	ref: 0,
	refLink: "",
	createdAt: "",
	inviter: {
		surname: "",
		name: "",
		patronymic: "",
		id: 0,
	},
};

export const profileSlice = createSlice({
	name: "profileSlice",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getProfileThunk.fulfilled, (state, action) => {
			state.isLoading = false;
			state.surname = action.payload.data.surname;
			state.name = action.payload.data.name;
			state.patronymic = action.payload.data.patronymic;
			state.email = action.payload.data.email;
			state.phone = action.payload.data.phone;
			state.login = action.payload.data.login;
			state.ref = action.payload.data.ref;
			state.refLink = action.payload.data.refLink;
			state.createdAt = action.payload.data.createdAt;
			state.inviter = action.payload.data.inviter;
		});
		builder.addCase(getProfileThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getProfileThunk.rejected, () => initialState);

		builder.addCase(updateProfileThunk.fulfilled, (state, action) => {
			state.isLoading = false;
			state.email = action.payload.data.email;
		});
		builder.addCase(updateProfileThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(updateProfileThunk.rejected, (state) => {
			state.isLoading = false;
		});
	},
});
