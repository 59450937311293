import { Box, styled } from "@mui/material";

const Recipient = styled(Box)({
	width: "100%",
	position: "relative",
});

const StyledWithClientName = {
	Recipient,
};

export default StyledWithClientName;
