import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";

import { actionTypes } from "./consts";
import {
	accountSlice,
	adminSlice,
	authSlice,
	bankHistorySlice,
	bankSlice,
	clientSlice,
	commonSlice,
	credentialSlice,
	historySlice,
	percentSlice,
	personalDataSlice,
	profileSlice,
	referenceSlice,
	remittanceSlice,
	roleSlice,
} from "./slices";

const rootReducer = combineReducers({
	accountReducer: accountSlice.reducer,
	adminReducer: adminSlice.reducer,
	authReducer: authSlice.reducer,
	bankHistoryReducer: bankHistorySlice.reducer,
	bankReducer: bankSlice.reducer,
	clientReducer: clientSlice.reducer,
	commonReducer: commonSlice.reducer,
	credentialReducer: credentialSlice.reducer,
	historyReducer: historySlice.reducer,
	percentReducer: percentSlice.reducer,
	personalDataReducer: personalDataSlice.reducer,
	profileReducer: profileSlice.reducer,
	referenceReducer: referenceSlice.reducer,
	remittanceReducer: remittanceSlice.reducer,
	roleReducer: roleSlice.reducer,
});

const reducerProxy = (state: any, action: AnyAction) => {
	if (action.type === actionTypes.resetStore) {
		return rootReducer(undefined, action);
	}
	return rootReducer(state, action);
};

export const createReduxStore = (initialState = {}) =>
	configureStore({
		reducer: reducerProxy,
		devTools: process.env.NODE_ENV !== "production",
		preloadedState: initialState,
	});

export const store = createReduxStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof createReduxStore>;
export type AppDispatch = AppStore["dispatch"];
