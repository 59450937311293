import { Box, Button, styled } from "@mui/material";

const FormWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "start",
	rowGap: "0.7em",
	[theme.breakpoints.down("tablet")]: {
		width: "17.5em",
	},
}));

const EnterButton = styled(Button)(({ theme }) => ({
	marginTop: "1.6em",
	color: theme.palette.lightGrey,
	width: "22.5em",
	backgroundColor: theme.palette.green,
	":hover": {
		backgroundColor: theme.palette.hoverGreen,
	},
	[theme.breakpoints.down("tablet")]: {
		marginTop: "0.6em",
		width: "17.5em",
		alignItems: "center",
	},
}));

const StyledLogIn = {
	FormWrapper,
	EnterButton,
};

export default StyledLogIn;
