import { Box, Button, styled } from "@mui/material";

const ButtonsWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	columnGap: "2.5em",
	[theme.breakpoints.down("tablet")]: {
		flexDirection: "column",
		alignItems: "center",
		rowGap: "0.625em",
	},
}));

const ChangeDataButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.middleGrey,
	"&:hover": {
		backgroundColor: theme.palette.darkGrey,
	},
}));

const StyledProfile = {
	ButtonsWrapper,
	ChangeDataButton,
};

export default StyledProfile;
