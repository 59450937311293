import React, { ChangeEvent } from "react";

import Styled from "./FormItem.style";

const FormItem: React.FC<TProps> = ({
	id,
	label,
	type,
	autoComplete,
	onChange,
	Icon,
}) => {
	return (
		<Styled.Wrapper>
			<Styled.IconWrapper>
				<Icon />
			</Styled.IconWrapper>
			<Styled.FormInput
				id={id}
				label={label}
				name={id}
				type={type}
				autoComplete={autoComplete}
				onChange={onChange}
			/>
		</Styled.Wrapper>
	);
};

type TProps = {
	id: string;
	label: string;
	onChange: (event: ChangeEvent<any>) => void;
	Icon: React.FC;
	type?: string;
	autoComplete?: string;
};

export default FormItem;
