import React from "react";

import Styled from "./WithClientName.style";
import { ClientName } from "./components";

const WithClientName: React.FC<TProps> = ({ children }) => {
	return (
		<Styled.Recipient>
			{children}
			<ClientName />
		</Styled.Recipient>
	);
};

type TProps = {
	children: React.ReactNode;
};

export default WithClientName;
