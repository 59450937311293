import { Box, styled } from "@mui/material";

const Name = styled(Box)(({ theme }) => ({
	position: "absolute",
	top: "3.6em",
	left: "4em",
	width: "max-content",
	color: theme.palette.deepGrey,
}));

const StyledAccount = {
	Name,
};

export default StyledAccount;
