import * as Yup from "yup";

export const ChangePasswordSchema = Yup.object().shape({
	oldPassword: Yup.string()
		.min(5, "не менее 5 символов")
		.max(20, "не более 20 символов")
		.matches(
			/^[a-zA-Z-\d!<@#_$>%`~^&?*()|+.,/\\]+$/,
			"может состоять только из букв латинского алфавита, цифр и символов",
		)
		.required("обязательное поле"),
	newPassword: Yup.string()
		.min(5, "не менее 5 символов")
		.max(20, "не более 20 символов")
		.matches(
			/^[a-zA-Z-\d!<@#_$>%`~^&?*()|+.,/\\]+$/,
			"может состоять только из букв латинского алфавита, цифр и символов",
		)
		.required("обязательное поле"),
	confirmation: Yup.string()
		.min(5, "не менее 5 символов")
		.max(20, "не более 20 символов")
		.matches(
			/^[a-zA-Z-\d!<@#_$>%`~^&?*()|+.,/\\]+$/,
			"может состоять только из букв латинского алфавита, цифр и символов",
		)
		.required("обязательное поле")
		.oneOf([Yup.ref("newPassword"), "string"], "пароли не совпадают"),
});
