import { createSlice } from "@reduxjs/toolkit";

import { getAdminAccountThunk, refillThunk } from "../thunks";

type TState = {
	balance: number;
	isLoading: boolean;
};

const initialState: TState = {
	balance: 0,
	isLoading: false,
};

export const adminSlice = createSlice({
	name: "adminSlice",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getAdminAccountThunk.fulfilled, (state, action) => {
			state.balance = action.payload.data.balance;
			state.isLoading = false;
		});
		builder.addCase(getAdminAccountThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getAdminAccountThunk.rejected, () => initialState);

		builder.addCase(refillThunk.fulfilled, (state, action) => {
			state.balance = action.payload.balance;
			state.isLoading = false;
		});
		builder.addCase(refillThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(refillThunk.rejected, (state) => {
			state.isLoading = false;
		});
	},
});
