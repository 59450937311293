import React from "react";

import Styled from "./Wallet.style";
import { Remittance, WalletHistory } from "./components";

import { CreditCard, Loader } from "../../../common";

import { ReactComponent as Logo } from "../../../../assets/coinsStack.svg";
import { ReactComponent as WalletIcon } from "../../../../assets/walletBig.svg";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { MainStyles } from "../../../../mui/commonStyles";
import { getBalance } from "../../../../store/thunks";

const Wallet: React.FC = () => {
	const { clientId } = useAppSelector((state) => state.authReducer);
	const { wallet, isLoading } = useAppSelector((state) => state.accountReducer);
	const dispatch = useAppDispatch();

	React.useEffect(() => {
		if (clientId) {
			dispatch(getBalance());
		}
	}, [dispatch, clientId]);

	return (
		<MainStyles.Wrapper>
			<MainStyles.LogoContainer>
				<Logo />
			</MainStyles.LogoContainer>
			<MainStyles.CardWrapper>
				<Styled.Container>
					<CreditCard title={"Кошелёк"} amount={wallet} icon={<WalletIcon />} />
					<Styled.SimpleLink
						href={"https://зеленоезолото.рф"}
						target={"_blank"}
						rel={"noopener noreferrer"}
					>
						Наши товары
					</Styled.SimpleLink>
					<Styled.SimpleLink
						href={"https://t.me/zelenoezolotoeko"}
						target={"_blank"}
						rel={"noopener noreferrer"}
					>
						Telegram канал
					</Styled.SimpleLink>
				</Styled.Container>
				<Remittance />
			</MainStyles.CardWrapper>
			<WalletHistory />
			<Loader isLoading={isLoading} />
		</MainStyles.Wrapper>
	);
};

export default Wallet;
