import { Box, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	cursor: "pointer",
	width: "29.5em",
	[theme.breakpoints.down("tablet")]: {
		width: "100%",
	},
}));

const DateNameWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	columnGap: "0.5em",
}));

const DateInfo = styled(Box)(({ theme }) => ({
	textWrap: "nowrap",
	color: theme.palette.white,
}));

const Name = styled(Box)(({ theme }) => ({
	width: "10em",
	overflowX: "hidden",
	color: theme.palette.white,
	textWrap: "nowrap",
	[theme.breakpoints.down("tablet")]: {
		width: "6em",
	},
}));

const InfoWrapper = styled(Box)({
	display: "flex",
	flexDirection: "column",
	alignItems: "flex-end",
	minWidth: "8em",
});

const Amount = styled(Box)(({ theme }) => ({
	color: theme.palette.white,
	textWrap: "nowrap",
	width: "5em",
	display: "flex",
	justifyContent: "flex-end",
}));

const StyledItem = {
	Wrapper,
	DateNameWrapper,
	Name,
	InfoWrapper,
	Amount,
	DateInfo,
};

export default StyledItem;
