import React from "react";

import { IncomeForm } from "./components";

import { CreditCard, Loader } from "../../../common";

import { ReactComponent as Logo } from "../../../../assets/bank.svg";
import { ReactComponent as BankIcon } from "../../../../assets/bankSmall.svg";
import { SystemMessageContext } from "../../../../hocs";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { MainStyles } from "../../../../mui/commonStyles";
import { getIncomeThunk } from "../../../../store/thunks";

const Income: React.FC = () => {
	const { income, isLoading } = useAppSelector((state) => state.bankReducer);
	const dispatch = useAppDispatch();
	const { showSystemMessage } = React.useContext(SystemMessageContext);

	React.useEffect(() => {
		dispatch(getIncomeThunk({ showSystemMessage }));
		// eslint-disable-next-line
	}, [dispatch]);

	return (
		<MainStyles.Wrapper>
			<MainStyles.LogoContainer>
				<Logo />
			</MainStyles.LogoContainer>
			<MainStyles.CardWrapper>
				<CreditCard title={"Доход"} amount={income} icon={<BankIcon />} />
				<IncomeForm />
			</MainStyles.CardWrapper>
			<Loader isLoading={isLoading} />
		</MainStyles.Wrapper>
	);
};

export default Income;
