import { Box, Link, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "1fr 1fr 1fr",
	paddingTop: "2em",
	gridGap: "0.5em",
	[theme.breakpoints.down("tablet")]: {
		width: "18.75em",
		gridTemplateColumns: "1fr",
	},
}));

const Text = styled(Box)(({ theme }) => ({
	display: "flex",
	alignItems: "flex-end",
	fontSize: "1.15rem",
	[theme.breakpoints.down("tablet")]: {
		fontSize: "1rem",
	},
}));

const InfoWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	width: "18.5em",
	paddingLeft: "7.3em",
	[theme.breakpoints.down("tablet")]: {
		paddingLeft: 0,
	},
}));

const SimpleLink = styled(Link)(({ theme }) => ({
	fontSize: "1.15rem",
	textDecoration: "none",
	color: theme.palette.lightGrey,
	[theme.breakpoints.down("tablet")]: {
		fontSize: "1rem",
	},
}));

const StyledExtraInfo = {
	Wrapper,
	Text,
	InfoWrapper,
	SimpleLink,
};

export default StyledExtraInfo;
