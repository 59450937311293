import React from "react";

import Styled from "./ProfileInfo.style";
import { ListItem, ListItemLink } from "./components";

import { Loader } from "../../../../../../../common";

import { ReactComponent as At } from "../../../../../../../../assets/at.svg";
import { ReactComponent as Badge2 } from "../../../../../../../../assets/badge2.svg";
import { ReactComponent as Badge3 } from "../../../../../../../../assets/badge3.svg";
import { ReactComponent as Badge } from "../../../../../../../../assets/badge.svg";
import { ReactComponent as Calendar } from "../../../../../../../../assets/calendar.svg";
import { ReactComponent as ManInTie } from "../../../../../../../../assets/manInTieSmall.svg";
import { ReactComponent as NumberSign } from "../../../../../../../../assets/number.svg";
import { ReactComponent as Person } from "../../../../../../../../assets/personSmall.svg";
import { ReactComponent as Phone } from "../../../../../../../../assets/phone.svg";
import { useAppSelector } from "../../../../../../../../hooks";
import { getDate } from "../../../../../../../../utils/functions";

const ProfileInfo: React.FC = () => {
	const {
		isLoading,
		surname,
		name,
		patronymic,
		email,
		phone,
		login,
		ref,
		createdAt,
		inviter,
	} = useAppSelector((state) => state.profileReducer);

	return (
		<React.Fragment>
			<Styled.Wrapper>
				<ListItem icon={<Badge />} label={"Фамилия"} info={surname} />
				<ListItem icon={<Badge2 />} label={"Имя"} info={name} />
				<ListItem icon={<Badge3 />} label={"Отчество"} info={patronymic} />
				<ListItem
					icon={<At />}
					label={"Адрес электронной почты"}
					info={email}
				/>
				<ListItem icon={<Phone />} label={"Номер телефона"} info={phone} />
				<ListItem icon={<ManInTie />} label={"Логин"} info={login} />
				<ListItem
					icon={<NumberSign />}
					label={"Идентификационный номер"}
					info={ref}
				/>
				<ListItemLink />
				<ListItem
					icon={<Calendar />}
					label={"Дата регистрации"}
					info={getDate(new Date(createdAt))}
				/>
				<ListItem
					icon={<Person />}
					label={"Спонсор"}
					info={`${inviter.surname} ${inviter.name} ${inviter.patronymic} № ${inviter.id}`}
				/>
			</Styled.Wrapper>
			<Loader isLoading={isLoading} />
		</React.Fragment>
	);
};

export default ProfileInfo;
