import { FormikTouched } from "formik";
import React, { ChangeEvent } from "react";

import Styled from "./FormikItem.style";

const FormikItem: React.FC<TProps> = ({
	id,
	label,
	type,
	values,
	errors,
	onChange,
	touched,
	Icon,
	disabled = false,
}) => {
	return (
		<Styled.Wrapper>
			<Styled.IconWrapper>
				<Icon />
			</Styled.IconWrapper>
			<Styled.FormInput
				id={id}
				label={label}
				name={id}
				type={type}
				value={values[id] || ""}
				error={(!!touched[id] || !!values[id]) && !!errors[id]}
				onChange={onChange}
				disabled={disabled}
			/>
			{errors[id] && (touched[id] || !!values[id]) && (
				<Styled.Error>{errors[id]}</Styled.Error>
			)}
		</Styled.Wrapper>
	);
};

type TProps = {
	id: string;
	label: string;
	Icon: React.FC;
	onChange?: (event: ChangeEvent<any>) => void;
	disabled?: boolean;
	type?: string;
	autoComplete?: string;
	errors: Record<string, string>;
	touched: FormikTouched<Record<string, string>>;
	values: Record<string, string | number>;
};

export default FormikItem;
