import { Box, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	width: "50em",
	backgroundColor: theme.palette.lightGrey,
	padding: "0.625em",
	borderRadius: "30px",
	margin: "0 auto",
	"& div:nth-last-of-type(1)": {
		border: "none",
	},
	[theme.breakpoints.down("tablet")]: {
		width: "18.75em",
		borderRadius: "20px",
	},
}));

const StyledProfile = {
	Wrapper,
};

export default StyledProfile;
