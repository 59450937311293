import { Box, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
	width: "30em",
	[theme.breakpoints.down("tablet")]: {
		width: "17.6em",
	},
}));

export const HistoryWrapper = {
	Wrapper,
};
