import { Box, Link, Typography, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	[theme.breakpoints.down("tablet")]: {
		marginBottom: "1em",
	},
}));

const InfoWrapper = styled(Box)({
	display: "flex",
	justifyContent: "flex-start",
	columnGap: "0.5em",
});

const PhoneNumbers = styled(Box)({
	display: "flex",
	flexDirection: "column",
});

const Title = styled(Typography)(({ theme }) => ({
	marginBottom: "1.3em",
	[theme.breakpoints.down("tablet")]: {
		marginBottom: "0.3em",
	},
}));

const Text = styled(Box)(({ theme }) => ({
	fontSize: "1.15rem",
	[theme.breakpoints.down("tablet")]: {
		fontSize: "1rem",
	},
}));

const SimpleLink = styled(Link)(({ theme }) => ({
	fontSize: "1.15rem",
	textDecoration: "none",
	color: theme.palette.lightGrey,
	[theme.breakpoints.down("tablet")]: {
		fontSize: "1rem",
	},
}));

const StyledContacts = {
	Wrapper,
	InfoWrapper,
	PhoneNumbers,
	Title,
	Text,
	SimpleLink,
};

export default StyledContacts;
