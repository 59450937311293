import { Box, Typography, styled } from "@mui/material";

const InfoWrapper = styled(Box)({
	marginTop: "0.1em",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	rowGap: "1em",
});

const Title = styled(Typography)(({ theme }) => ({
	fontSize: "1.375rem",
	[theme.breakpoints.down("tablet")]: {
		fontSize: "1.125rem",
	},
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "1.125rem",
	color: theme.palette.lightGrey,
	[theme.breakpoints.down("tablet")]: {
		width: "18.75em",
		fontSize: "1rem",
		textAlign: "center",
	},
}));

const Container = styled(Box)(({ theme }) => ({
	width: "100%",
	[theme.breakpoints.down("tablet")]: {
		width: "100vw",
		paddingLeft: "1em",
	},
}));

const Total = styled(Typography)(({ theme }) => ({
	color: theme.palette.lightGrey,
	marginBottom: "1em",
}));

const List = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "flex-start",
	rowGap: "0.5em",
	width: "fit-content",
	backgroundColor: "white",
	padding: "2em",
	borderRadius: "10px",
	[theme.breakpoints.down("tablet")]: {
		padding: "0.5em 2em 0.5em 0.5em",
	},
}));

const StyledInvitees = {
	InfoWrapper,
	Title,
	Text,
	Container,
	Total,
	List,
};

export default StyledInvitees;
