import React from "react";
import { useLocation } from "react-router-dom";

import { HeaderMenuItem } from "../HeaderMenuItem";
import { Reference } from "../Reference";

import { useAppSelector } from "../../../../hooks";
import { Paths } from "../../../../paths";
import { Roles, urlItem } from "../../../../utils/consts";

const AdminLinks: React.FC = () => {
	const { clientRoles } = useAppSelector((state) => state.authReducer);
	const isAdmin = clientRoles.some((role) => role === Roles.admin);
	const { pathname } = useLocation();

	if (!isAdmin) return null;

	return (
		<HeaderMenuItem
			name={"Администратор"}
			isActive={pathname.includes(`/${urlItem.main}/${urlItem.admin}/`)}
		>
			<Reference name={"Счёт администратора"} link={Paths.adminAccount} />
		</HeaderMenuItem>
	);
};

export default AdminLinks;
