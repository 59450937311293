import { Box, styled } from "@mui/material";

const Wrapper = styled(Box)(({ theme }) => ({
	padding: "3.5em 6.3em 4.4em",
	height: "28.2em",
	width: "90em",
	margin: "0 auto",
	color: "white",
	[theme.breakpoints.down("tablet")]: {
		width: "100%",
		height: "fit-content",
		padding: "2.5em 0.625em",
		margin: 0,
	},
}));

const Divider = styled(Box)(({ theme }) => ({
	height: "1px",
	width: "100%",
	backgroundImage: `repeating-linear-gradient(
		90deg,
		${theme.palette.yellow},
		${theme.palette.yellow} 10px,
		${theme.palette.green} 10px,
		${theme.palette.green} 20px
	)`,
}));

const ContactsWrapper = styled(Box)(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "1fr 1fr 1fr",
	paddingTop: "3.75em",
	[theme.breakpoints.down("tablet")]: {
		width: "18.75em",
		gridTemplateColumns: "1fr",
		paddingTop: "2em",
	},
}));

const InfoWrapper = styled(Box)(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "1fr 1fr 1fr",
	paddingTop: "3.75em",
	height: "100%",
}));

const StyledFooter = {
	Wrapper,
	Divider,
	ContactsWrapper,
	InfoWrapper,
};

export default StyledFooter;
