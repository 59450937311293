import { addZero } from "./addZero";

export const getFullDate = (date: Date): string => {
	const day = addZero(date.getDate());
	const month = addZero(date.getMonth() + 1);
	const year = addZero(date.getFullYear());
	const hours = addZero(date.getHours());
	const minutes = addZero(date.getMinutes());
	return `${day}.${month}.${year} ${hours}:${minutes}`;
};
