import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import Styled from "./RestoreSuccess.style";

import { useAppSelector } from "../../../../hooks";
import { Paths } from "../../../../paths";

const RestoreSuccess: React.FC = () => {
	const { isRestoreReqSent } = useAppSelector((state) => state.clientReducer);
	const navigate = useNavigate();

	const handleClick = () => {
		navigate(Paths.welcome);
	};

	return (
		<React.Fragment>
			{isRestoreReqSent && (
				<Styled.Wrapper>
					<Styled.Text>
						Письмо с инструкцией по восстановлению пароля отправлено на ваш
						почтовый адрес
					</Styled.Text>
					<Button variant="contained" onClick={handleClick}>
						Понятно
					</Button>
				</Styled.Wrapper>
			)}
		</React.Fragment>
	);
};

export default RestoreSuccess;
