import { createSlice } from "@reduxjs/toolkit";

import { remittanceThunk } from "../thunks/remittance/transferMoney.thunk";

type TAccount = {
	isLoading: boolean;
};

const initialState: TAccount = {
	isLoading: false,
};

export const remittanceSlice = createSlice({
	name: "remittanceSlice",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(remittanceThunk.fulfilled, (state) => {
			state.isLoading = false;
		});
		builder.addCase(remittanceThunk.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(remittanceThunk.rejected, (state) => {
			state.isLoading = false;
		});
	},
});
