import { Box, Typography, styled } from "@mui/material";

const Wrapper = styled(Box)({
	display: "flex",
	justifyContent: "space-between",
	marginTop: "2em",
});

const ButtonsWrapper = styled(Box)({
	display: "flex",
	justifyContent: "space-between",
	width: "3.5em",
});

const ArrowButton = styled("button")<TArrowButtonProps>(
	({ theme, disabled }) => ({
		border: "none",
		backgroundColor: theme.palette.green,
		color: disabled ? "#b1b1b1" : theme.palette.lightGrey,
	}),
);

const PagesWrapper = styled(Box)({
	display: "flex",
	justifyContent: "center",
	columnGap: "0.5em",
});

const PageNumber = styled(Typography, {
	shouldForwardProp: (prop) => prop !== "isActive",
})<TPageNumberProps>(({ theme, isActive }) => ({
	color: isActive ? theme.palette.yellow : theme.palette.lightGrey,
	"&:hover": {
		cursor: "pointer",
	},
}));

type TPageNumberProps = {
	isActive: boolean;
};

type TArrowButtonProps = {
	disabled: boolean;
};

const StyledPagination = {
	Wrapper,
	ButtonsWrapper,
	ArrowButton,
	PagesWrapper,
	PageNumber,
};

export default StyledPagination;
