import { AlertColor, Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import React from "react";

import { SMCWProps } from "./utils/types";

import { SystemMessageContext } from ".";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
	props,
	ref,
) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SystemMessageContextWrapper: React.FC<SMCWProps> = ({ children }) => {
	const [open, setOpen] = React.useState(false);
	const [systemMessage, setSystemMessage] = React.useState("");
	const [type, setType] = React.useState<AlertColor | undefined>(undefined);

	const showSystemMessage = (message: string, type: AlertColor | undefined) => {
		setType(type);
		setSystemMessage(message);
		setOpen(true);
	};

	const handleClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string,
	) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	return (
		<SystemMessageContext.Provider value={{ showSystemMessage }}>
			{children}
			<Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
					{systemMessage}
				</Alert>
			</Snackbar>
		</SystemMessageContext.Provider>
	);
};

export default SystemMessageContextWrapper;
