import React from "react";

import Styled from "./ListItem.style";

const ListItem: React.FC<TProps> = ({ icon, text, amount }) => {
	return (
		<Styled.ListItem>
			<Styled.Icon>{icon}</Styled.Icon>
			<Styled.InfoWrapper>
				<Styled.TextWrapper>
					<Styled.Text>{text}</Styled.Text>
				</Styled.TextWrapper>
				<Styled.AmountWrapper>
					<Styled.Amount>{amount}</Styled.Amount>
				</Styled.AmountWrapper>
			</Styled.InfoWrapper>
		</Styled.ListItem>
	);
};

type TProps = {
	icon: React.ReactNode;
	text: string;
	amount: string;
};

export default ListItem;
